import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Badge,
  Paper,
  TextField,
  Button,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Search, FilterList } from "@mui/icons-material";
import ConversationItem from "./ConversationItem";

const LeftSideBar = ({
  conversations,
  selectedConversation,
  handleConversationChange,
  setFilter,
  setShowFilter,
}) => {
  return (
    <Box
      sx={{
        borderRight: "1px solid",
        borderColor: "divider",
        display: "flex",
        flexDirection: "column",
        px: 2,
        height: "100%",
      }}
    >
      <Box sx={{ py: 2 }}>
        <TextField
          placeholder="Search Booking"
          size="small"
          fullWidth
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
        <Button
          variant="outlined"
          onClick={() => setShowFilter(true)}
          startIcon={<FilterList />}
          fullWidth
        >
          Filter
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            borderRadius: "4px",
          },
        }}
      >
        {conversations.map((item, index) => (
          <ConversationItem
            key={item.conversation._id}
            {...item}
            isSelected={
              selectedConversation?.conversation?._id === item.conversation._id
            }
            onClick={() => handleConversationChange(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LeftSideBar;
