import {
  CircularProgress,
  Grid,
  Typography,
  Paper,
  styled,
  Button,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ProspectProfile } from "./ProspectProfile";
import { useHistory } from "react-router-dom";
import EstimatePaymentSection from "./EstimatePaymentSection";
import BillingAccountSelector from "../../transaction/BillingAccountSelector";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "100%",
}));

export default function EstimateForm({
  estimateData,
  setEstimateData,
  onUpdateEstimate,
  loading,
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    customerProfile: null,
    billingAccount: null,
  });

  useEffect(() => {
    if (estimateData) {
      setFormData({
        customerProfile: estimateData?.customerProfile,
        billingAccount: estimateData?.billingAccount,
      });
    }
  }, [estimateData]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (setEstimateData) {
      setEstimateData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  let project = estimateData?.project;

  return loading ? (
    <div className="flex justify-center items-center h-screen">
      <CircularProgress />
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Prospect Information
          </Typography>
          <ProspectProfile
            formData={formData}
            curEntityId={project?._id}
            entity={"Project"}
            givenProfileIdArr={[]}
            handleChange={handleChange}
            handleRemoveEntity={(type, id) => handleChange(type, null)}
          />
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Billing Account
          </Typography>
          <BillingAccountSelector
            sourceProfile={project?.profile?._id}
            selectedBillingAccounts={formData?.billingAccount}
            onBillingAcountChange={(value) => {
              handleChange("billingAccount", value);
            }}
          />
        </StyledPaper>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          overflowX: "scroll",
          pt: 0,
          height: "max-content",
        }}
      >
        <StyledPaper
          sx={{
            pt: 0,
          }}
        >
          <EstimatePaymentSection
            estimateData={estimateData}
            setEstimateData={setEstimateData}
          />
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
        >
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onUpdateEstimate(formData)}
          >
            Update
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
