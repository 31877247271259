import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getBlockUnitsAnalysis } from "../api.call";
import FlipCard from "../../styled/CommonComponents/FlipCard";
import { FaHandshakeSimple } from "react-icons/fa6";
import { GiPayMoney } from "react-icons/gi";
import Divider from '@mui/material/Divider';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Paper } from "@material-ui/core";
import allUnitStatuses from "../../../helpers/allUnitStatuses";
import Chip from '@mui/material/Chip';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  mainCont:{
    width: "100%",
    height: "100%",
    overflowX: "auto",
    padding: "0px 20px 20px",
  },
  blockStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "30px",
    "& h3": {
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "600"
    }
  },
  allFloorCont: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  floorStyle: {
    width: "33.33%",
    display: "flex",
    marginBottom: "10px",
  },
  floorNoSty: {
    width: "60px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "12px",
    fontWeight: "500",
    color: "#050c2e"
  },
  unitCardsCont: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
  },
  cardSty: {
    width: "90px",
    height: "55px",
    marginRight: "10px",
    marginBottom: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  fCardSty: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  iconSty: {
    width: "26px",
    height: "26px",
    backgroundColor: "white",
    position: "absolute",
    top: "0px",
    right: "0px",
    borderBottomLeftRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContent: {
    position: 'absolute',
    top: '7px',
    left: '6px',
    color: 'white',
  },
  openedText: {
    fontSize: '11px',
    fontWeight: '400'
  },
  scoreText: {
    fontSize: '13px',
    fontWeight: '700',
  },
  bCardSty: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& p": {
      fontSize: "11px",
      color: "white",
      fontWeight: "500"
    }
  },
  allLagendCont: {
    width: "220px",
    display: "flex",
    flexWrap: "wrap",
    borderLeft: "1px solid #d7d7d7"
  },
  boxLabelCont: {
    width: "95px",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    marginBottom: "10px",
    "& div": {
      width: "20px",
      height: "20px",
      marginRight: "5px",
    }
  },
  headerPart: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 15px",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600"
    }
  },
  rightPart: {
    display: "flex",
    alignItems: "center",
    "& .MuiButtonBase-root": {
      marginRight: "5px"
    }
  },
  skeletonSty: {
    width: '100%',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  }
}));

function groupUnitsByBlockAndFloor(units) {
  const blockGroups = units.reduce((result, unit) => {
    const blockKey = unit.block || 'Unknown Block';
    if (!result[blockKey]) {
      result[blockKey] = {
        blockName: unit.blockDetails ? unit.blockDetails.name : 'Unknown Block',
        blockId: unit.blockDetails ? unit.blockDetails._id : null,
        floors: {}
      };
    }
    const floor = unit.floor;
    if (!result[blockKey].floors[floor]) {
      result[blockKey].floors[floor] = [];
    }
    result[blockKey].floors[floor].push(unit);
    return result;
  }, {});

  return Object.keys(blockGroups).map(blockKey => {
    const block = blockGroups[blockKey];
    const floorsArray = Object.keys(block.floors)
      .sort((a, b) => Number(a) - Number(b))
      .map(floorNum => ({
        floorNumber: Number(floorNum),
        units: block.floors[floorNum]
      }));
    return {
      blockName: block.blockName,
      blockId: block.blockId,
      floors: floorsArray
    };
  });
}

const rendarFloorName = (number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  if (number === 0) {
    return <p>Ground</p>;
  } else if (number < 0) {
    const remainder = (number * -1) % 100;
    const suffix = (remainder >= 11 && remainder <= 13) ? 'th' : suffixes[(number * -1) % 10] || 'th';
    return (<>
      <p>{number * -1}{suffix}</p>
      <p>Basement</p>
    </>)
  } else {
    const remainder = number % 100;
    const suffix = (remainder >= 11 && remainder <= 13) ? 'th' : suffixes[number % 10] || 'th';
    return (<>
      <p>{number}{suffix}</p>
      <p>Floor</p>
    </>)
  }
}

const getColor = (availability) => {
  switch (availability) {
    case "Available":
      return 'linear-gradient(-135deg, #E5F8E9 0%, #78B763 40%, #4D8F3F 100%)';
    case "Sold":
      return "linear-gradient(-135deg, #FEE9E9 0%, #E67C7C 40%, #B64040 100%)";
    case "Rented":
      return "linear-gradient(-135deg, #E8F2FE 0%, #71A3E0 40%, #3069A0 100%)";
    case "Reserved":
      return "linear-gradient(-135deg, #FDF4E7 0%, #E0B267 40%, #C29332 100%)";
  }
}

const getIcon = (ownershipStatus) => {
  switch (ownershipStatus) {
    case "Investor":
      return <GiPayMoney style={{ fontSize: "17px" }} />;
    case "Landowner":
      return <GolfCourseIcon style={{ fontSize: "17px" }} />
    case "Developer / Project owner":
      return <EngineeringIcon
        style={{
          fontSize: "26px",
          marginLeft: "9px",
          marginBottom: "6px"
        }}
      />;
    case "Partners":
      return <FaHandshakeSimple style={{ fontSize: "17px" }} />;
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const BlockUnitsHome = () => {
  const { profileId } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const [loader, setLoader] = useState(false)
  const [unitsData, setUnitsData] = useState([])
  const [filterOwners, setFilterOwners] = useState([])
  const [filterAvailabilities, setFilterAvailabilities] = useState([])

  const getFilterOptions = async () => {
    setLoader(true)
    getBlockUnitsAnalysis({ profileId })
      .then((data) => {
        setUnitsData(groupUnitsByBlockAndFloor(data))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoader(false)
      })
  }

  useEffect(() => {
    getFilterOptions()
  }, [])

  return (
    <div className={classes.mainCont} >
      {loader ? (<div className={classes.skeletonSty} >
        <Skeleton
          style={{
            width: '100%',
            height: "100px",
            margin: "10px 20px"
          }}
        />
        {Array.from({ length: 120 }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rect"
            className={classes.cardSty}
          />
        ))}
      </div>) : (<>
        <div className={classes.headerPart} >
          <h3>Block Insights</h3>
          <div className={classes.rightPart} >
            <div>
              <div style={{ marginBottom: "10px" }} >
                {allUnitStatuses.Ownership.map((s) => (
                  <Chip
                    label={s}
                    size="small"
                    color="primary"
                    variant={filterOwners.includes(s) ? "contained" : "outlined"}
                    onClick={() => {
                      if (filterOwners.includes(s)) {
                        setFilterOwners(filterOwners.filter((o) => o !== s))
                      } else {
                        setFilterOwners([...filterOwners, s])
                      }
                    }}
                  />
                ))}
              </div>
              <div>
                {allUnitStatuses.Availability.map((s) => (
                  <Chip
                    label={s}
                    size="small"
                    color="primary"
                    variant={filterAvailabilities.includes(s) ? "contained" : "outlined"}
                    onClick={() => {
                      if (filterAvailabilities.includes(s)) {
                        setFilterAvailabilities(filterAvailabilities.filter((o) => o !== s))
                      } else {
                        setFilterAvailabilities([...filterAvailabilities, s])
                      }
                    }}
                  />
                ))}
              </div>
            </div>
            <div className={classes.allLagendCont}  >
              {allUnitStatuses.Availability.map((s) => (<div className={classes.boxLabelCont} >
                <Paper elevation={2} style={{ background: getColor(s) }} ></Paper>
                <p>{s}</p>
              </div>))}
            </div>
          </div>
        </div>
        {unitsData && unitsData.map((blockData, inx) => (<>
          <div className={classes.blockStyle} >
            <h3>{blockData?.blockName}</h3>
            <Divider style={{ width: "100%", margin: "5px 0px 25px" }} variant="middle" />
            <div className={classes.allFloorCont} >
              {blockData?.floors && blockData?.floors.map((floorData) => {
                const filteredUnits = floorData?.units.filter((u) => (filterOwners.length === 0 || filterOwners.includes(u.ownershipStatus)) && (filterAvailabilities.length === 0 || filterAvailabilities.includes(u.availability)));
                if (filteredUnits && filteredUnits.length > 0) {
                  return (
                    <div className={classes.floorStyle} >
                      <div className={classes.floorNoSty} >{rendarFloorName(floorData?.floorNumber)}</div>
                      <div className={classes.unitCardsCont} >
                        {filteredUnits.map((unitData) => (
                          <div
                            className={classes.cardSty}
                            onClick={() => {
                              history.push(`/project/unit/${unitData?._id}`)
                            }}
                          >
                            <FlipCard
                              frontComponent={
                                <div
                                  className={classes.fCardSty}
                                  style={{
                                    background: getColor(unitData?.availability),
                                  }}
                                >
                                  <div className={classes.iconSty} >
                                    {getIcon(unitData?.ownershipStatus)}
                                  </div>
                                  <div className={classes.textContent}>
                                    <div className={classes.scoreText}>{unitData?.unitType}</div>
                                    <div className={classes.openedText}>{unitData?.name}</div>
                                  </div>
                                </div>
                              }
                              backComponent={<div className={classes.bCardSty} >
                                <p>{numberWithCommas(unitData?.saleableArea || 0)}/SqFt</p>
                                <p>{unitData?.customer}</p>
                              </div>}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </>))}
      </>)}
    </div>
  );
};

export default BlockUnitsHome;