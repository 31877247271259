import React, { useEffect, useState } from "react";
import { getUnitWiseAnalysisData } from "../api.call";
import {
    Line, XAxis, YAxis, CartesianGrid, Tooltip, 
    ResponsiveContainer, Bar, ReferenceLine, 
    ComposedChart, Cell, Label, Area
} from "recharts";
import AmountStatusPieGraph from "../../team/procurements/AmountStatusPieChart";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@mui/material";
import { useDebounce } from "react-use";
import Skeleton from '@mui/material/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const COLORS = {
    "Partners": "#4682B4",
    "Landowner": "#FFD700",
    "Investor": "#2E8B57",
    "Developer / Project owner": "#87CEEB"
}

const useStyles = makeStyles((theme) => ({
    lineChartSty: {
        zIndex: "100",
        width: "100%",
        height: "500px",
        padding: "15px 15px 50px",
        position: "relative",
        marginBottom: "20px",
        "& .recharts-responsive-container": {
            marginTop: "25px"
        }
    },
    bothPieCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    colorTextCont: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& p": {
            marginLeft: "5px",
            fontWeight: "500"
        }
    },
    pieLagendCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        "& p": {
            fontSize: "12px",
            fontWeight: "500",
            color: "#888888",
            marginLeft: "3px"
        }
    },
    pieChatCont: {
        width: "280px"
    },
    lagendsCont: {
        width: "calc(100% - 300px)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    titleAutocompleteSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    container: {
        width: "100%",
        maxHeight: "500px",
        border: "1px solid #d5d1d1",
        "& tr": {
            height: "auto"
        },
        "&.MuiTableCell-root": {
            outline: "1px solid #ececec",
            border: "none",
        },
        "& table": {
            tableLayout: "auto",
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
        },
        "& th": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "10px"
        },
        "& td": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "10px 10px",
        }
    },
    headingSty: {
        fontSize: "22px",
        fontWeight: "500",
        color: "black",
        marginBottom: "15px"
    }
}));

const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    return (
        <div
            style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
            }}
        >
            <p>{label}</p>
            <p style={{ fontWeight: "bold" }} >₹ {new Intl.NumberFormat('en-US').format(payload[0].value)}</p>
        </div>
    );
};

const calculateCumulativeValues = (data) => {
    let runningTotal = data[0].value;

    return data.map((item, index) => {
        const newItem = { ...item };

        if (index === 0) {
            newItem.cumulative = item.value;
        } else {
            runningTotal = runningTotal - data[index - 1].value + item.value;
            newItem.cumulative = runningTotal;
        }

        return newItem;
    });
};

const formatYAxis = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
};

function getOwnershipPropertyKey(ownership) {
    switch (ownership) {
        case "Developer / Project owner":
            return "projectOwnerAmount";
        case "Landowner":
            return "landOwnerAmount";
        case "Investor":
            return "InvestorAmount";
        case "Partners":
            return "partnersAmount";
        default:
            if (ownership) {
                return ownership.toLowerCase().replace(/\s+(.)/g, (match, group) =>
                    group.toUpperCase()) + "Amount";
            }
            return null;
    }
}

const UnitWiseAnalysis = ({
    profileId, filterObj, totalCriticalAmount, totalTransactedAmount
}) => {
    const classes = useStyles();
    const [amountDistribution, setAmountDistribution] = useState([]);
    const [amountDistributionAgainstOwner, setAmountDistributionAgainstOwner] = useState([]);
    const [totalAmountDistributionAgainstOwner, setTotalAmountDistributionAgainstOwner] = useState([]);
    const [loading, setLoading] = useState(true);

    const getChartData = () => {
        setLoading(true);
        getUnitWiseAnalysisData({ profileId })
            .then((gpData = []) => {
                const totals = {
                    totalValue: 0,
                    totalSold: 0,
                    totalUnsold: 0,
                    collected: 0,
                    due: 0
                };
                const obj1 = { label: "Total Value" };
                const obj2 = { label: "Sold" };
                const obj3 = { label: "Unsold" };
                const obj4 = { label: "Collected" };
                const obj5 = { label: "Due" };
                const dataArr2 = [];
                gpData.forEach((s) => {
                    totals.totalValue += s?.totalValue || 0;
                    totals.totalSold += s?.totalSold || 0;
                    totals.totalUnsold += s?.totalUnsold || 0;
                    totals.collected += s?.collected || 0;
                    totals.due += s?.due || 0;

                    dataArr2.push({
                        name: s?.ownership,
                        value: s?.totalValue,
                    });

                    const ownershipKey = getOwnershipPropertyKey(s?.ownership);
                    if (ownershipKey) {
                        obj1[ownershipKey] = s?.totalValue;
                        obj2[ownershipKey] = s?.totalSold;
                        obj3[ownershipKey] = s?.totalUnsold;
                        obj4[ownershipKey] = s?.collected;
                        obj5[ownershipKey] = s?.due;
                    }
                });
                obj1.total = totals.totalValue;
                obj2.total = totals.totalSold;
                obj3.total = totals.totalUnsold;
                obj4.total = totals.collected;
                obj5.total = totals.due;
                setAmountDistribution(calculateCumulativeValues([
                    {
                        name: "Total Value",
                        value: totals.totalValue,
                        fillColor: 'rgba(144, 202, 249, 0.7)'
                    },
                    {
                        name: "Sold Value",
                        value: totals.totalSold,
                        fillColor: 'rgba(255, 204, 128, 0.7)',
                    },
                    {
                        name: "Current Due",
                        value: totals.due,
                        fillColor: 'rgba(255, 213, 79, 0.7)'
                    },
                    {
                        name: "Bill Sent",
                        value: totalTransactedAmount,
                        fillColor: 'rgba(179, 157, 219, 0.7)'
                    },
                    {
                        name: "Critical Transaction",
                        value: totalCriticalAmount || 0,
                        fillColor: 'rgba(239, 154, 154, 0.7)'
                    },
                    {
                        name: "Collected Payment",
                        value: totals.collected,
                        fillColor: 'rgba(128, 203, 196. 0.7)',
                    },
                ]));
                setAmountDistributionAgainstOwner(dataArr2);
                setTotalAmountDistributionAgainstOwner([
                    obj1, obj2, obj3, obj5, obj4
                ]);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useDebounce(() => {
        getChartData()
    }, 1000, [filterObj])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        if ((amountDistribution.filter((s) => s.name === "Bill Sent")).length > 0 && (amountDistribution.filter((s) => s.name === "Bill Sent"))[0]?.value !== totalTransactedAmount) {
            setAmountDistribution(calculateCumulativeValues([
                {
                    name: "Total Value",
                    value: (amountDistribution.filter((s) => s.name === "Total Value"))[0]?.value,
                    fillColor: 'rgba(144, 202, 249, 0.7)'
                },
                {
                    name: "Sold Value",
                    value: (amountDistribution.filter((s) => s.name === "Sold Value"))[0]?.value,
                    fillColor: 'rgba(255, 204, 128, 0.7)',
                },
                {
                    name: "Current Due",
                    value: (amountDistribution.filter((s) => s.name === "Current Due"))[0]?.value,
                    fillColor: 'rgba(255, 213, 79, 0.7)'
                },
                {
                    name: "Bill Sent",
                    value: totalTransactedAmount || 0,
                    fillColor: 'rgba(179, 157, 219, 0.7)'
                },
                {
                    name: "Critical Transaction",
                    value: totalCriticalAmount || 0,
                    fillColor: 'rgba(239, 154, 154, 0.7)'
                },
                {
                    name: "Collected Payment",
                    value: (amountDistribution.filter((s) => s.name === "Collected Payment"))[0]?.value,
                    fillColor: 'rgba(128, 203, 196. 0.7)',
                },
            ]));
        }
    }, [totalCriticalAmount, amountDistribution, totalTransactedAmount])

    return (<>
        <Paper
            className={classes.lineChartSty}
            elevation={2}
            style={{ borderRadius: "10px" }}
        >
            <div className={classes.titleAutocompleteSty} >
                <h3 className={classes.headingSty} >Amounts Distribution</h3>
                <div className="autocompletesCont" ></div>
            </div>
            {loading ? (
                <Skeleton
                    variant="rect"
                    style={{
                        width: "100%",
                        height: "420px",
                        borderRadius: "10px",
                        marginBottom: "20px"
                    }}
                />
            ) : (<>
                <ResponsiveContainer width="100%" height={"95%"}>
                    <ComposedChart
                        data={amountDistribution}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 60,
                            bottom: 40
                        }}
                    >
                        <defs>
                            <linearGradient id="ownerColorValue" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FF0000" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#FABAAD" stopOpacity={0.4} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                        <YAxis
                            label={{ value: 'Amount in in INR (₹)', angle: -90, position: 'insideLeft', offset: -35 }}
                            tickFormatter={formatYAxis}
                            tick={{ fill: '#666' }}
                            tickLine={false}
                            axisLine={false}
                            width={80}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <ReferenceLine y={0} stroke="#000" />
                        <Area
                            type="monotone"
                            dataKey="cumulative"
                            stroke="none"
                            fillOpacity={0.8}
                            fill="url(#ownerColorValue)"
                        />
                        <Bar dataKey="value" radius={[8, 8, 0, 0]}>
                            {amountDistribution.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fillColor} />
                            ))}
                            {amountDistribution.map((entry, index) => (
                                <Label
                                    key={`label-${index}`}
                                    position="center"
                                    content={({ x, y, width, height }) => (
                                        <text
                                            x={x + width / 2}
                                            y={y + height / 2}
                                            fill="#000"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            fontSize={12}
                                            fontWeight="bold"
                                        >
                                            {entry.value}
                                        </text>
                                    )}
                                />
                            ))}
                        </Bar>
                        <Line
                            type="monotone"
                            dataKey="cumulative"
                            stroke="#ff0000"
                            strokeWidth={2}
                            dot={{ r: 4, fill: '#ff0000' }}
                            activeDot={{ r: 6 }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </>)}
        </Paper>
        <div
            className={classes.bothPieCont}
        >
            <Paper style={{ width: "420px", borderRadius: "10px" }} >
                <h3 style={{ margin: "15px 0px 0px 15px" }} className={classes.headingSty} >Ownership</h3>
                {loading ? (
                    <Skeleton
                        variant="rect"
                        style={{
                            width: "100%",
                            height: "300px",
                            borderRadius: "10px"
                        }}
                    />
                ) : (
                    <div className={classes.pieLagendCont} >
                        <div className={classes.pieChatCont} >
                            <AmountStatusPieGraph
                                data={{ data: amountDistributionAgainstOwner }}
                                givenWidth={450}
                                givenHeight={450}
                                style={{
                                    width: "290px",
                                    height: "260px"
                                }}
                                givenOuterRadius={100}
                            />
                        </div>
                        <div className={classes.lagendsCont} >
                            {["Developer / Project owner", "Landowner", "Investor", "Partners"].map((type) => (
                                <div className={classes.colorTextCont} >
                                    <div
                                        style={{
                                            backgroundColor: COLORS?.[type],
                                            width: "15px",
                                            height: "15px",
                                            minWidth: "15px",
                                            minHeight: "15px",
                                            borderRadius: "3px"
                                        }}
                                    ></div>
                                    <p>{type}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Paper>
            <Paper
                style={{ width: "calc(100% - 435px)", borderRadius: "10px", padding: "15px" }}
                elevation={2}
            >
                <h3 style={{ marginBottom: "15px" }} className={classes.headingSty}  >Ownership Amount Distribution</h3>
                {loading ? (
                    <Skeleton
                        variant="rect"
                        style={{
                            width: "100%",
                            height: "300px",
                            borderRadius: "10px"
                        }}
                    />
                ) : (
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" >
                                        Ownership
                                    </TableCell>
                                    <TableCell align="center"  >
                                        Project Owner
                                    </TableCell>
                                    <TableCell align="center" >
                                        Land Owner
                                    </TableCell>
                                    <TableCell align="center" >
                                        Partners
                                    </TableCell>
                                    <TableCell align="center"  >
                                        Investors
                                    </TableCell>
                                    <TableCell align="center"  >
                                        Total
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {totalAmountDistributionAgainstOwner && totalAmountDistributionAgainstOwner.map((rowData) => (
                                    <TableRow>
                                        <TableCell align="center" >
                                            {rowData?.label}
                                        </TableCell>
                                        <TableCell align="center"  >
                                            ₹ {numberWithCommas(rowData?.projectOwnerAmount || 0)}
                                        </TableCell>
                                        <TableCell align="center"  >
                                            ₹ {numberWithCommas(rowData?.landOwnerAmount || 0)}
                                        </TableCell>
                                        <TableCell align="center" >
                                            ₹ {numberWithCommas(rowData?.partnersAmount || 0)}
                                        </TableCell>
                                        <TableCell align="center"  >
                                            ₹ {numberWithCommas(rowData?.InvestorAmount || 0)}
                                        </TableCell>
                                        <TableCell align="center"  >
                                            ₹ {numberWithCommas(rowData?.total || 0)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </div>
    </>
    );
};

export default UnitWiseAnalysis;