import React, { useEffect, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  AccountBalanceWalletOutlined,
  ExpandLess,
  ExpandMore,
  LocalOfferOutlined,
  ScheduleOutlined,
  PersonAddOutlined,
} from "@material-ui/icons";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PaymentTables from "../settings/PaymentTables";
import PaymentTable from "../settings/PaymentTable";
import DemandDeedSettings from "../settings/DemandDeedSettings";
import ScheduleEdit from "../settings/ScheduleEdit";
import Schedule from "../settings/Schedule";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import Schedules from "../settings/Schedules";
import DemandDeedSettingsCreate from "../settings/DemandDeedSettingsCreate";
import DemandDeedSettingView from "../settings/DemandDeedSettingView";
import { Email } from "@mui/icons-material";
import BookingBusinessEmail from "./BookingBusinessEmail";
import OnboardingTemplates from "../settings/OnboardingTemplates";

const BookingSettings = () => {
  const history = useHistory();
  const location = useLocation();
  const { profileId } = useParams();
  const [view, setView] = useState("paymenttables");
  const [subView, setSubView] = useState(null);
  const [toggledMenu, setToggledMenu] = useState("paymenttable");
  const [walletData, setWalletData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  // add here all subview to work Breadcrumbs properly
  useEffect(() => {
    const newView = searchParams.get("view");
    const newSubView =
      searchParams.get("scheduleId") ||
      searchParams.get("paymentTableId") ||
      searchParams.get("settingid") ||
      searchParams.get("offerId") ||
      searchParams.get("tableId");

    if (newView) {
      setView(newView);
    } else {
      setView("paymenttables");
    }

    setSubView(newSubView);
  }, [location.search]);

  const handleMenuToggle = (menu) => {
    setToggledMenu(toggledMenu === menu ? null : menu);
  };

  const changeView = (newView, subViewId = null) => {
    let url = `/booking/settings/${profileId}?view=${newView}`;
    if (subViewId) {
      url += `&${
        newView === "schedule" ? "scheduleId" : "paymentTableId"
      }=${subViewId}`;
    }
    history.push(url);
  };

  const drawerList = [
    {
      title: "Payment",
      icon: <AccountBalanceWalletOutlined />,
      code: "paymenttable",
      subMenu: [
        {
          title: "Payment Tables",
          code: "paymenttables",
        },
      ],
    },
    {
      title: "Offer",
      icon: <LocalOfferOutlined />,
      code: "offer",
      subMenu: [
        {
          title: "My Offers",
          code: "myoffers",
        },
      ],
    },
    {
      title: "DemandDeed",
      icon: <HandshakeOutlinedIcon />,
      code: "demanddeedsettings",
      subMenu: [
        {
          title: "DemandDeed Settings",
          code: "demanddeedsettings",
        },
      ],
    },
    {
      title: "Schedule",
      icon: <ScheduleOutlined />,
      code: "schedule",
      subMenu: [
        {
          title: "My Schedules",
          code: "schedules",
        },
      ],
    },
    {
      title: "Email",
      icon: <Email />,
      code: "email",
      subMenu: [
        {
          title: "Business Email",
          code: "businessEmail",
        },
      ],
    },
    {
      title: "Onboarding",
      icon: <PersonAddOutlined />,
      code: "onboarding",
      subMenu: [
        {
          title: "Document Templates",
          code: "documentTemplates",
        },
      ],
    },
  ];

  const viewArray = [
    { title: "Payment", code: "paymenttable", subView: true },
    { title: "Payment Tables", code: "paymenttables", subView: true },
    { title: "Offer", code: "offer", subView: false },
    { title: "My Offers", code: "myoffers", subView: true },
    { title: "DemandDeed", code: "demanddeedsettings", subView: false },
    { title: "DemandDeed Settings", code: "demanddeedsettings", subView: true },
    {
      title: "DemandDeed Setting",
      code: "demandDeedSettingsId",
      subView: false,
    },
    { title: "Schedule", code: "schedule", subView: false },
    { title: "My Schedules", code: "schedules", subView: true },
    {
      title: "Create Demand Deed",
      code: "createdemanddeed",
      subView: true,
    },
    {
      title: "Email",
      code: "email",
      subView: true,
    },
    {
      title: "Onboarding",
      code: "onboarding",
      subView: false,
    },
    {
      title: "Document Templates",
      code: "documentTemplates",
      subView: true,
    },
  ];

  const getTitle = (viewCode) => {
    const view = viewArray.find((item) => item.code === viewCode);
    return view ? view.title : "";
  };

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId: profileId })
        .then((data1) => {
          setWalletData(data1);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPageLoading(false);
        });
    }
  }, [profileId]);

  const NavigationBreadcrumbs = () => (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Typography
        sx={{
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        color="inherit"
        onClick={() => {
          history.push(`/booking/${profileId}`);
        }}
      >
        Booking
      </Typography>
      <Typography
        sx={{
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        color="inherit"
        onClick={() => {
          history.push(`/booking/settings/${profileId}`);
        }}
      >
        Booking Settings
      </Typography>
      <Typography
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
        }}
        color="inherit"
        onClick={() => {
          history.push(`/booking/settings/${profileId}?view=${view}`);
        }}
      >
        {getTitle(view)}
      </Typography>
      {subView && (
        <Typography
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          color="textPrimary"
          onClick={() => {
            history.push(
              `/booking/settings/${profileId}?view=${view}&${
                view === "schedule"
                  ? "scheduleId"
                  : view === "paymenttable"
                  ? "paymentTableId"
                  : view === "demanddeedsettings"
                  ? "demandDeedSettingsId"
                  : view === "myoffers"
                  ? "offerId"
                  : "id"
              }=${subView}`
            );
          }}
        >
          {getTitle(view)} Details
        </Typography>
      )}
    </Breadcrumbs>
  );

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Booking"
      display="flex"
      onAppBarBackButtonClick={() => {
        history.push(`/booking/${profileId}`);
      }}
      RenderBreadcrumbs={NavigationBreadcrumbs}
    >
      {walletData?.defaultLibrary ? (
        <>
          <Box flex="0.2">
            <List
              sx={{ width: "100%", bgcolor: "background.paper" }}
              component="nav"
            >
              {drawerList.map((item) => (
                <React.Fragment key={item.code}>
                  <ListItemButton onClick={() => handleMenuToggle(item.code)}>
                    <ListItemIcon
                      sx={{
                        color: (theme) =>
                          toggledMenu === item.code
                            ? theme.palette.primary.main
                            : "auto",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {toggledMenu === item.code ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={toggledMenu === item.code}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subMenu.map((subItem) => (
                        <ListItemButton
                          key={subItem.code}
                          sx={{ pl: 10 }}
                          onClick={() => changeView(subItem.code)}
                        >
                          <ListItemText
                            primary={subItem.title}
                            primaryTypographyProps={{
                              color: view === subItem.code ? "primary" : "auto",
                              fontWeight:
                                view === subItem.code ? "bold" : "auto",
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Box>
          <Box flex="0.8" sx={{ ml: 2, maxHeight: "100%", overflowY: "auto" }}>
            <StandardAppContainerRounded fullHeight={true}>
              {view === "paymenttables" && (
                <PaymentTables
                  libraryId={walletData?.defaultLibrary}
                  onSelectPaymentTable={(id) => changeView("paymenttable", id)}
                />
              )}
              {view === "paymenttable" && (
                <PaymentTable paymentTableId={subView} />
              )}
              {view === "demanddeedsettings" && (
                <DemandDeedSettings libraryId={walletData?.defaultLibrary} />
              )}
              {view === "createdemanddeed" && (
                <DemandDeedSettingsCreate
                  libraryId={walletData?.defaultLibrary}
                />
              )}
              {view === "demandDeedSettingsId" && (
                <DemandDeedSettingView libraryId={walletData?.defaultLibrary} />
              )}
              {view === "schedules" && (
                <Schedules
                  libraryId={walletData?.defaultLibrary}
                  onSelectSchedule={(id) => changeView("schedule", id)}
                />
              )}
              {view === "schedule" && subView && (
                <Schedule
                  libraryId={walletData?.defaultLibrary}
                  scheduleId={subView}
                />
              )}
              {view === "scheduleedit" && (
                <ScheduleEdit libraryId={walletData?.defaultLibrary} profileId={profileId} />
              )}
              {view === "businessEmail" && (
                <BookingBusinessEmail libraryId={walletData?.defaultLibrary} />
              )}
              {view === "documentTemplates" && (
                <OnboardingTemplates libraryId={walletData?.defaultLibrary} />
              )}
            </StandardAppContainerRounded>
          </Box>
        </>
      ) : (
        <div>
          <p>
            No Library exists in the current directory or any library still
            didn't selected as default library.
            <br />
            Click{" "}
            <Link
              onClick={() => {
                history.push(`/offerings-categories/management/${profileId}`);
              }}
            >
              here
            </Link>{" "}
            to create and manage your library(s), category(s), product(s) and
            service(s)
          </p>
        </div>
      )}
    </StandardContainer>
  );
};

export default BookingSettings;
