import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Card,
  CardContent,
  Divider,
  Grid,
  Collapse,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Edit,
  Add,
  AccountBalance,
  Visibility,
  DateRange,
  Gavel,
  BookOnline,
  Person,
  AttachMoney,
  Receipt,
  ReceiptLong,
  MoneyOff,
} from "@mui/icons-material";
import StandardContainer from "../../styled/generic/StandardContainer";
import StatusChip from "../../styled/generic/StatusChip";
import Api from "../../../helpers/Api";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import TableContainer from "../../styled/generic/TableContainer";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import { SlabInfoSection } from "../booking/demandDeed/CreateDemandDeedDrawer";
import { allStatusOptions } from "../../../helpers/allStatuses";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import TransactionSection from "../booking/demandDeed/TransactionTable";
import config from "../../../config";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[2],
}));

const InfoItem = ({ icon, label, value }) => (
  <>
    <Grid item xs={12} sm={6} md={3}>
      <Box display="flex" alignItems="center">
        {icon}
        <Typography variant="subtitle2" color="text.secondary" sx={{ ml: 1 }}>
          {label}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="body1">{value}</Typography>
    </Grid>
  </>
);

const DemandDeedDetails = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { demandDeedId } = useParams();
  const dispatch = useDispatch();
  const [demandDeed, setDemandDeed] = useState();
  const [booking, setBooking] = useState();
  const [transactions, setTransactions] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [schedule, setSchedule] = useState({});
  const [slab, setSlab] = useState({});
  const [subject, setSubject] = useState("");
  const [status, setStatus] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initialStatus, setInitialStatus] = useState("Draft");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedDialogMessage, setSelectedDialogMessage] = useState(0);
  const [usedComponents, setUsedComponents] = useState([]);

  const confirmationDialogContent = [
    {
      title: "Confirm Status Change to Sent",
      message:
        "Are you sure you want to set the status to Sent? Once confirmed, the demand deed will become uneditable, and this action cannot be undone.",
      confirmFn: () => {
        setEmailDialogOpen(true);
        setDialogOpen(false);
      },
      cancelFn: () => {
        setDialogOpen(false);
      },
    },
    {
      title: "Action Restricted: Verification Required",
      message:
        "You cannot set the status to Sent because your business email has not been verified. Please verify your email to proceed. Click Confirm to go to the Accounts section.",
      confirmFn: () => {
        const isDev = config?.mode == "dev";
        const redirectUrl = isDev
          ? `${config.BASE_URL}account`
          : `https://accounts.reallist.ai/account`;
        window.open(redirectUrl);
      },
      cancelFn: () => {
        setDialogOpen(false);
      },
    },
  ];

  const updateDemandDeed = async (selectedEmail = null) => {
    setButtonLoading(true);
    try {
      if (!subject.trim()) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please enter a title for the Demand Deed",
          },
        });
        return;
      }

      if (!transactions || transactions.length === 0) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please add at least one transaction",
          },
        });
        return;
      }

      let payload;
      let apiUrl;

      if (status === "Paid") {
        payload = {
          demandDeedId,
          data: {
            title: subject,
            status: "Paid",
            bankAccountId: booking?.bankAccountId,
            billingAccount: booking?.billingAccount,
            booking: booking,
          },
          transactions: transactions.map((transaction) => ({
            _id: transaction.transactionData._id,
            billItems: transaction.billItems.map((item) => ({
              ...item,
              detail: item.detail || item._id,
              receivedAgainstCurrentDue: parseInt(
                item.receivedAgainstCurrentDue || 0,
                10
              ),
              rebateAmount: parseInt(item.rebateAmount || 0, 10),
              penaltyDue: parseInt(item.penaltyDue || 0, 10),
              chargeAmount: parseInt(item.chargeAmount || 0, 10),
            })),
          })),
        };
        apiUrl = `listing/booking/payment/demanddeed/paid`;
      } else {
        payload = {
          demandDeedId,
          demandDeedData: {
            title: subject,
            status: status,
            bankAccountId: booking?.bankAccountId,
            billingAccount: booking?.billingAccount,
          },
          transactions: transactions.map((transaction) => ({
            transactionData: {
              ...transaction.transactionData,
              booking: booking._id,
              bankAccountId: booking?.bankAccountId,
              billingAccount: booking?.billingAccount,
              status: status,
              paymentDue: transaction.billItems.reduce(
                (acc, item) => acc + parseInt(item.currentDue || 0, 10),
                0
              ),
              amountPaid: transaction.billItems.reduce(
                (acc, item) =>
                  acc + parseInt(item.receivedAgainstCurrentDue || 0, 10),
                0
              ),
              amountPayable: transaction.billItems.reduce(
                (acc, item) =>
                  acc + parseInt(item.totalAmountReceivable || 0, 10),
                0
              ),
            },
            billItems: transaction.billItems,
          })),
        };
        apiUrl = "listing/booking/payment/demanddeed/update";
      }

      if (selectedEmail) {
        if (payload.data) {
          payload.data.sentFromEmail = selectedEmail;
        } else if (payload.demandDeedData) {
          payload.demandDeedData.sentFromEmail = selectedEmail;
        }
      }

      const response = await Api.post(apiUrl, payload);

      if (response && response.data) {
        setEditMode(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message:
              status === "Paid"
                ? "Demand Deed paid successfully"
                : "Demand Deed updated successfully",
          },
        });

        if (status === "Paid") {
          setDemandDeed(response.data.demandDeed);
          if (response.data.demandDeed.transactions) {
            setTransactions(response.data.demandDeed.transactions);
          }
        }

        getDemandDeed();
      } else {
        throw new Error(
          response.message ||
            `Failed to ${
              status === "Paid" ? "process payment for" : "update"
            } Demand Deed`
        );
      }
    } catch (error) {
      console.error(
        `Error ${
          status === "Paid" ? "processing payment for" : "updating"
        } Demand Deed:`,
        error
      );
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message:
            error.message ||
            `An error occurred while ${
              status === "Paid" ? "processing the payment for" : "updating"
            } the Demand Deed`,
        },
      });
    } finally {
      setButtonLoading(false);
    }
  };
  const handleSave = () => {
    if (status === "Sent") {
      if (
        !user?.verifiedBusinessEmails ||
        user.verifiedBusinessEmails.length === 0
      ) {
        setDialogOpen(true);
        setSelectedDialogMessage(1);
        return;
      }
      setDialogOpen(true);
      setSelectedDialogMessage(0);
      return;
    }
    updateDemandDeed();
  };

  const handleEmailSelection = () => {
    setEmailDialogOpen(false);
    updateDemandDeed(selectedEmail);
  };

  useEffect(() => {
    const usedPaymentDetails = transactions.reduce((total, acc) => {
      return [
        ...total,
        ...acc.billItems.map((item) => item?.componentRef?._id),
      ];
    }, []);

    setUsedComponents(usedPaymentDetails);
  }, [transactions]);

  const getDemandDeed = async () => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/demanddeed/get-by-id",
        {
          demandDeedId,
        }
      );
      if (data) {
        setUserInfo(data?.booking?.customerProfile);
        setDemandDeed(data);
        setInitialStatus(data?.status);
        setStatus(data?.status);
        setSchedule(data?.booking?.schedule);
        const filteredSlabs = data?.booking?.schedule.slabs.filter(
          (item) => item?.isCompleted
        );
        setSlab(filteredSlabs[filteredSlabs.length - 1] || {});
        const setupTransactions = data.transactions.map((item) => ({
          transactionData: {
            _id: item._id || "",
            booking: item.booking || "",
            subject: item.subject || "",
            bankAccountId: item.bankAccountId?._id || "",
            status: item.status || "",
            profile: item.profile || "",
            paymentDue: item.paymentDue || 0,
            amountPaid: item.amountPaid || 0,
            amountPayable: item.amountPayable || 0,
          },
          billItems: item.billItems || [],
        }));

        setTransactions(setupTransactions);
        setBooking(data?.booking);
        setSubject(data?.title);
        setTemplates(
          data?.booking?.paymentTable?.demandDeedSettings?.transactions
        );
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setPageLoading(false);
  };

  useEffect(() => {
    getDemandDeed();
  }, []);

  console.log(booking?.paymentDetails, "booking.paymentDetails");
  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Demand Deed"
      loading={pageLoading}
    >
      <StyledPaper elevation={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box>
            {editMode ? (
              <TextField
                label="Title"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <Receipt sx={{ color: "action.active", mr: 1 }} />
                  ),
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: demandDeed?.title,
                }}
                style={{
                  fontSize: "22px",
                  fontWeight: "500",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              />
            )}
            <Typography variant="body2" display="block">
              <BookOnline sx={{ mr: 0.5 }} />
              Booking ID: {booking?._id}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {editMode ? (
              <Select
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                size="small"
                sx={{
                  width: "250px",
                }}
              >
                {allStatusOptions["DemandDeed"][initialStatus].map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
                {["Sent", "Checking", "Paid", "Cancelled", "Draft"].map(
                  (item) => (
                    <MenuItem
                      value={item}
                      sx={{
                        display: "none",
                      }}
                    >
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            ) : (
              <StatusChip
                label={demandDeed?.status}
                variant="Completed"
                rounded={true}
              />
            )}
            <Tooltip
              title={editMode ? "Switch to view mode" : "Switch to edit mode"}
            >
              <IconButton
                color={editMode ? "primary" : "default"}
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? <Visibility /> : <Edit />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <SingleUserWithCaptionTableCell
            url={userInfo?.parent?.displayPicture?.url}
            label={userInfo?.parent?.displayName}
          />
          <Box
            sx={{
              display: "flex",
              gap: "15px",
            }}
          >
            <StatusChip
              label={booking?.status}
              variant="Completed"
              rounded={true}
              sx={{ mr: 1 }}
            />
            <Chip
              icon={<AccountBalance />}
              label={`Invite code: ${booking?.inviteCode}`}
              variant="outlined"
            />
          </Box>
        </Box>

        <Grid container spacing={2}>
          <InfoItem
            icon={<DateRange color="action" />}
            label="Registration Date"
            value={new Date(booking?.registrationDate).toLocaleDateString()}
          />
          <InfoItem
            icon={<Gavel color="action" />}
            label="Sanctioned/Unsanctioned"
            value={booking?.sanctioned ? "Sanctioned" : "Unsanctioned"}
          />
          <InfoItem
            icon={<DateRange color="action" />}
            label="Booking Date"
            value={new Date(booking?.bookingDate).toLocaleDateString()}
          />
          <InfoItem
            icon={<Person color="action" />}
            label="Closing Manager"
            value={booking?.closingManager?.parent?.displayName ?? "N/A"}
          />
          <InfoItem
            icon={<AttachMoney color="action" />}
            label="Agreement Value"
            value={`₹${booking?.aggreementValue ?? 0}`}
          />
        </Grid>
      </StyledPaper>

      <SlabInfoSection slab={slab} />

      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom color={"primary"}>
          <ReceiptLong sx={{ verticalAlign: "middle", mr: 1 }} />
          Transactions
        </Typography>
        {editMode && demandDeed.status === "Draft" ? (
          <>
            {transactions.map((transaction, index) => (
              <TransactionSection
                key={index}
                initialData={transaction}
                index={index}
                setTransactions={setTransactions}
                bookingId={booking?._id}
                paymentDetails={booking?.paymentDetails}
                usedComponents={usedComponents}
              />
            ))}
            <Button
              startIcon={<Add />}
              color="primary"
              variant="outlined"
              size="large"
              sx={{ mt: 2, borderStyle: "dashed" }}
              onClick={() => {
                setTransactions((prev) => [
                  ...prev,
                  {
                    transactionData: {
                      booking: booking._id,
                      subject: "",
                      bankAccountId: "",
                      paymentStatus: "Draft",
                      slabId: [],
                      profile: user?.profile,
                      paymentDue: 0,
                      amountPaid: 0,
                      amountPayable: 0,
                    },
                    billItems: [],
                  },
                ]);
              }}
            >
              Add Transaction
            </Button>
          </>
        ) : (
          demandDeed?.transactions?.map((transaction, index) => (
            <TransactionCard
              transaction={transaction}
              key={index}
              index={index}
              history={history}
            />
          ))
        )}

        {editMode && (
          <Box mt={3}>
            <DuoButtonGroup
              primaryButtonText="Save"
              primaryButtonListener={handleSave}
              hideSecondary={true}
              loadingPrimary={buttonLoading}
            />
          </Box>
        )}
      </StyledPaper>

      <ConfirmationDialog
        cancelButtonText={"Cancel"}
        cancelListener={
          confirmationDialogContent[selectedDialogMessage].cancelFn
        }
        successButtonText={"Confirm"}
        successListener={
          confirmationDialogContent[selectedDialogMessage].confirmFn
        }
        open={dialogOpen}
        setOpen={setDialogOpen}
        message={confirmationDialogContent[selectedDialogMessage].message}
        title={confirmationDialogContent[selectedDialogMessage].title}
      />

      <Dialog open={emailDialogOpen} onClose={() => setEmailDialogOpen(false)}>
        <DialogTitle>Select Email to Send Demand Deed</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Email</InputLabel>
            <Select
              value={selectedEmail}
              onChange={(e) => setSelectedEmail(e.target.value)}
            >
              {user.verifiedBusinessEmails.map((email, index) => (
                <MenuItem key={index} value={email}>
                  {email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmailDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEmailSelection} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </StandardContainer>
  );
};

const TransactionCard = ({ transaction, index, history }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledCard key={index}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography variant="h6">
              <Receipt sx={{ verticalAlign: "middle", mr: 1 }} />
              Transaction {index + 1}:{" "}
            </Typography>
            <Typography variant="h6">
              <span
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
                dangerouslySetInnerHTML={{
                  __html: transaction?.subject,
                }}
              />
            </Typography>
          </Box>

          <Button
            variant="outlined"
            size="small"
            startIcon={<Visibility />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Hide Details" : "View Details"}
          </Button>
        </Box>

        <Collapse in={expanded}>
          <Box display="flex" alignItems="center" mb={2} gap={2}>
            <StatusChip
              label={transaction.status}
              variant={transaction.status === "draft" ? "Warning" : "Success"}
              rounded={true}
              sx={{ mr: 2 }}
            />
            <Chip
              icon={<AccountBalance />}
              label={transaction?.bankAccountId?.bankName}
              variant="outlined"
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() =>
                history.push(`/booking/transaction/view/${transaction._id}`)
              }
            >
              view
            </Button>
          </Box>
          {transaction?.billItems.length > 0 ? (
            <TableContainer
              columns={[
                "Charge Type",
                "Charge Amount",
                "Total Received",
                "Current Due",
                "Penalty Amount",
                "Discount Amount",
                "Total Payable",
                "Received Against Current Due",
              ]}
              data={transaction?.billItems}
            >
              <tbody>
                {transaction?.billItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.chargeType}</td>
                    <td>₹{item?.chargeAmount}</td>
                    <td>₹{item?.amountReceived}</td>
                    <td>₹{item?.currentDue}</td>
                    <td>₹{item?.penaltyDue}</td>
                    <td>₹{item?.rebateAmount}</td>
                    <td>₹{item?.totalAmountReceivable}</td>
                    <td>₹{item?.receivedAgainstCurrentDue}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          ) : (
            <Typography variant="body2" color="text.secondary" align="center">
              <MoneyOff sx={{ verticalAlign: "middle", mr: 0.5 }} />
              No Bill Items Found
            </Typography>
          )}
        </Collapse>
      </CardContent>
    </StyledCard>
  );
};

export default DemandDeedDetails;
