import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, TextField, Avatar, IconButton } from "@material-ui/core";
import emptyIcon from "../../Assets/FileIcon/emptyfolder.png";
import { getProfileForEntitySearch } from "../styled/CommonComponents/api.call";
import CancelIcon from "@material-ui/icons/Cancel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styled/CommonComponents/common.css";
import {
  resourceAccessHelper,
  resourceAccessRoleAccessMap,
} from "../../helpers/contactHelper";
import PaginatedEntityDropdown from "../styled/CommonComponents/PaginatedEntityDropdown";

const useStyles = makeStyles((theme) => ({
  searchCont: {
    padding: "0px 10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedCont: {
    width: "100%",
    height: "calc(100% - 70px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 10px 0px",
  },
  emptySty: {
    width: "100%",
    height: "100%",
    padding: "20px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "30%",
      height: "auto",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "300",
      textAlign: "center",
    },
  },
  cardShowFlex: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  nameRoleCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsCont: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    "& h3": {
      fontSize: "14px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "200",
    },
  },
  roleChip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "10px",
    fontWeight: "400",
    padding: "1px 5px",
    borderRadius: "7px",
    marginRight: "5px",
  },
}));

export default function ShareEntitySelectView({
  givenProfileIdArr = [],
  entity,
  curEntityId,
  currentUserRole,
  selectedEntities,
  setSelectedEntities,
  givenFilterOption,
  resourceId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [profileIdArr, setProfileIdArr] = useState([]);
  const [mainProfileWithParent, setMainProfileWithParent] = useState(null);
  const [changeState, setChangeState] = useState(false);

  useEffect(() => {
    if (curEntityId && entity) {
      getProfileForEntitySearch({
        entityId: curEntityId,
        type: entity,
      })
        .then((data) => {
          const profiles = data?.profiles || [];
          const currentProfileWithParent = data?.currentProfileWithParent;
          let locProfileIdArr = new Set([...profiles]);
          setProfileIdArr([...locProfileIdArr]);
          setMainProfileWithParent(currentProfileWithParent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [curEntityId, entity]);

  const selectEntityFunction = (value) => {
    if (!value) return;

    setChangeState(!changeState);
    const profileIds =
      selectedEntities?.map((entityProfile) => entityProfile?.data?._id) || [];

    if (profileIds.includes(value?.data?._id)) {
      const filteredEntities = selectedEntities.filter(
        (entityProfile) => entityProfile?.data?._id !== value?.data?._id
      );
      setSelectedEntities([...filteredEntities]);
    } else {
      const obj = {
        ...value,
        role: "Viewer",
      };
      setSelectedEntities(
        selectedEntities ? [...selectedEntities, obj] : [obj]
      );
    }
    setChangeState(!changeState);
  };

  console.log(selectedEntities);

  return (
    <>
      <div className={classes.searchCont}>
        <PaginatedEntityDropdown
          onChange={selectEntityFunction}
          isMulti={false}
          entity={entity}
          curEntityId={curEntityId}
          givenProfileIdArr={profileIdArr}
          palCreate={false}
          givenFilterOption={givenFilterOption}
        />
      </div>
      <div className={classes.selectedCont}>
        {selectedEntities && selectedEntities.length > 0 ? (
          <>
            {selectedEntities.map((selectedEntity, index) => (
              <div className="simpleUserCardMainContDrawer">
                <div className={classes.nameRoleCont}>
                  <div className={classes.cardShowFlex}>
                    {selectedEntity?.data?.parentModelName === "OutSider" ? (
                      <Avatar
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                      >
                        {selectedEntity?.data?.parent?.displayPicture?.thumbUrl}
                      </Avatar>
                    ) : (
                      <Avatar
                        src={
                          selectedEntity?.data?.parent?.displayPicture?.thumbUrl
                            ? selectedEntity?.data?.parent?.displayPicture
                                ?.thumbUrl
                            : selectedEntity?.data?.parent?.displayPicture?.url
                        }
                        alt={selectedEntity?.data?.parent?.displayName}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "5px",
                        }}
                      />
                    )}
                    <div className={classes.detailsCont}>
                      <h3>{selectedEntity?.data?.parent?.displayName}</h3>
                      <p>{selectedEntity?.data?.parent?.username}</p>
                    </div>
                  </div>

                  <Autocomplete
                    options={
                      resourceId == null ||
                      resourceId === undefined ||
                      (currentUserRole &&
                        resourceAccessRoleAccessMap[currentUserRole]?.includes(
                          selectedEntity?.role
                        ))
                        ? resourceAccessRoleAccessMap[currentUserRole] || []
                        : resourceAccessHelper || []
                    }
                    value={selectedEntity?.role || null}
                    onChange={(event, value) => {
                      if (!value) return;
                      const dataArr = [...selectedEntities];
                      dataArr[index].role = value;
                      setSelectedEntities(dataArr);
                    }}
                    style={{ width: "200px", marginRight: "30px" }}
                    size="small"
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        {...params}
                        placeholder={"Select role"}
                      />
                    )}
                    disabled={
                      resourceId == null ||
                      resourceId === undefined ||
                      (currentUserRole &&
                        resourceAccessRoleAccessMap[currentUserRole]?.includes(
                          selectedEntity?.role
                        ))
                        ? false
                        : true
                    }
                  />
                </div>

                <span className="simpleUserCardTypeDrawer">
                  {`${selectedEntity?.data?.parentModelName} ${
                    selectedEntity?.data?.parentModelName === "Pal"
                      ? selectedEntity?.data?.parent?.type
                      : ""
                  }`}
                </span>
                <span className="closeBtn">
                  {resourceId == null ||
                  resourceId === undefined ||
                  (currentUserRole &&
                    resourceAccessRoleAccessMap[currentUserRole].includes(
                      selectedEntity?.role
                    )) ? (
                    <IconButton
                      onClick={() => {
                        selectEntityFunction(selectedEntity);
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  ) : null}
                </span>
              </div>
            ))}
          </>
        ) : (
          <div className={classes.emptySty}>
            <img src={emptyIcon} />
            <p>No User still selected!</p>
          </div>
        )}
      </div>
    </>
  );
}
