const { default: Api } = require("../../helpers/Api");

export const getPublicTemplatesApiCall = async (obj) => {
  try {
    const resResult = await Api.post("issue/template/getPublicTemplates", obj);
    const data = resResult?.data || [];
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllIssuesTemplatePaginated = async (obj) => {
  try {
    const resResult = await Api.post("issue/template/get/pagination", obj);
    const data = resResult || {};
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteIssueTemplate = async (obj) => {
  try {
    const resResult = await Api.post("issue/template/delete", obj);
    const data = resResult?.data || [];
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const createIssueTemplate = async (obj) => {
  try {
    const resResult = await Api.post("issue/template/create", obj);
    const data = resResult?.data || {};
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const createIssueByTemplate = async (obj) => {
  try {
    const resResult = await Api.post("issue/create", obj);
    return resResult;
  } catch (err) {
    console.log(err);
  }
};

export const getTemplatesToCreateIssues = async (obj) => {
  try {
    const resResult = await Api.post(
      "issue/templates/get/for/issue-create",
      obj
    );
    return resResult?.data || [];
  } catch (err) {
    console.log(err);
  }
};

export const getFilterOptionsForIssues = async (obj) => {
  try {
    const resResult = await Api.post(
      "issue/get/filter/options/for-issues",
      obj
    );
    return resResult?.data || {};
  } catch (err) {
    console.log(err);
  }
};

export const getPaginatedTickets = async (obj) => {
  try {
    const resResult = await Api.post("issue/get/with/pagination", obj);
    return resResult?.data || {};
  } catch (err) {
    console.log(err);
  }
};

export const getPaginatedTicketsByTemplate = async (obj) => {
  try {
    const resResult = await Api.post("issue/get/by-template/pagination", obj);
    return resResult?.data || {};
  } catch (err) {
    console.log(err);
  }
};

export const deleteIssueById = async (obj) => {
  try {
    const resResult = await Api.post("issue/delete", obj);
    return resResult;
  } catch (err) {
    console.log(err);
  }
};

export const getFilterOptionsForIssuesByTemplate = async (obj) => {
  try {
    const resResult = await Api.post(
      "issue/get/filter/options/against-template",
      obj
    );
    return resResult?.data || {};
  } catch (err) {
    console.log(err);
  }
};

export const getProjectByProfileOrId = async (obj) => {
  try {
    const resResult = await Api.post(
      "project/get/by/by-profileid/or/by-id",
      obj
    );
    return resResult?.data || [];
  } catch (err) {
    console.log(err);
  }
};

export const getIssuesActivitiesPaginated = async (obj) => {
  try {
    const resResult = await Api.post(
      "issue/activities/by-template-entity/paginated",
      obj
    );
    return resResult?.data || [];
  } catch (err) {
    console.log(err);
  }
};

export const getIssueActivitiesByTemplateStatus = async (obj) => {
  try {
    const resResult = await Api.post(
      "issue/activities/by-template-status",
      obj
    );
    return resResult?.data || [];
  } catch (err) {
    console.log(err);
  }
};
