import Api from '../../helpers/Api';

export const createApprovalTemplate = async (obj) => {
    try {
        const res = await Api.post('approval/template/create/newOne', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getApprovalTemplates = async (obj) => {
    try {
        const res = await Api.post('approval/template/get', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getApprovalTemplateById = async (obj) => {
    try {
        const res = await Api.post('approval/template/get-by-id/populated', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateApprovalTemplateById = async (obj) => {
    try {
        const res = await Api.post('approval/template/deep-update', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const addCommentApiCall = async (obj) => {
    try {
        const res = await Api.post('comment/add/new/for-approval', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getCommentsByParent = async (obj) => {
    try {
        const res = await Api.post('comments/get/pagination', obj);
        const result = res || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const takeActionAndAddCommment = async (obj) => {
    try {
        const res = await Api.post('approval/request/take/action', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getRequestStageById = async (obj) => {
    try {
        const res = await Api.post('approval/request/get/stage/by-id', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const createDefaultTemplate = async (obj) => {
    try {
        const resResult = await Api.post("approval/template/create/default", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getRequestsForApprovals = async (obj) => {
    try {
        const resResult = await Api.post("approval/request/get/all/by-profile", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createApprovalRequestFromMiddle = async (obj) => {
    try {
        const resResult = await Api.post("approval/request/create/from-middle", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getApprovalTemplatesByLibrary = async (obj) => {
    try {
        const resResult = await Api.post("approval/template/get/all/by-library", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const changeTemplateForExisitingRequest = async (obj) => {
    try {
        const resResult = await Api.post("approval/request/change/template/for-existing", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getApprovalStagesByRequestId = async (obj) => {
    try {
        const resResult = await Api.post("approval/request/get/stages/by-requestId", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getCommentsByStage = async (obj) => {
    try {
        const resResult = await Api.post("comments/get/by-stage", obj);
        const data = resResult;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const addCommentEmoji = async (obj) => {
    try {
        const resResult = await Api.post("comments/add/emoji-reaction", obj);
        const data = resResult?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};