import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CustomAccordion from "../styled/CommonComponents/CustomAccordion";
import { getCommentsByStage } from "./api.call";
import { Skeleton } from "@mui/material";
import { useDebounce } from "react-use";
import SingleComment from "./SingleComment";

const useStyles = makeStyles((theme) => ({
    headerSty: {
        fontSize: "18px",
        fontWeight: "600"
    },
    skeletonSty: {
        width: "100%",
        height: "80px !important",
        marginBottom: "20px"
    }
}));

export default function StageAndCommentDetails({
    stageData, index = null, changePageState,
    setReactions, setOpenReactionsDetails
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(index === 0 ? true : false)
    const [approvalAnalysis, setApprovalAnalysis] = useState({
        title: "",
        requiredMandatoryApprover: 0,
        requiredApprover: 0,
        actionsTaken: 0,
        approved: 0,
        rejected: 0,
        approver: 0,
        approvalType: "anybody"
    })

    useEffect(() => {
        let requiredMandatoryApprover = 0;
        let requiredApprover = stageData?.approvalsNeededCustom || 0;
        let actionsTaken = 0;
        let approved = 0;
        let rejected = 0;
        let approver = stageData.approvers.length;
        stageData.approvers.map((s) => {
            if (s?.mandatory) {
                requiredMandatoryApprover = requiredMandatoryApprover + 1
            }
            if (s?.isApproved || s?.isRejected) {
                actionsTaken = actionsTaken + 1
            }
            if (s?.isApproved) {
                approved = approved + 1
            }
            if (s?.isRejected) {
                rejected = rejected + 1
            }
        })
        if (stageData?.approvalType === "anybody" && stageData?.completed) {
            actionsTaken = 1
        } if (stageData?.approvalType === "anybody") {
            approver = "anybody"
        }
        setApprovalAnalysis({
            title: stageData?.status || "",
            requiredMandatoryApprover,
            requiredApprover,
            actionsTaken,
            approver,
            approvalType: stageData?.approvalType,
            approved,
            rejected,
        })
    }, [stageData])

    const getComments = async () => {
        setLoading(true)
        await getCommentsByStage({ stageId: stageData?._id })
            .then((data) => {
                console.log(data)
                setComments(data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useDebounce(() => {
        if (isAccordionOpen) {
            getComments()
        }
    }, 1000, [isAccordionOpen, changePageState])

    return (
        <div
            style={{ marginBottom: "20px" }}
        >
            <CustomAccordion
                defaultValue={index === 0 ? true : null}
                setIsAccordionOpen={setIsAccordionOpen}
                headerPart={
                    <h3 className={classes.headerSty} >
                        <span style={{ color: "green" }} >
                            {approvalAnalysis?.title}
                        </span>
                        {" "}
                        <span>
                            (Required {approvalAnalysis?.approvalType === "specific_different_power" ? `(${approvalAnalysis?.requiredMandatoryApprover} | ${approvalAnalysis?.requiredApprover || 0})` : approvalAnalysis?.requiredApprover || "01"})
                        </span>
                        <span style={{ color: "#2D76E0", marginLeft: "10px" }} >
                            {approvalAnalysis.actionsTaken}/{approvalAnalysis.approver}
                        </span>
                    </h3>
                }
                bodyPart={<div>
                    {loading ? (<>
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                        <Skeleton variant="rect" className={classes.skeletonSty} />
                    </>) : (<>
                        {(comments && comments.length > 0) ? (<>
                            {comments.map((comment) => (
                                <SingleComment
                                    comment={comment}
                                    setReactions={setReactions}
                                    setOpenReactionsDetails={setOpenReactionsDetails}
                                />
                            ))}
                        </>) : (
                            <div
                                style={{
                                    width: "100%",
                                    height: "200px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#999999"
                                }}
                            >
                                No Activity Has Started!
                            </div>
                        )}
                    </>)}
                </div>}
            />
        </div>
    );
}
