import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Chip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Percent } from "@mui/icons-material";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import { useDispatch } from "react-redux";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import TextArea from "../../styled/generic/TextArea";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getProfileData } from "../../team/procurements/api.call";
import ProjectPicker from "../../styled/generic/ProjectPicker";
import OrgPicker from "../../styled/generic/OrgPicker";
import { Edit as EditIcon } from "@mui/icons-material";
import EditOffIcon from "@mui/icons-material/EditOff";
import { makeStyles } from "@material-ui/core/styles";
import { getTeams } from "./api";
import { useSelector } from "react-redux";

const fetchBookings = async ({ libraryId }) => {
  const data = await Api.post("/listing/booking/get", { libraryId });
  if (data) {
    return data?.bookings;
  } else {
    console.error("Error fetching bookings:");
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  organizationCover: {
    position: "relative",
    width: "100%",
  },
  editOrg: {
    width: "fit-content",
    position: "absolute",
    right: "1rem",
    bottom: "3rem",
  },
}));

const CreateOfferModel = ({ open, setOpen, libraryId, onSuccess }) => {
  const classes = useStyles();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [formData, setFormData] = useState({
    project: null,
    organization: null,
    offerType: "",
    description: "",
    value: "",
    expiryDate: "",
    isActive: true,
    terms: "",
    team: null,
    actions: [],
    status: "Draft",
    theme: {
      primaryColor: "#FF6600",
      secondaryColor: "#FFF0E6",
    },
  });
  const [profileData, setProfileData] = useState(null);
  const [isDisabledOrg, setIsDisabledOrg] = useState(false);
  const [isDisabledProject, setIsDisabledProject] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (user?.profile) {
      getTeams(user.profile).then(setTeams);
    }
  }, [user]);

  useEffect(() => {
    if (profileId) {
      getProfileData({ profileId })
        .then((data) => {
          const walletData = data?.parent?.wallet || null;
          setWalletData(walletData);
          setFormData((prev) => ({
            ...prev,
            library: walletData.defaultLibrary,
          }));
          setProfileData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  // useEffect(() => {
  //   const getBookings = async () => {
  //     const response = await fetchBookings({
  //       libraryId,
  //     });
  //     if (response) {
  //       const filteredBookings = response.filter(
  //         (item) => item?.customerProfile?.parent?.displayName
  //       );
  //       setBookings(filteredBookings);
  //     } else {
  //       dispatch({
  //         type: "AddApiAlert",
  //         payload: {
  //           success: false,
  //           message: "Failed to fetch bookings",
  //         },
  //       });
  //     }
  //   };
  //   if (open && libraryId) {
  //     getBookings();
  //   }
  // }, [open, libraryId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = await Api.post("/booking/offer-create", formData);
      if (data) {
        setOpen(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Offer created successfully",
          },
        });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to create offer",
          },
        });
      }
    } catch (error) {
      console.error("Error creating offer:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to create offer",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerContainer title={"Create Offer"} open={open} setOpen={setOpen}>
      <Box
        sx={{
          minWidth: "60vw",
        }}
      >
        <SpaceBetween
          left={
            <FormBox label="Organization">
              <div className={classes.organizationCover}>
                <OrgPicker
                  disabled={isDisabledOrg}
                  selectedOrg={formData.organization}
                  setSelectedOrg={(org) =>
                    setFormData((prev) => ({ ...prev, organization: org }))
                  }
                  hideLabel={true}
                  fullWidth={true}
                />
                {isDisabledOrg ? (
                  <EditIcon
                    sx={{ fontSize: "1rem" }}
                    className={classes.editOrg}
                    onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                  />
                ) : (
                  <EditOffIcon
                    sx={{ fontSize: "1rem" }}
                    className={classes.editOrg}
                    onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                  />
                )}
              </div>
            </FormBox>
          }
          right={
            <FormBox label="Project">
              <div className={classes.organizationCover}>
                <ProjectPicker
                  disabled={isDisabledProject}
                  selectedProject={formData.project}
                  setSelectedProject={(project) =>
                    setFormData((prev) => ({ ...prev, project: project }))
                  }
                  fullWidth={true}
                />
                {isDisabledProject ? (
                  <EditIcon
                    sx={{ fontSize: "1rem" }}
                    className={classes.editOrg}
                    onClick={() => setIsDisabledProject(!isDisabledProject)}
                  />
                ) : (
                  <EditOffIcon
                    sx={{ fontSize: "1rem" }}
                    className={classes.editOrg}
                    onClick={() => setIsDisabledProject(!isDisabledProject)}
                  />
                )}
              </div>
            </FormBox>
          }
        />
        <FormBox label="Offer Type">
          <Select
            name="offerType"
            value={formData.offerType}
            onChange={handleChange}
            placeholder="Offer Type"
            fullWidth
          >
            {[
              "Discount",
              "Freebie",
              "Upgrade",
              "Referral Discounts",
              "Money-Back Guarantee",
              "Other",
            ].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormBox>
        <FormBox label="Description">
          <TextArea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            fullWidth
            minRows={5}
            maxRows={8}
            sx={{
              width: "100%",
              resize: "none",
            }}
          />
        </FormBox>
        <FormBox label="Terms">
          <TextArea
            name="terms"
            value={formData.terms}
            onChange={handleChange}
            placeholder="Terms"
            fullWidth
            minRows={5}
            maxRows={8}
            sx={{
              width: "100%",
              resize: "none",
            }}
          />
        </FormBox>
        <SpaceBetween
          right={
            <FormBox label="Expiry Date">
              <TextField
                name="expiryDate"
                type="date"
                value={formData.expiryDate}
                onChange={handleChange}
                placeholder="Expiry Date"
                fullWidth
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
              />
            </FormBox>
          }
          left={
            <FormBox label="Value">
              <TextField
                name="value"
                type="number"
                value={formData.value}
                onChange={handleChange}
                placeholder="Value"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent />
                    </InputAdornment>
                  ),
                }}
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label={"Status"}>
              <Select
                fullWidth
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    status: e.target.value,
                  }))
                }
                value={formData.status}
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Archived">Archived</MenuItem>
              </Select>
            </FormBox>
          }
        />
        <FormBox label="Team">
          <Select
            value={formData.team}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                team: e.target.value,
              }));
            }}
            fullWidth
          >
            {teams
              .filter((item) => item.title)
              .map((team) => (
                <MenuItem key={team._id} value={team._id}>
                  {team.title}
                </MenuItem>
              ))}
          </Select>
        </FormBox>
        <FormBox label={"Actions"}>
          <ActionInput
            defaultActions={formData.actions}
            onChange={(e) =>
              setFormData((prevData) => ({ ...prevData, actions: e }))
            }
          />
        </FormBox>
        <FormBox>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                name="isActive"
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    isActive: e.target.checked,
                  }));
                }}
              />
            }
            label="Set as Active"
          />
        </FormBox>
        <ThemeColorPicker
          theme={formData.theme}
          onChange={(newTheme) =>
            setFormData((prev) => ({ ...prev, theme: newTheme }))
          }
        />
        <DuoButtonGroup
          primaryButtonListener={handleSubmit}
          loadingPrimary={loading}
          disableSecondaryButton={loading}
          primaryButtonText={"Create Offer"}
          secondaryButtonText={"Cancel"}
          secondaryButtonListener={() => setOpen(false)}
        />
      </Box>
    </DrawerContainer>
  );
};

export default CreateOfferModel;

export const ActionInput = ({ onChange, defaultActions }) => {
  const [actions, setActions] = useState(defaultActions);
  const [newAction, setNewAction] = useState({
    actionType: "",
    reward: "",
    weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    openingHours: [],
  });
  const [editIndex, setEditIndex] = useState(-1);
  const [openDialog, setOpenDialog] = useState(false);

  const actionTypes = ["SiteVisit", "Booking"];
  const dayArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const usedActionTypes = actions.map((action) => action.actionType);

  const availableActionTypes = actionTypes.filter((type) => {
    if (editIndex !== -1) {
      return (
        type === actions[editIndex].actionType ||
        !usedActionTypes.includes(type)
      );
    }
    return !usedActionTypes.includes(type);
  });

  const handleAddAction = () => {
    if (newAction.actionType && newAction.reward) {
      const actionToAdd = {
        actionType: newAction.actionType,
        reward: newAction.reward,
        ...(newAction.actionType === "SiteVisit" && {
          weekDays: newAction.weekDays,
          openingHours: newAction.openingHours,
        }),
        ...(newAction.actionType === "Booking" && {
          weekDays: [],
          openingHours: [],
        }),
      };

      const updatedActions = [...actions, actionToAdd];
      setActions(updatedActions);
      setNewAction({
        actionType: "",
        reward: "",
        weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        openingHours: [],
      });
      onChange(updatedActions);
    }
  };

  const handleDeleteAction = (indexToDelete) => {
    const updatedActions = actions.filter(
      (_, index) => index !== indexToDelete
    );
    setActions(updatedActions);
    onChange(updatedActions);
  };

  const handleEditAction = (index) => {
    setEditIndex(index);
    setNewAction(actions[index]);
    setOpenDialog(true);
  };

  const handleUpdateAction = () => {
    if (newAction.actionType && newAction.reward) {
      const actionToUpdate = {
        actionType: newAction.actionType,
        reward: newAction.reward,
        ...(newAction.actionType === "SiteVisit" && {
          weekDays: newAction.weekDays,
          openingHours: newAction.openingHours,
        }),
        ...(newAction.actionType === "Booking" && {
          weekDays: [],
          openingHours: [],
        }),
      };

      const updatedActions = [...actions];
      updatedActions[editIndex] = actionToUpdate;
      setActions(updatedActions);
      setNewAction({
        actionType: "",
        reward: "",
        weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        openingHours: [],
      });
      setEditIndex(-1);
      setOpenDialog(false);
      onChange(updatedActions);
    }
  };

  const handleAddTimeSlot = () => {
    setNewAction((prev) => ({
      ...prev,
      openingHours: [
        ...prev.openingHours,
        { startTime: "09:00", endTime: "18:00" },
      ],
    }));
  };

  const handleTimeSlotChange = (index, field, value) => {
    const updatedHours = [...newAction.openingHours];
    updatedHours[index][field] = value;
    setNewAction((prev) => ({
      ...prev,
      openingHours: updatedHours,
    }));
  };

  const handleWeekDayChange = (day) => {
    setNewAction((prev) => ({
      ...prev,
      weekDays: prev.weekDays.includes(day)
        ? prev.weekDays.filter((d) => d !== day)
        : [...prev.weekDays, day],
    }));
  };

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {editIndex === -1 ? "Add Action" : "Edit Action"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
            <Select
              value={newAction.actionType}
              onChange={(e) =>
                setNewAction((prev) => ({
                  ...prev,
                  actionType: e.target.value,
                }))
              }
              label="Action Type"
              fullWidth
            >
              {availableActionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Reward"
              fullWidth
              value={newAction.reward}
              onChange={(e) =>
                setNewAction((prev) => ({ ...prev, reward: e.target.value }))
              }
              placeholder="e.g., Get Uber Paid, Rs 1000 on visit"
            />
            {newAction.actionType === "SiteVisit" && (
              <>
                <Button onClick={handleAddTimeSlot}>Add Time Slot</Button>
                {newAction.openingHours.map((slot, index) => (
                  <Box key={index} sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      label="Start Time"
                      type="time"
                      value={slot.startTime}
                      onChange={(e) =>
                        handleTimeSlotChange(index, "startTime", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="End Time"
                      type="time"
                      value={slot.endTime}
                      onChange={(e) =>
                        handleTimeSlotChange(index, "endTime", e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                ))}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {dayArr.map((day) => (
                    <Chip
                      key={day}
                      label={day}
                      onClick={() => handleWeekDayChange(day)}
                      color={
                        newAction.weekDays.includes(day) ? "primary" : "default"
                      }
                      variant={
                        newAction.weekDays.includes(day) ? "filled" : "outlined"
                      }
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              editIndex === -1 ? handleAddAction() : handleUpdateAction();
              setOpenDialog(false);
            }}
          >
            {editIndex === -1 ? "Add" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>

      {availableActionTypes.length > 0 && (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditIndex(-1);
            setNewAction({
              actionType: "",
              reward: "",
              weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              openingHours: [],
            });
            setOpenDialog(true);
          }}
          sx={{ mb: 2 }}
        >
          Add Action
        </Button>
      )}

      <Box display="flex" flexWrap="wrap" gap={1} maxWidth={"100%"}>
        {actions.map((action, index) => (
          <Chip
            key={index}
            label={`${action.actionType}: ${action.reward}`}
            onDelete={() => handleDeleteAction(index)}
            onClick={() => handleEditAction(index)}
            color="info"
            variant="outlined"
          />
        ))}
      </Box>
    </Box>
  );
};

export const ThemeColorPicker = ({ theme, onChange }) => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Theme Colors
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{
            p: "2px",
            width: "fit-content",
          }}
        >
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Primary Color
          </Typography>
          <input
            type="color"
            value={theme.primaryColor}
            onChange={(e) =>
              onChange({ ...theme, primaryColor: e.target.value })
            }
            style={{
              width: "50px",
              height: "50px",
              padding: "0",
              border: "none",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          sx={{
            p: "2px",
            width: "fit-content",
          }}
        >
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Secondary Color
          </Typography>
          <input
            type="color"
            value={theme.secondaryColor}
            onChange={(e) =>
              onChange({ ...theme, secondaryColor: e.target.value })
            }
            style={{
              width: "50px",
              height: "50px",
              padding: "0",
              border: "none",
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </>
  );
};
