import React from "react";
import { useDispatch } from "react-redux";
import { getBookingEstimates } from "../api";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Pagination,
  CircularProgress,
  Chip,
  PaginationItem,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import EstimateCard from "./EstimateCard";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "react-use";

const limit = 12;

const useStyles = makeStyles((theme) => ({
  bodyCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerCont: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "22px",
      fontWeight: "500",
    },
  },
  container: {
    width: "100%",
    height: "calc(100% - 110px)",
    overflowY: "auto",
    padding: "0px 15px 15px",
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #d2d1d1",
  },
}));

export default function EstimateList({ libraryId, projectIds }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEstimates, setTotalEstimates] = useState(0);
  const [search, setSearch] = useState("");

  const getEstimates = async () => {
    try {
      setLoading(true);
      const data = await getBookingEstimates({
        libraryId: libraryId,
        projectIds: projectIds,
        page: page,
        limit: limit,
        search: search,
      });
      setEstimates(data?.bookingEstimates || []);
      setTotalPages(data?.pagination?.pages || 1);
      setTotalEstimates(data?.pagination?.total || 0);
    } catch (error) {
      console.log("Error getting estimates::", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error getting estimates",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  useEffect(() => {
    getEstimates();
  }, [libraryId, projectIds, page]);

  useDebounce(
    () => {
      setPage(1);
      getEstimates();
    },
    500,
    [search]
  );

  return (
    <>
      <Paper className={classes.bodyCont}>
        <div className={classes.headerCont}>
          <div>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search by author name, customer name or phone"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              sx={{ minWidth: 350 }}
            />
          </div>
        </div>

        <div className={classes.container}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : estimates.length === 0 ? (
            <Box
              sx={{
                textAlign: "center",
                my: 4,
              }}
            >
              <Typography variant="body1" color="textSecondary">
                No estimates found.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {estimates.map((estimate) => (
                <Grid item lg={3} md={6} xs={12} key={estimate._id}>
                  <EstimateCard estimate={estimate} />
                </Grid>
              ))}
            </Grid>
          )}
        </div>

        {totalPages > 1 && (
          <div className={classes.paginationCont}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => handlePageChange(event, value)}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />
          </div>
        )}
      </Paper>
    </>
  );
}
