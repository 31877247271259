import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import { Box, TextField, Chip, Select, MenuItem, Avatar } from "@mui/material";
import { useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import { getBookingPaginatedTransactions } from "../api.call";
import { useDebounce } from "react-use";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import moment from "moment";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  filterAndTableCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  filterCont: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 20px",
    "& h3": {
      fontSize: "22px",
      fontWeight: "500",
    }
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 60px)",
    overflow: "hidden",
  },
  container: {
    width: "100%",
    height: "calc(100% - 50px)",
    "& tr": {
      height: "auto",
      outline: "1px solid #ececec",
    },
    "&.MuiTableCell-root": {
      border: "none",
    },
    "& table": {
      border: "none",
      borderCollapse: "collapse",
      tableLayout: "auto"
    },
    "& th": {
      border: "none",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px"
    },
    "& td": {
      border: "none",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "10px 10px",
    }
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #d2d1d1",
  },
  filterInputCont: {
    display: "flex",
    alignItems: "center",
  },
  rowSty: {
    width: "100%",
    height: "50px",
    marginBottom: "5px"
  }
}));

export const TransactionRow = ({ transaction, history, entityType }) => {
  console.log(transaction)
  return (<TableRow
    onClick={() => history.push(`/booking/transaction/view/${transaction?._id}`)}
    style={{ cursor: "pointer" }}
  >
    <TableCell align="left" >
      <span
        dangerouslySetInnerHTML={{
          __html: transaction?.subject,
        }}
      />
    </TableCell>
    <TableCell align="left" >
      <div style={{ display: "flex", alignItems: "center" }} >
        <Avatar
          src={transaction?.booking?.customerProfile?.parent?.displayPicture?.url}
          sx={{ width: 35, height: 35, mr: 1 }}
        />
        <div>
          <h3 style={{ fontSize: "16px", fontWeight: "500" }} >
            {transaction?.booking?.customerProfile?.parent?.displayName || "Unknown"}
          </h3>
          <p style={{ fontSize: "12px", fontWeight: "400" }} >
            {transaction?.booking?.customerProfile?.parent?.username}
          </p>
        </div>
      </div>
    </TableCell>
    {entityType === "Organization" && (
      <TableCell align="center" >
        {transaction?.project?.displayName}
      </TableCell>
    )}
    <TableCell align="center" >
      <Chip
        sx={{
          width: "fit-content",
        }}
        label={transaction?.type}
        variant="outlined"
      />
    </TableCell>
    <TableCell align="center"  >
      {transaction?.booking?.unit?.name}
    </TableCell>
    <TableCell align="center"  >
      {transaction?.booking?.projectBlock?.name}
    </TableCell>
    <TableCell align="center"  >
      <NumberFormat
        value={transaction?.amountPayable}
        displayType="text"
        thousandSeparator={true}
        prefix="₹"
      />
    </TableCell>
    <TableCell align="center"  >
      {moment(transaction?.dueDate).format("Do MMM YYYY")}
    </TableCell>
    <TableCell align="center"  >
      <StatusChip status={transaction?.status} />
    </TableCell>
    <TableCell align="right"  >
      {moment(transaction?.createdAt).format("Do MMM YYYY")}
    </TableCell>
  </TableRow>);
};

const StatusChip = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "draft":
        return "info";
      case "checking":
        return "warning";
      case "sent":
        return "error";
      case "paid":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Chip
        label={status}
        size="medium"
        color={getStatusColor(status)}
        sx={{
          width: "fit-content",
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        }}
      />
    </Box>
  );
};

const Transaction = ({ libraryId, profileId, reload }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [changeState, setChangeState] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [status, setStatus] = useState("");
  const [entityType, setEntityType] = useState("Project")

  const getTransactions = async () => {
    setLoading(true);
    await getBookingPaginatedTransactions({
      libraryId,
      profileId,
      page,
      limit: 15,
      searchTerm,
      sortBy,
      sortOrder,
      status,
    })
      .then((data) => {
        setTransactions(data.data);
        setTotalPages(data.totalPages);
        setLoading(false);
        setEntityType(data?.entityType ? "Organization" : "Project")
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error while fetching transactions",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useDebounce(() => {
    if (libraryId) {
      getTransactions();
    }
  }, 1000, [page, sortBy, sortOrder, profileId, libraryId, changeState]);

  useEffect(() => {
    setPage(1);
    setChangeState(!changeState);
  }, [status, reload, searchTerm]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleSortChange = (event) => {
    const [newSortBy, newSortOrder] = event.target.value.split(":");
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setPage(1);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
  };

  return (
    <div className={classes.filterAndTableCont} >
      <div className={classes.filterCont} >
        <h3>Transactions</h3>
        <div className={classes.filterInputCont} >
          <TextField
            placeholder="Search"
            variant="outlined"
            style={{ width: "250px" }}
            size="small"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Select
            value={`${sortBy}:${sortOrder}`}
            onChange={handleSortChange}
            style={{ width: "150px", margin: "0px 10px" }}
            size="small"
          >
            <MenuItem value="createdAt:desc">Newest First</MenuItem>
            <MenuItem value="createdAt:asc">Oldest First</MenuItem>
            <MenuItem value="amountPayable:desc">Amount (High to Low)</MenuItem>
            <MenuItem value="amountPayable:asc">Amount (Low to High)</MenuItem>
          </Select>
          <Select
            value={status}
            onChange={handleStatusChange}
            style={{ width: "150px" }}
            size="small"
            displayEmpty
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="checking">Checking</MenuItem>
            <MenuItem value="sent">Sent</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
          </Select>
        </div>
      </div>
      <div className={classes.bodyCont}>
        {loading ? (<>
          {Array.from({ length: 20 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rect"
              className={classes.rowSty}
            />
          ))}
        </>) : (<>
          {transactions?.length > 0 ? (
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label="sticky table"
                size={'small'}
                style={{ tableLayout: 'auto' }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" >
                      Subject
                    </TableCell>
                    <TableCell align="left" >
                      Customer
                    </TableCell>
                    {entityType === "Organization" && (
                      <TableCell align="center" >
                        Project
                      </TableCell>
                    )}
                    <TableCell align="center" >
                      Type
                    </TableCell>
                    <TableCell align="center" >
                      Unit
                    </TableCell>
                    <TableCell align="center"  >
                      Block
                    </TableCell>
                    <TableCell align="center"  >
                      Amount Payable
                    </TableCell>
                    <TableCell align="center"  >
                      Due Date
                    </TableCell>
                    <TableCell align="center"  >
                      Status
                    </TableCell>
                    <TableCell align="right"  >
                      Created
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions && transactions.map((transaction) => (
                    <TransactionRow
                      transaction={transaction}
                      history={history}
                      entityType={entityType}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (<div></div>)}
          <div className={classes.paginationCont} >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        </>)}
      </div>
    </div>
  );
};

export default Transaction;