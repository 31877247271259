import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PaymentTable from "../PaymentTable";

const EstimatePaymentSection = ({ estimateData }) => {
  const [estimateDetails, setEstimateDetails] = useState([]);

  useEffect(() => {
    if (estimateData?.estimateDetails && !estimateDetails.length > 0) {
      let localArr = estimateData?.estimateDetails || [];
      localArr.sort((a, b) => a.title.localeCompare(b.title));
      setEstimateDetails(localArr);
    }
  }, [estimateData]);

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PaymentTable
          paymentDetails={estimateDetails}
          setPaymentDetails={setEstimateDetails}
          slabs={estimateData?.schedule?.slabs || []}
          area={estimateData?.area}
          isEstimate={true}
        />
      </Box>
      <Box
        sx={{
          display: "none",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" gutterBottom mb={2}>
          Payment Schedule
        </Typography>
      </Box>
    </Box>
  );
};

export default EstimatePaymentSection;
