import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { MdOutlineArrowForwardIos } from "react-icons/md";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <MdOutlineArrowForwardIos
                style={{
                    transform: "rotate(270deg)",
                    fontSize: "25px"
                }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "#e7ecfd",
    flexDirection: 'row',
    padding: "5px 11px",
    justifyContent: 'space-between',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginRight: 'auto',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export default function CustomAccordion({
    headerPart, bodyPart, defaultValue, setIsAccordionOpen
}) {
    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
        if (defaultValue) {
            setExpanded(true)
        }
    }, [defaultValue])

    useEffect(() => {
        setIsAccordionOpen(expanded)
    }, [expanded])

    return (
        <Accordion
            expanded={expanded}
            onChange={(event) => { setExpanded(!expanded) }}
        >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                {headerPart}
            </AccordionSummary>
            <AccordionDetails>
                {bodyPart}
            </AccordionDetails>
        </Accordion>
    );
}