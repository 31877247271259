import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-root": { height: 40 },
    "& .MuiInputBase-input": { fontSize: 14, padding: "6px 8px" },
  },
}));

export default function BookingActivitiesList({ booking }) {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1}
        borderBottom="1px solid #ebeaea"
      >
        <Typography
          variant="h6"
          style={{ fontSize: "16px", fontWeight: "550" }}
        >
          Activities
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDrawer(true)}
          style={{ textTransform: "none" }}
        >
          Create Activity
        </Button>
      </Box>
      <ActivityForm openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </>
  );
}

const ActivityForm = ({ openDrawer, setOpenDrawer }) => {
  const classes = useStyles();
  const [customerType, setCustomerType] = useState("existingCustomer");
  const [eventType, setEventType] = useState("Site Visit");
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCreateActivity = async () => {
    setLoading(true);
    try {
      // Add your API call here
      // Example:
      // await Api.post("/booking/activity/create", {
      //   customerType,
      //   eventType,
      //   status,
      //   priority,
      //   date,
      //   startTime,
      //   endTime
      // });

      setOpenDrawer(false);
    } catch (error) {
      console.error("Error creating activity:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerContainer
      open={openDrawer}
      setOpen={setOpenDrawer}
      onClose={() => setOpenDrawer(false)}
      title="Add new activity"
    >
      <Box style={{ width: "400px" }}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="customer-type"
            name="customer-type"
            value={customerType}
            onChange={(e) => setCustomerType(e.target.value)}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              value="existingCustomer"
              control={<Radio color="primary" />}
              label="Existing Customer"
            />
            <FormControlLabel
              value="existingLead"
              control={<Radio color="primary" />}
              label="Existing Lead"
            />
            <FormControlLabel
              value="newLead"
              control={<Radio color="primary" />}
              label="Add New Lead"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mt={3} p={2}>
        <Typography variant="h6" style={{ marginBottom: "20px" }}>
          Schedule Event
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                options={[
                  "Meeting",
                  "Phone Call",
                  "Site Visit",
                  "Follow-up",
                  "Other",
                ]}
                value={eventType}
                onChange={(evt, newValue) => setEventType(newValue)}
                getOptionLabel={(option) => option || ""}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    size="small"
                    fullWidth
                    className={classes.input}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={date}
                onChange={(value) => setDate(value)}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    className: classes.input,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(value) => setStartTime(value)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        className: classes.input,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="End Time"
                    value={endTime}
                    onChange={(value) => setEndTime(value)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        className: classes.input,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          padding: "16px",
          borderTop: "1px solid #ebeaea",
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => setOpenDrawer(false)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateActivity}
          disabled={loading}
        >
          Save
        </Button>
      </Box>
    </DrawerContainer>
  );
};
