import React from "react";
import { Box, Typography, Avatar, Divider, Button } from "@mui/material";
import {
  CalendarToday,
  Home,
  Engineering,
  CurrencyRupee,
  Sell,
  Person,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { calculateBookingFinancials } from "../Bookings";
import NumberFormat from "react-number-format";
import { SALE_TYPE } from "../../../../helpers/constants";
import config from "../../../../config";

const EstimateCard = ({ estimate }) => {
  const history = useHistory();
  const isDev = config?.mode == "dev";

  const { agreementValue, totalDue, totalPaid } =
    calculateBookingFinancials(estimate);

  return (
    <Box
      sx={{
        borderRadius: "12px",
        overflow: "hidden",
        transition: "all 0.3s ease-in-out",
        cursor: "pointer",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
        border: "1px solid #e0e0e0",
      }}
      onClick={() => history.push(`/booking/estimate/view/${estimate?._id}`)}
    >
      <Box
        sx={{
          p: 1.5,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          pt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: "1.1rem",
              color: "primary.main",
              mb: 0,
            }}
          >
            🧮
            {estimate?.customerProfile?.parent?.displayName ||
              "No Lead assigned"}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.75 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Engineering
              sx={{ mr: 1, color: "primary.main", fontSize: "1.1rem" }}
            />
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              {estimate?.project?.displayName}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Home sx={{ mr: 1, color: "primary.main", fontSize: "1.1rem" }} />
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              Unit: {estimate?.unit?.name || "undefined"}
            </Typography>
          </Box>

          {estimate?.bookingType && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Sell sx={{ mr: 1, color: "primary.main", fontSize: "1.1rem" }} />
              <Typography variant="body2" color="text.primary" fontWeight={500}>
                {SALE_TYPE[estimate?.bookingType]?.label}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CurrencyRupee
              sx={{ mr: 1, color: "primary.main", fontSize: "1.1rem" }}
            />
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              Agreement Value:{" "}
              <NumberFormat
                value={agreementValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
                style={{ fontWeight: "600" }}
              />
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarToday
              sx={{ mr: 1, color: "primary.main", fontSize: "1.1rem" }}
            />
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              Created on: {new Date(estimate?.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
        </Box>

        {estimate?.docFolder && (
          <Box sx={{ mt: 1.5 }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              sx={{
                borderRadius: "6px",
                textTransform: "none",
                fontWeight: 500,
                py: 0.5,
              }}
              onClick={(e) => {
                e.stopPropagation();
                const path = `/doc/folder/${estimate?.docFolder?.profile}`;
                if (isDev) {
                  history.push(path);
                } else if (config.mode == "prod") {
                  let link = `https://reallist.ai/${path}`;
                  window.open(link, "_self");
                }
              }}
            >
              View Documents
            </Button>
          </Box>
        )}

        <Box sx={{ mt: "auto", pt: 1 }}>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.75,
            }}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight={500}
            >
              Author:
            </Typography>
            {estimate?.author ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                <Avatar
                  src={estimate?.author?.displayPicture?.url}
                  sx={{
                    width: 24,
                    height: 24,
                    border: "1px solid #f0f0f0",
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={500}
                >
                  {estimate?.author?.displayName}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 0.25,
                  fontStyle: "italic",
                }}
              >
                Not available
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EstimateCard;
