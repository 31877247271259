import React from 'react';
import {
    ComposedChart, Line, Area, XAxis, YAxis,
    CartesianGrid, Tooltip, ResponsiveContainer,
    Brush
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    return (
        <div 
        style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
        }}
        >
            <p>{label}</p>
            <p style={{ fontWeight: "bold" }} >₹ {new Intl.NumberFormat('en-US').format(payload[0].value)}</p>
        </div>
    );
};

const BookingTxChart = ({ data }) => {
    const formatYAxis = (value) => {
        return new Intl.NumberFormat('en-US').format(value);
    };

    return (
        <ResponsiveContainer width="100%" height={"95%"}>
            <ComposedChart
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    left: 60,
                    bottom: 40
                }}
            >
                <defs>
                    <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#4f92f7" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#a2c5fa" stopOpacity={0.6} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                    dataKey="date"
                />
                <YAxis
                    tickFormatter={formatYAxis}
                    tick={{ fill: '#666' }}
                    tickLine={false}
                    axisLine={false}
                    width={80}
                />
                 <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ stroke: '#4F46E5', strokeWidth: 1, strokeDasharray: '5 5' }}
                />
                <Area
                    type="linear"
                    dataKey="value"
                    fill="url(#colorValue)"
                    stroke="none"
                />
                <Line
                    type="linear"
                    dataKey="value"
                    stroke="#4F46E5"
                    strokeWidth={2}
                    dot={{
                        fill: '#4F46E5',
                        stroke: '#4F46E5',
                        r: 4
                    }}
                    activeDot={{
                        fill: '#4F46E5',
                        stroke: '#fff',
                        strokeWidth: 2,
                        r: 6
                    }}
                />
                <Brush
                    dataKey="date"
                    height={40}
                    stroke="#4F46E5"
                    fill="#fff"
                    travellerWidth={10}
                    startIndex={0}
                    endIndex={data.length - 1}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default BookingTxChart;