import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PaymentTable from "./PaymentTable";
import ScheduleTable from "./ScheduleTable";
import { getPaymentTableComponents, getPaymentScheduleComponents } from "./api";

const PaymentSection = ({ bookingData, setBookingData }) => {
  const [paymentComponents, setPaymentComponents] = useState([]);
  const [scheduleComponents, setScheduleComponents] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);

  useEffect(() => {
    if (bookingData?.paymentTable?._id) {
      getPaymentTableComponents(bookingData.paymentTable._id)
        .then(setPaymentComponents)
        .catch(console.error);
    }
  }, [bookingData?.paymentTable]);

  useEffect(() => {
    if (bookingData?.schedule?._id) {
      getPaymentScheduleComponents(bookingData.schedule._id)
        .then(setScheduleComponents)
        .catch(console.error);
    }
  }, [bookingData?.schedule]);

  const handleUpdatePaymentDetails = (updatedDetails) => {
    setBookingData((prev) => ({ ...prev, paymentDetails: updatedDetails }));
  };

  const handleUpdatePaymentSchedule = (updatedSchedule) => {
    setBookingData((prev) => ({ ...prev, paymentSchedule: updatedSchedule }));
  };

  useEffect(() => {
    if (bookingData.paymentDetails && !paymentDetails.length > 0) {
      console.log(bookingData.paymentDetails)
      let localArr = bookingData?.paymentDetails || [];
      localArr.sort((a, b) => a.title.localeCompare(b.title));
      setPaymentDetails(localArr);
    }
  }, [bookingData]);



  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PaymentTable
          paymentDetails={paymentDetails}
          onUpdatePaymentDetails={handleUpdatePaymentDetails}
          components={paymentComponents}
          area={bookingData.area}
          setPaymentDetails={setPaymentDetails}
          slabs={bookingData?.schedule?.slabs || []}
        />
      </Box>
      <Box
        sx={{
          display: "none",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" gutterBottom mb={2}>
          Payment Schedule
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentSection;
