import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, CircularProgress, Button } from "@mui/material";
import HorizBox from "../../../styled/generic/HorizBox";
import StatusChip from "../../../styled/generic/StatusChip";
import StandardContainer from "../../../styled/generic/StandardContainer";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import NumberFormat from "react-number-format";
import {
  getBookingEstimateById,
  generateBookingEstimatePdf,
  createBookingFromEstimate,
} from "../api";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TabsWithActions from "../../../styled/generic/TabsWithActions";
import { PaymentTableView } from "../PaymentTableView";
import { useDispatch } from "react-redux";

export default function EstimateView() {
  const { estimateId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("Table");
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [creatingBooking, setCreatingBooking] = useState(false);

  useEffect(() => {
    const fetchEstimate = async () => {
      setLoading(true);
      try {
        const response = await getBookingEstimateById({
          bookingEstimateId: estimateId,
        });
        setEstimate(response?.bookingEstimate);
      } catch (error) {
        console.error("Error fetching estimate:", error);
      } finally {
        setLoading(false);
      }
    };

    if (estimateId) {
      fetchEstimate();
    }
  }, [estimateId]);

  const handleGeneratePdf = async () => {
    try {
      setGeneratingPdf(true);
      const response = await generateBookingEstimatePdf({
        bookingEstimateId: estimateId,
      });
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const fileName = `estimate-${estimateId}`;
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName
          ? fileName + ".pdf"
          : response.headers.filename + ".pdf" || "file.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Estimate generated successfully",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to download the file",
          },
        });
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to download the file",
        },
      });
    } finally {
      setGeneratingPdf(false);
    }
  };

  const handleCreateBooking = async () => {
    try {
      setCreatingBooking(true);
      const response = await createBookingFromEstimate({
        estimateId: estimateId,
      });
      if (response) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Booking created successfully",
          },
        });
        history.push(`/booking/view/${response?.data?._id}`);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to create booking",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create booking",
        },
      });
    } finally {
      setCreatingBooking(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Booking Estimate Details"
      appBarActions={
        <HorizBox spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCreateBooking();
            }}
            disabled={creatingBooking || !estimate}
          >
            {creatingBooking ? "Creating..." : "Create Booking"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`/booking/estimate/edit/${estimateId}`)}
          >
            <EditIcon sx={{ mr: 1, fontSize: "1rem" }} />
            Edit
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGeneratePdf}
            disabled={generatingPdf || !estimate}
            startIcon={<PictureAsPdfIcon />}
          >
            {generatingPdf ? "Downloading..." : "Download PDF"}
          </Button>
        </HorizBox>
      }
    >
      {estimate && (
        <>
          <StandardAppContainerRounded>
            <Box sx={{ mb: 4 }}>
              <HorizBox sx={{ gap: 2, mb: 2 }}>
                <SingleUserWithCaptionTableCell
                  url={estimate?.customerProfile?.parent?.displayPicture?.url}
                  label={
                    estimate?.customerProfile
                      ? estimate?.customerProfile?.parent?.displayName ||
                        "No customer name"
                      : "No prospect added"
                  }
                />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <StatusChip
                    label="Estimate"
                    variant="Warning"
                    rounded={true}
                  />
                  <StatusChip
                    label={estimate?.status || "Draft"}
                    variant="Completed"
                    rounded={true}
                  />
                </Box>
              </HorizBox>
            </Box>

            <Grid container spacing={3} sx={{ mb: 4 }}>
              {[
                {
                  label: "Project",
                  value: (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {estimate?.project?.displayName || "N/A"}
                    </Typography>
                  ),
                },
                {
                  label: "Block",
                  value: (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {estimate?.projectBlock?.name || "N/A"}
                    </Typography>
                  ),
                },
                {
                  label: "Unit",
                  value: (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {estimate?.unit?.name || "N/A"}
                    </Typography>
                  ),
                },
                {
                  label: "Status",
                  value: estimate?.status || "Draft",
                },
                {
                  label: "Area",
                  value: (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {estimate?.area || 0} sq.ft
                    </Typography>
                  ),
                },
                {
                  label: "Saleable Area",
                  value: (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {estimate?.saleableArea || 0} sq.ft
                    </Typography>
                  ),
                },
                {
                  label: "Rate per sq.ft",
                  value: (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      ₹
                      <NumberFormat
                        value={estimate?.rsf || 0}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Typography>
                  ),
                },

                {
                  label: "Created At",
                  value: estimate?.createdAt
                    ? new Date(estimate.createdAt).toLocaleDateString()
                    : "N/A",
                },
                {
                  label: "Updated At",
                  value: estimate?.updatedAt
                    ? new Date(estimate.updatedAt).toLocaleDateString()
                    : "N/A",
                },
              ].map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={3}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "text.secondary",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "0.75rem",
                        mb: 0.5,
                      }}
                    >
                      {item.label}
                    </Typography>
                    {typeof item.value === "object" ? (
                      item.value
                    ) : (
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {item.value}
                      </Typography>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </StandardAppContainerRounded>
          <StandardAppContainerRounded>
            <TabsWithActions
              onTabChange={(val) => setTab(val)}
              currentTabVariable={tab}
              tabs={[
                {
                  title: "Table",
                },
              ]}
            />
          </StandardAppContainerRounded>
          <StandardAppContainerRounded>
            {tab === "Table" && (
              <Box
                sx={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                }}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "40vh",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={40} />
                  </Box>
                ) : !estimate?.estimateDetails?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "20vh",
                    }}
                  >
                    <Typography color={"GrayText"}>
                      Estimate table details not found
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <PaymentTableView data={estimate?.estimateDetails} />
                  </Box>
                )}
              </Box>
            )}
          </StandardAppContainerRounded>
        </>
      )}
    </StandardContainer>
  );
}
