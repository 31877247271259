import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FileView from "./FileView";
import GalleryViewer from "./GalleryViewer";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../../../helpers/Api";
import { setFiles } from "../fileUtils";
import { Typography, Dialog, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

const useStyles = makeStyles({
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  col: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: 22,
    fontWeight: 500,
    margin: "0rem 1rem",
    fontFamily: "Heebo",
  },
  videoWrapper: {
    position: "relative",
    width: 220,
    height: 140,
    margin: "0.5rem",
    background: "#fff",
    border: "1px solid #ccc",
    borderRadius: 8,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  videoThumbnail: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  playButton: {
    position: "absolute",
    fontSize: 48,
    color: "#fff",
    opacity: 0.8,
  },
  processingPlaceholder: {
    width: "100%",
    height: "100%",
    background: "white", // or a spinner or your custom "processing" placeholder
  },
});

const FilesViewer = (props) => {
  const classes = useStyles();
  const fileReducer = useSelector((state) => state.file);
  const dispatch = useDispatch();
  const { row } = classes;
  const { fileIds, isGallery, deletable, handler } = props;
  const { fileDictionary } = fileReducer;

  // For video playback
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState(null);

  useEffect(() => {
    // Load any missing files from server
    const newFileIds = (fileIds || []).filter((fileId) => {
      return fileDictionary[fileId] ? false : true;
    });

    if (newFileIds.length > 0) {
      Api.post("file/get", { files: newFileIds }).then((res) => {
        const { result: files } = res;
        setFiles(files, fileReducer, dispatch);
      });
    }
  }, [fileIds, fileDictionary, dispatch, fileReducer]);

  // Collections
  const ImageFileIds = [];
  const DocFileIds = [];
  const VideoFileIds = [];

  (fileIds || []).forEach((fileId) => {
    const file = fileDictionary[fileId];
    if (file && file._id && !file.deleted) {
      if (file.isVideo) {
        VideoFileIds.push(fileId);
      } else if (file.type?.startsWith("image/")) {
        ImageFileIds.push(fileId);
      } else {
        DocFileIds.push(fileId);
      }
    }
  });

  const handleOpenVideo = (muxPlaybackUrl) => {
    setActiveVideoUrl(muxPlaybackUrl);
    setVideoDialogOpen(true);
  };

  const handleCloseVideo = () => {
    setVideoDialogOpen(false);
    setActiveVideoUrl(null);
  };

  return (
    <div>
      {/* BLOCK FOR VIDEO FILES */}
      {VideoFileIds.length > 0 && (
        <div style={{ zIndex: 999 }}>
          <Typography variant="h4" className={classes.text} gutterBottom>
            Videos
          </Typography>
          <div className={row}>
            {VideoFileIds.map((fileId) => {
              const file = fileDictionary[fileId];
              const isProcessing = file.processed === false; // or !file.processed
              const thumbnailUrl = file.muxThumbnailUrl;
              const playbackUrl = file.muxPlaybackUrl;

              return (
                <div
                  key={fileId}
                  className={classes.videoWrapper}
                  onClick={() => {
                    if (!isProcessing && playbackUrl) {
                      handleOpenVideo(playbackUrl);
                    }
                  }}
                >
                  {isProcessing ? (
                    <div className={classes.processingPlaceholder} />
                  ) : (
                    <>
                      {/* Mux Thumbnail */}
                      {thumbnailUrl ? (
                        <img
                          src={thumbnailUrl}
                          alt="video thumbnail"
                          className={classes.videoThumbnail}
                        />
                      ) : (
                        <div className={classes.processingPlaceholder} />
                      )}
                      {/* Play Icon Overlay */}
                      {playbackUrl && (
                        <PlayCircleOutlineIcon className={classes.playButton} />
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* BLOCK FOR IMAGE FILES */}
      {ImageFileIds.length > 0 && (
        <div style={{ zIndex: 999 }}>
          <Typography variant="h4" className={classes.text} gutterBottom>
            Media
          </Typography>
          <div className={row}>
            {!isGallery ? (
              ImageFileIds.map((fileId) => {
                return (
                  <FileView
                    key={fileId}
                    fileId={fileId}
                    styleBody={props.styleBody}
                    deletable={deletable}
                    handler={handler}
                  />
                );
              })
            ) : (
              <GalleryViewer images={ImageFileIds} />
            )}
          </div>
        </div>
      )}

      {/* BLOCK FOR DOCUMENT FILES */}
      {DocFileIds.length > 0 && (
        <div>
          <Typography variant="h4" className={classes.text} gutterBottom>
            Documents
          </Typography>
          <div
            style={{
              display: "grid",
              gridGap: "6px",
              gridTemplateColumns: "repeat(auto-fill,minmax(220px,1fr))",
            }}
          >
            {DocFileIds.map((fileId) => {
              return (
                <FileView
                  key={fileId}
                  fileId={fileId}
                  styleBody={props.styleBody}
                  deletable={deletable}
                  handler={handler}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* VIDEO DIALOG */}
      <Dialog
        open={videoDialogOpen}
        onClose={handleCloseVideo}
        maxWidth="md"
        fullWidth
      >
        <IconButton
          style={{ alignSelf: "flex-end" }}
          onClick={handleCloseVideo}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {activeVideoUrl ? (
            <video
              style={{ width: "100%", height: "auto" }}
              src={activeVideoUrl}
              controls
              autoPlay
            />
          ) : (
            <Typography variant="body1">Invalid video URL</Typography>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FilesViewer;
