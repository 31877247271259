import React, { useEffect, useState } from "react";
import { getBookingChartAnalysisData } from "../api.call";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid,
    Tooltip, Brush, ResponsiveContainer, BarChart,
    Bar, ReferenceLine, Legend,
} from "recharts";
import AmountStatusPieGraph from "../../team/procurements/AmountStatusPieChart";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@mui/material";
import { getLinerStatuses } from "../../../helpers/approval";
import { useDebounce } from "react-use";
import Skeleton from '@mui/material/Skeleton';
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import BookingTxChart from "./BookingTxChart";

const COLORS = {
    "Draft": "#9B9B9B",         // Grey
    "Submitted": "#FF8C00",     // Dark Orange
    "Under Review": "#FF6347",  // Tomato Red
    "Approved": "#4682B4",      // Steel Blue
    "Confirmed": "#4682B4",      // Steel Blue
    "Rejected": "#FF4500",      // Orange Red
    "Evaluated": "#DA70D6",     // Orchid
    "Issued": "#32CD32",        // Lime Green
    "Acknowledged": "#1E90FF",  // Dodger Blue
    "Partially Fulfilled": "#FFD700", // Gold
    "Fully Fulfilled": "#2E8B57",    // Sea Green
    "Paid": "#87CEEB",          // Sky Blue
    "Fully Paid": "#87CEEB",          // Sky Blue
    "Partially Paid": "#FF69B4",     // Hot Pink
    "Sent": "#DDA0DD",         // Plum
    "Checking": "#FFA07A",      // Light Salmon
    "Cancelled": "#C0C0C0",    // Silver
    "Confirmed": "#4169E1",    // Royal Blue
    "In Progress": "#F4A460",   // Sandy Brown
    "Certified": "#20B2AA"     // Light Sea Green
}

const useStyles = makeStyles((theme) => ({
    lineChartSty: {
        zIndex: "100",
        width: "100%",
        height: "500px",
        padding: "15px 15px 50px",
        position: "relative",
        marginBottom: "20px",
        // "& h3": {
        //     fontSize: "18px",
        //     fontWeight: "500",
        //     marginBottom: "20px",
        //     marginLeft: "7px"
        // },
        "& .recharts-responsive-container": {
            marginLeft: "-20px"
        }
    },
    bothPieCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
        // "& h3": {
        //     fontSize: "18px",
        //     fontWeight: "500",
        //     margin: "20px 0px 0px 20px"
        // }
    },
    colorTextCont: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& p": {
            marginLeft: "5px",
            fontWeight: "500"
        }
    },
    pieLagendCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 40px 15px",
        "& p": {
            fontSize: "12px",
            fontWeight: "500",
            color: "#888888",
            marginLeft: "3px"
        }
    },
    pieChatCont: {
        width: "340px"
    },
    lagendsCont: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    skCardsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
    },
    titleAutocompleteSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .autocompletesCont": {
            display: "flex",
            alignItems: "center",
        }
    },
    headingSty:{
        fontSize: "22px",
        fontWeight: "500",
        color: "black",
        marginBottom: "15px"
    }
}));

function getLast20Years() {
    const currentYear = new Date().getFullYear();
    return (Array.from({ length: 20 }, (_, i) => currentYear - i)).map((s) => s.toString());
}

const BookingChartAnalysis = ({ profileId, filterObj, resetChildFilter }) => {
    const classes = useStyles();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [localFilterObj, setLocalFilterObj] = useState({
        month: null,
        year: null
    })

    const getChartData = () => {
        setLoading(true)
        getBookingChartAnalysisData({ profileId, filterObj, localFilterObj })
            .then((gpData) => {
                console.log(gpData)
                setData(gpData)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useDebounce(() => {
        getChartData()
    }, 1000, [filterObj, localFilterObj])

    useEffect(() => {
        if (resetChildFilter > 0) {
            setLocalFilterObj({
                month: null,
                year: null
            })
        }
    }, [resetChildFilter])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<>
        <Paper
            className={classes.lineChartSty}
            elevation={2}
            style={{ borderRadius: "10px" }}
        >
            <div className={classes.titleAutocompleteSty} >
                <h3 className={classes.headingSty} >Daily Transation Payment Trends(₹{numberWithCommas(data?.transactionsCountAmountBarChartData && data?.transactionsCountAmountBarChartData.length > 0 ? data?.transactionsCountAmountBarChartData.reduce((acc, data) => acc + (data?.amount || 0), 0) : 0)})</h3>
                <div className="autocompletesCont" >
                    <Autocomplete
                        value={localFilterObj?.month}
                        options={[
                            "January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                        ]}
                        getOptionLabel={(option) => { return option || "" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                margin="normal"
                                variant="outlined"
                                label="Month"
                                placeholder="Select month"
                            />
                        )}
                        onChange={(e, value) => {
                            setLocalFilterObj({
                                ...localFilterObj,
                                month: value
                            })
                        }}
                        style={{ width: "150px", marginRight: '10px' }}
                        size="small"
                    />

                    <Autocomplete
                        value={localFilterObj?.year}
                        options={getLast20Years()}
                        getOptionLabel={(option) => { return option || "" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                margin="normal"
                                variant="outlined"
                                label="Year"
                                placeholder="Select year"
                            />
                        )}
                        onChange={(e, value) => {
                            setLocalFilterObj({
                                ...localFilterObj,
                                year: value
                            })
                        }}
                        style={{ width: "150px" }}
                        size="small"
                    />
                </div>
            </div>
            {loading ? (
                <Skeleton
                    variant="rect"
                    style={{
                        width: "100%",
                        height: "420px",
                        borderRadius: "10px",
                        marginBottom: "20px"
                    }}
                />
            ) : (<>
                <BookingTxChart
                    data={data?.transactionsCountAmountBarChartData && data?.transactionsCountAmountBarChartData.length > 0 ?
                        data?.transactionsCountAmountBarChartData.map((s) => {
                            return {
                                date: s?.date,
                                value: s?.amount,
                                numberOfTransactions: s?.numberOfTransactions
                            }
                        })
                        : []}
                />
            </>)}
        </Paper>
        <div
            className={classes.bothPieCont}
            elevation={2}
        >
            <Paper style={{ width: "48%", borderRadius: "10px" }} >
                <h3 className={classes.headingSty} style={{ margin: "15px 0px 0px 15px" }} >Booking Status Distribution</h3>
                {loading ? (
                    <Skeleton
                        variant="rect"
                        style={{
                            width: "100%",
                            height: "300px",
                            borderRadius: "10px"
                        }}
                    />
                ) : (
                    <div className={classes.pieLagendCont} >
                        <div className={classes.pieChatCont} >
                            <AmountStatusPieGraph
                                data={{
                                    data: data?.bookingStatusData ? data?.bookingStatusData.map((s) => {
                                        return {
                                            name: s?.status,
                                            value: s?.count
                                        }
                                    }) : []
                                }}
                                givenWidth={550}
                                givenHeight={550}
                                givenOuterRadius={120}
                            />
                        </div>
                        <div className={classes.lagendsCont} >
                            {getLinerStatuses("Booking").map((status) => (
                                <div className={classes.colorTextCont} >
                                    <div
                                        style={{
                                            backgroundColor: COLORS?.[status],
                                            width: "15px",
                                            height: "15px",
                                            borderRadius: "3px"
                                        }}
                                    ></div>
                                    <p>{status}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Paper>
            <Paper
                style={{ width: "48%", borderRadius: "10px" }}
                elevation={2}
            >
                <h3 className={classes.headingSty} style={{ margin: "15px 0px 0px 15px" }} >Transaction Status Breakdown</h3>
                {loading ? (
                    <Skeleton
                        variant="rect"
                        style={{
                            width: "100%",
                            height: "300px",
                            borderRadius: "10px"
                        }}
                    />
                ) : (
                    <div className={classes.pieLagendCont} >
                        <div className={classes.pieChatCont} >
                            <AmountStatusPieGraph
                                data={{
                                    data: data?.transactionStatusCounts ? data?.transactionStatusCounts.map((s) => {
                                        return {
                                            name: s?.status,
                                            value: s?.count
                                        }
                                    }) : []
                                }}
                                givenWidth={550}
                                givenHeight={550}
                                givenOuterRadius={120}
                            />
                        </div>
                        <div className={classes.lagendsCont} >
                            {getLinerStatuses("Booking Transactions").map((status) => (
                                <div className={classes.colorTextCont} >
                                    <div
                                        style={{
                                            backgroundColor: COLORS?.[status],
                                            width: "15px",
                                            height: "15px",
                                            borderRadius: "3px"
                                        }}
                                    ></div>
                                    <p>{status}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Paper>
        </div>
        <Paper
            className={classes.lineChartSty}
            elevation={2}
            style={{ borderRadius: "10px" }}
        >
            <h3 className={classes.headingSty} >Daily Booking Trends</h3>
            {loading ? (
                <Skeleton
                    variant="rect"
                    style={{
                        width: "100%",
                        height: "420px",
                        borderRadius: "10px",
                        marginBottom: "20px"
                    }}
                />
            ) : (<>
                <BookingTxChart
                    data={data?.BookingsCountlineChartData && data?.BookingsCountlineChartData.length > 0 ?
                        data?.BookingsCountlineChartData.map((s) => {
                            return {
                                date: moment(s?.date).format("Do MMM YY"),
                                value: s?.bookings
                            }
                        })
                        : []}
                />
            </>)}
        </Paper>
    </>
    );
};

export default BookingChartAnalysis;
