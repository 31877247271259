import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory, Link } from "react-router-dom";
import ImageVideoViewer from "./ImageVideo.Viewer";
import DownloadDocCard from "./DownloadDocCard";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    width: "100%",
    height: "100%",
  },
  mainImgCont: {
    width: "100%",
    height: "calc(100% - 100px)",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down",
      float: "left",
    },
  },
  imgConts: {
    display: "flex",
    overflowX: "auto",
    width: "100%",
    paddingLeft: "15px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& div": {
      flexShrink: 0,
      width: "80px",
      height: "50px",
      overflow: "hidden",
      marginRight: "15px",
      marginBottom: "15px",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      cursor: "pointer",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
        float: "left",
      },
    },
  },
  docsCont: {
    display: "flex",
    flexWrap: "wrap",
  },
  uploadContainer: {
    width: "160px",
    height: "120px",
    margin: theme.spacing(2),
    position: "relative",
    borderRadius: "14px",
    overflow: "hidden",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 6px 16px rgba(0,0,0,0.15)",
    },
  },
  uploadButton: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F4F7FF",
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: "14px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#E8EFFF",
    },
  },
  uploadIcon: {
    width: "40px",
    height: "40px",
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  uploadText: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "14px",
  },
  removeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
  },
  progressOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "14px",
  },
  imageContainer: {
    position: "relative",
    "& img": {
      transition: "transform 0.3s ease",
    },
    "&:hover img": {
      transform: "scale(1.05)",
    },
  },
  loadingSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function AllFileViewerFeed({
  picVideoViewerHeight,
  picVideoViewerWidth,
  picVideoViewerHeightSmall,
  files,
  isDeletable,
  removeFun,
  disableEdit,
}) {
  const classes = useStyles();
  const [picVideosArr, setPicVideosArr] = useState([]);
  const [docsArr, setDocsArr] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const [mediaFiles, documents] = files?.reduce(
      (acc, file, i) => {
        const isMedia =
          file?.type?.startsWith("image/") || file?.type?.startsWith("video/");
        const fileObj = {
          _id: file?._id,
          index: i,
          url: file?.url,
          type: file?.type?.startsWith("image/") ? "photo" : "video",
        };

        acc[isMedia ? 0 : 1].push(isMedia ? fileObj : file);
        return acc;
      },
      [[], []]
    );

    setPicVideosArr(mediaFiles);
    setDocsArr(documents);
    setLoading(false);
  }, [files]);

  if (loading) {
    return (
      <div className={classes.loadingSpinner}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {picVideosArr.length > 0 && (
        <ImageVideoViewer
          data={picVideosArr}
          picVideoViewerHeight={picVideoViewerHeight}
          picVideoViewerWidth={picVideoViewerWidth}
          picVideoViewerHeightSmall={picVideoViewerHeightSmall}
          isDeletable={!disableEdit}
          removeFun={removeFun}
        />
      )}
      <div className={classes.docsCont}>
        {docsArr.map((file, i) => (
          <DownloadDocCard
            key={i}
            file={file}
            isDeletable={!disableEdit}
            removeFun={removeFun}
          />
        ))}
      </div>
    </>
  );
}
