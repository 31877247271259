import { IconButton, MenuItem, Select, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HorizBox from "../../../styled/generic/HorizBox";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import DatePicker from "../../../styled/generic/DatePicker";
import { Delete } from "@mui/icons-material";

export const SlabRow = ({ setSlabs, slabs, slab, index, change }) => {
  const [error, setError] = useState(false);
  const dueDate = new Date(slab?.dueDate);
  console.log(dueDate);

  function caculateCumulativePercentage() {
    setTimeout(() => { }, 2000);
    let newTotalCumulativePercentage = 0;
    slabs.slice(0, index + 1).forEach((item) => {
      newTotalCumulativePercentage += parseFloat(item.paymentPercentage) || 0;
    });
    return newTotalCumulativePercentage.toString() || 0;
  }

  function caculateCumulativePercentageAndSet() {
    setTimeout(() => { }, 2000);
    let newTotalCumulativePercentage = 0;
    slabs.slice(0, index + 1).forEach((item) => {
      newTotalCumulativePercentage += parseFloat(item.paymentPercentage) || 0;
    });
    const newSlabs = [...slabs];
    newSlabs[index].cumulativePercentage = newTotalCumulativePercentage || 0;
    setSlabs(newSlabs);
    if (newTotalCumulativePercentage > 100) {
      setError(true);
    } else {
      setError(false);
    }
  }

  useEffect(() => {
    caculateCumulativePercentage();
  }, [change]);

  return (
    <HorizBox spacing={2} marginBottom={"0.8rem"}>
      <FormBox
        label="Type"
        sx={{
          flex: 1,
        }}
      >
        <Select
          placeholder="Select slab"
          onChange={(e) => {
            const newSlabs = [...slabs];
            newSlabs[index].type = e.target.value;
            setSlabs(newSlabs);
          }}
          defaultValue={slab?.type}
          fullWidth={true}
        >
          <MenuItem value={"milestone"}>MileStone Based</MenuItem>
          <MenuItem value={"aggrement"}>Aggrement Based</MenuItem>
        </Select>
      </FormBox>
      <FormBox
        label="Title"
        sx={{
          flex: 1,
        }}
      >
        <TextField
          placeholder="Enter"
          value={slab.title}
          onChange={(e) => {
            const newSlabs = [...slabs];
            newSlabs[index].title = e.target.value;
            setSlabs(newSlabs);
          }}
          fullWidth={true}
        />
      </FormBox>
      <FormBox
        label="Description"
        sx={{
          flex: 1,
        }}
      >
        <TextField
          placeholder="Enter"
          value={slab.description}
          onChange={(e) => {
            const newSlabs = [...slabs];
            newSlabs[index].description = e.target.value;
            setSlabs(newSlabs);
          }}
          fullWidth={true}
        />
      </FormBox>

      <FormBox
        label="Due Date"
        sx={{
          flex: 1,
        }}
      >
        {slab?.type === "milestone" ? (
          <DatePicker
            defaultValue={slab?.dueDate || new Date.now()}
            onChange={(date) => {
              const newSlabs = [...slabs];
              newSlabs[index].dueDate = date;
              setSlabs(newSlabs);
            }}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "53px",
              borderRadius: "5px",
              backgroundColor: "#EBECED",
              border: "1px solid lightgray",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Agreement based</Typography>
          </Box>
        )}
      </FormBox>
      <FormBox
        label="Payment"
        sx={{
          flex: 1,
        }}
      >
        <TextField
          error={error}
          placeholder="Enter"
          value={slab.paymentPercentage}
          onChange={(e) => {
            const newSlabs = [...slabs];
            newSlabs[index].paymentPercentage = parseFloat(e.target.value) || 0;
            setSlabs(newSlabs);
            caculateCumulativePercentageAndSet();
          }}
          fullWidth={true}
        />
      </FormBox>
      <FormBox
        label="Cumulative"
        sx={{
          flex: 1,
        }}
      >
        <TextField
          disabled={true}
          placeholder="Enter"
          value={caculateCumulativePercentage()}
          onChange={(e) => {
            const newSlabs = [...slabs];
            newSlabs[index].cumulativePercentage = e.target.value;
            setSlabs(newSlabs);
          }}
          fullWidth={true}
        />
      </FormBox>

      <IconButton onClick={() => setSlabs(slabs.filter((_, i) => i !== index))}>
        <Delete />
      </IconButton>
    </HorizBox>
  );
};
