import Api from "../../../helpers/Api";

export const getBookingById = async (bookingId) => {
  const { data } = await Api.post("/listing/booking/get-by-id", { bookingId });
  return data;
};

export const updateBooking = async (bookingId, payload, brokerData) => {
  return await Api.post("/listing/booking/update", {
    bookingId,
    bookingData: payload,
    brokerData: brokerData,
  });
};

export const getPaymentTables = async (libraryId) => {
  const { data } = await Api.post("/listing/booking/payment/table/get", {
    libraryId,
  });
  return data;
};

export const getPaymentSchedules = async (libraryId, tableId) => {
  const { data } = await Api.post("/listing/booking/schedule/get", {
    libraryId,
    tableId,
  });
  return data;
};

export const getPaymentTableComponents = async (tableId) => {
  const { data } = await Api.post("listing/booking/payment/component/get", {
    tableId,
  });
  return data.data;
};

export const getPaymentScheduleComponents = async (scheduleId) => {
  const { data } = await Api.post("listing/booking/schedule/get-by-id", {
    scheduleId,
  });
  return data.data.slabs;
};

export const getTeams = async (profileId) => {
  const { data } = await Api.post(`/issue/template/getAll`, { profileId });
  return data.filter((item) => item?.isCRM === false);
};

export const getBillingAccounts = async (sharedIds) => {
  try {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      { sharedIds }
    );
    return { billingAccounts: response.billingAccounts || [] };
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const getBookingPaymentAmountAnalysis = async (obj) => {
  try {
    const response = await Api.post(`booking/payment/analysis/data`, obj);
    const result = response?.data;
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const getPopulatedBookingById = async (obj) => {
  try {
    const response = await Api.post(`/listing/booking/get-by-id`, obj);
    const result = response?.data;
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const getPaymentTableByLibrary = async (obj) => {
  try {
    const response = await Api.post(`/listing/booking/payment/table/get`, obj);
    const result = response?.data || [];
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const getScheduleByPaymentTable = async (obj) => {
  try {
    const response = await Api.post(`/listing/booking/schedule/get`, obj);
    const result = response?.data || [];
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const createBookingRecord = async (obj) => {
  try {
    const response = await Api.post(`/listing/booking/create/new`, obj);
    const result = response?.data || [];
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const savePaymentTable = async (obj) => {
  try {
    const response = await Api.post(
      `/listing/booking/payment/table/update`,
      obj
    );
    const result = response || null;
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const createBookingEstimate = async (obj) => {
  const response = await Api.post(`/bookings/estimate/create`, obj);
  return response;
};

export const getBookingEstimates = async (obj) => {
  const response = await Api.post(`/bookings/estimate/get`, obj);
  return response;
};

export const getBookingEstimateById = async (obj) => {
  const response = await Api.post(`/bookings/estimate/get-by-id`, obj);
  return response;
};

export const updateBookingEstimate = async (obj) => {
  const response = await Api.post(`/bookings/estimate/update`, obj);
  return response;
};

export const updateBookingEstimateDetails = async (obj) => {
  try {
    console.log("Update booking estimate details::", obj);
    const response = await Api.post(
      `/bookings/estimate/update-payment-table-details`,
      obj
    );
    const result = response || null;
    return result;
  } catch (error) {
    console.error("Error fetching billing accounts:", error);
    return { billingAccounts: [] };
  }
};

export const generateBookingEstimatePdf = async (obj) => {
  const response = await Api.post(`/bookings/estimate/generate-pdf`, obj, {
    responseType: "blob",
  });
  console.log("Response::", response);
  return response;
};

export const createBookingFromEstimate = async (obj) => {
  const response = await Api.post(`/bookings/estimate/create-booking`, obj);
  return response;
};
