import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import HorizBox from "../styled/generic/HorizBox";
import Button from "../styled/generic/Button";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  CircularProgress,
  Chip,
  LinearProgress,
  MenuList,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Choose from "../select/choose";
import NumberFormat from "react-number-format";
import {
  Add,
  AlarmOnOutlined,
  MessageOutlined,
  NoteOutlined,
  PhoneOutlined,
} from "@material-ui/icons";
import ProjectPicker from "../styled/generic/ProjectPicker.jsx";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import { useDispatch, useSelector } from "react-redux";
import DrawerContainer from "../styled/generic/DrawerContainer";
import SearchEntityDialog from "../styled/CommonComponents/SearchEntityDrawer";
import Select from "../styled/generic/Select";
import Api from "../../helpers/Api";
import dayjs from "dayjs";
import {
  Delete as DeleteIcon,
  EditOutlined,
  FilePresentOutlined,
} from "@mui/icons-material";
import DatePicker from "../styled/generic/DatePicker";
import TimePicker from "../styled/generic/TimePicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FilesViewer from "../file/Viewer/FilesViewer.js";
import useShareAndAssign from "../ResourceAccessControl/shareAndAssign";
import CreatePalDrawer from "../AddMember/CreatePalDrawer";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell";
import ProjectBlockAndUnitPickerDropdown from "../styled/generic/ProjectBlockAndUnitPickerDropdown";
import { GiArrowScope } from "react-icons/gi";
import InputBase from "@mui/material/InputBase";
import { updateDeleteFlagForSingleFiles } from "../propertyManagement/apiCall";
import Pagination from "@mui/material/Pagination";
import EventAddEdit from "./EventAddEdit.jsx";
import { Paper } from "@material-ui/core";
import { TbBrandBooking } from "react-icons/tb";
import CreateBookingModel from "../booking/booking/CreateBookingModel.jsx";
import { TbStatusChange } from "react-icons/tb";
import BookingCard from "../booking/booking/BookingCard.jsx";
import AddIcon from "@material-ui/icons/Add";
import NotifyAlert from "../styled/CommonComponents/NotifyAlert.js";
import { deleteIssueById } from "./api.call.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CreateEstimateModal from "../booking/booking/estimate/CreateEstimateModal.jsx";
import EstimateCard from "../booking/booking/estimate/EstimateCard.jsx";

const useStyles = makeStyles((theme) => ({
  emptyCont: {
    width: "100%",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "14px",
      color: "gray",
      marginTop: "5px",
    },
    "& svg": {
      fontSize: "100px",
    },
  },
  bookingCreateBtnCont: {
    width: "100%",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TIME_MODES = [
  "1 day",
  "1 week",
  "15 days",
  "1 months",
  "3 months",
  "6 months",
  "9 months",
  "1 year",
  "Custom",
];

let actionTabOptions = [
  {
    title: "Notes",
    icon: NoteOutlined,
  },
  {
    title: "Actions",
    icon: AlarmOnOutlined,
  },
  {
    title: "Documents",
    icon: FilePresentOutlined,
  },
  {
    title: "Tasks",
    icon: GiArrowScope,
  },
  {
    title: "SMS",
    icon: MessageOutlined,
  },
  {
    title: "Call",
    icon: PhoneOutlined,
  },
];

const ProfileIssueCRM = ({ getIssueCall, issue, setIssue }) => {
  console.log("Issue::", issue);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { fileDictionary, createdFileIds } = useSelector((state) => state.file);
  const { user, userProfile } = useSelector((state) => state.auth);

  const [actionTab, setActionTab] = useState("Notes");
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [note, setNote] = useState("");
  const [activities, setActivities] = useState([]);
  const [lead, setLead] = useState(false);
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  const [itemToPreview, setItemToPreview] = useState(null);
  const [actionTitle, setActionTitle] = useState("");
  const [actionDescription, setActionDescription] = useState("");
  const [actionDate, setActionDate] = useState(dayjs());
  const [files, setFiles] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [showUpdatePalDrawer, setShowUpdatePalDrawer] = useState(false);
  const [openChooseOrganization, setOpenChooseOrganization] = useState(false);
  const [chosenProject, setChosenProject] = useState(null);
  const [chosenOrganization, setChosenOrganization] = useState("");
  const [selectedProjectBlock, setSelectedProjectBlock] = useState(
    issue?.projectBlock || null
  );
  const [selectedProjectUnit, setSelectedProjectUnit] = useState(
    issue?.unit || null
  );
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [actionDateMode, setActionDateMode] = useState("1 day");
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusOption, setStatusOption] = useState("");
  const [activityFilter, setActivityFilter] = useState("All");
  const [mainProfileWithParent, setMainProfileWithParent] = useState(null);
  const [profileIdArr, setProfileIdArr] = useState([]);
  const [showCreateBookingModel, setShowCreateBookingModel] = useState(false);
  const [showCreateEstimateModal, setShowCreateEstimateModal] = useState(false);
  const [alertObj, setAlertObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const saveAction = async (createData = null) => {
    try {
      let locationObj = null;
      if (chosenProject) {
        locationObj = {
          name: chosenProject.name,
        };
      } else if (selectedPlace) {
        const {
          address_line1,
          address_line2,
          name,
          city,
          country,
          country_code,
          lat,
          lon,
          state,
          state_code,
          postcode,
        } = selectedPlace;

        locationObj = {
          address_line1,
          address_line2,
          name,
          city,
          country,
          country_code,
          loc: {
            type: "Point",
            coordinates: [lat, lon],
          },
          state,
          state_code,
          postcode,
        };
      }

      const payload = {
        issueId: issue?._id,
        type:
          actionTab === "Notes"
            ? "Note"
            : actionTab === "Documents"
            ? "Doc"
            : "Action",
        userProfileId: user?.profile,
        templateId: issue?.template?._id,
        tags: issue.tags,
        profileId: issue?.profile?._id,
        projectId: chosenProject?._id,
        issueTemplateId: issue?.template?._id,
      };

      switch (actionTab) {
        case "Notes":
          payload.note = note;
          break;
        case "Documents":
          payload.fileIds = createdFileIds;
          break;
        default:
          // Action type
          payload.managers = createData.managers || [];
          payload.newEventData = {
            ...createData,
            parent: issue?._id,
            parentModelName: "Issue",
            tags: [
              {
                data: issue?._id,
                dataModel: "Issue",
              },
            ],
          };
          payload.actionDateMode = actionDateMode;
          break;
      }

      const { data } = await Api.post("/issue/activity/create", payload);

      if (data) {
        switch (actionTab) {
          case "Notes":
            setNote("");
            break;
          case "Documents":
            dispatch({ type: "FileUploadReset" });
            break;
          default:
            setSelectedPlace(null);
            setActionDate(dayjs());
            break;
        }
        setActivities([...activities, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: `${
              actionTab === "Notes"
                ? "Note"
                : actionTab === "Documents"
                ? "Document"
                : "Action"
            } added successfully`,
          },
        });
        setActionTab("Notes");
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteDocByFile = async (fileId) => {
    try {
      const { data } = await Api.post("/issue/activity/update", {
        issueActivityId: itemToPreview?._id,
        fileId: fileId,
        type: "Doc",
      });
      if (data) {
        setActivities(
          activities.map((item) => (item?._id === data?._id ? data : item)) ||
            []
        );
        setItemToPreview();
        setShowPreviewDrawer(false);

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Docs updated successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getActivities = async (filterType, page, limit) => {
    try {
      setLoadingActivities(true);
      const response = await Api.post("/issue/activity/get", {
        issueId: issue?._id,
        type: filterType,
        page: page,
        limit: limit,
      });
      if (response.data && response.data) {
        setActivities(response.data);
        setTotalPages(response.totalPages);
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoadingActivities(false);
    }
  };

  const connectLeadWithIssue = async (lead) => {
    try {
      setIsLoading(true);
      const { data } = await Api.post("/issue/activity/connect-lead", {
        issueId: issue?._id,
        finrelId: lead?.finrel?._id || lead?.finrel,
      });
      if (data) {
        setLead(data?.finrel);
        setIssue({
          ...issue,
          finrel: data?.finrel,
          source: data.source,
          sourceNote: data.sourceNote,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isBookingEnabled = Boolean(
    issue?.project?.profile &&
      issue?.finrel?.profile?._id &&
      issue?.template?.successStates &&
      issue?.template?.successStates.length > 0 &&
      issue?.status?._id &&
      issue.template.successStates.includes(issue?.status?._id)
  );

  const isEstimateEnabled = Boolean(
    issue?.project?.profile && issue?.finrel?.profile?._id
  );

  useEffect(() => {
    getActivities();
  }, []);

  useEffect(() => {
    setLead(issue?.finrel);
  }, [issue]);

  useEffect(() => {
    switch (actionDateMode) {
      case "1 day":
        setActionDate(dayjs().add(1, "day").hour(9).minute(0));
        break;
      case "1 week":
        setActionDate(dayjs().add(1, "week").hour(9).minute(0));
        break;
      case "15 days":
        setActionDate(dayjs().add(15, "day").hour(9).minute(0));
        break;
      case "1 months":
        setActionDate(dayjs().add(1, "month").hour(9).minute(0));
        break;
      case "3 months":
        setActionDate(dayjs().add(3, "month").hour(9).minute(0));
        break;
      case "6 months":
        setActionDate(dayjs().add(6, "month").hour(9).minute(0));
        break;
      case "9 months":
        setActionDate(dayjs().add(9, "month").hour(9).minute(0));
        break;
      case "1 year":
        setActionDate(dayjs().add(1, "year").hour(9).minute(0));
        break;

      default:
        break;
    }
  }, [actionDateMode]);

  const handlePreview = (item) => {
    setItemToPreview(item);
    setShowPreviewDrawer(true);
    switch (item?.type) {
      case "Note":
        setNote(item?.note);
        break;

      case "Action":
        setActionTitle(item?.calendarEvent?.title);
        setActionDescription(item?.calendarEvent?.description);
        setActionDate(dayjs(item?.calendarEvent?.startDate));
        setActionDateMode(item?.actionDateMode || "1 day");
        break;

      case "Doc":
        let docs = item?.docs;
        let _files = [];
        for (let i = 0; i < docs.length; i++) {
          let doc = docs[i];
          _files = [..._files, ...doc.files];
        }
        setFiles(_files);
        break;

      default:
        break;
    }
  };

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  useEffect(() => {
    const projectProfileId =
      issue?.project?.profile?._id || issue?.project?.profile;
    const orgProfileId =
      issue?.organization?.profile?._id || issue?.organization?.profile;
    const userProfileId = user?.profile;
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [
    issue?.organization?.profile?._id,
    issue?.organization?.profile,
    user?.profile,
    issue?.project?.profile?._id,
    issue?.project?.profile,
  ]);

  const { AssignButton, AssigneDrawer, AssignView } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: issue?._id,
    resourceName: "Issue",
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  useEffect(() => {
    let template = issue?.template;
    if (template) {
      setStatusOptions(template.pipeline);
    }
  }, [issue]);

  useEffect(() => {
    setStatusOption(issue?.status?._id);
  }, [statusOptions]);

  useEffect(() => {
    if (issue) {
      setMainProfileWithParent(issue?.profile);
      setProfileIdArr([issue?.profile?._id]);
    }
  }, [issue]);

  useEffect(() => {
    setChosenProject(issue?.project);
    setChosenOrganization(issue?.organization);
  }, [issue]);

  const updateIssueApi = async ({ status, project }) => {
    try {
      setIsLoading(true);
      let issueObject = {
        _id: issue?._id,
        project: project || chosenProject?._id,
        organization: chosenOrganization?._id,
        projectBlock: selectedProjectBlock?._id,
        dealValue: issue.dealValue,
        status: status || statusOption,
      };

      if (selectedProjectUnit?._id && selectedProjectUnit?._id !== "") {
        issueObject.unit = selectedProjectUnit?._id;
      }

      const res = await Api.post("issue/update", issueObject);
      await getIssueCall();
      if (!res) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
        return;
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Ticket updated successfully",
          },
        });
      }
      dispatch({ type: "FileUploadReset" });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateBookingIdForIssueApi = async (newBookingId) => {
    let issueObject = {
      _id: issue?._id,
      linkedBooking: newBookingId,
    };

    const res = await Api.post("issue/update", issueObject);
    await getIssueCall();
    if (!res) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
      return;
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ticket updated successfully",
        },
      });
    }
    dispatch({ type: "FileUploadReset" });
  };

  const updateEstimateIdForIssueApi = async (newEstimateId) => {
    let issueObject = {
      _id: issue?._id,
      linkedEstimate: newEstimateId,
    };

    const res = await Api.post("issue/update", issueObject);
    await getIssueCall();
    if (!res) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
      return;
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ticket updated successfully",
        },
      });
    }
    dispatch({ type: "FileUploadReset" });
  };

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);
    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });
    await updateDeleteFlagForSingleFiles({ fileId: selectedId })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActivityFilterChange = (event) => {
    setActivityFilter(event.target.value);
    setCurrentPage(1);
  };

  const deleteIssue = async () => {
    try {
      await deleteIssueById({
        issueId: issue?._id,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Issue deleted successfully",
        },
      });
      history.push(
        `/issue/template/${issue?.template?._id}/view/${issue?.profile?._id}`
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={`${issue?.template?.title}`}
      appBarActions={
        <HorizBox spacing={2}>
          {isLoading && (
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                bottom: -1,
                left: 0,
              }}
            >
              <LinearProgress />
            </Box>
          )}
          <Box sx={{ minWidth: 200, position: "relative" }}>
            <Select
              value={statusOption}
              onChange={(e) => {
                setStatusOption(e.target.value);
                // if (
                //   issue?.template?.successStates &&
                //   issue?.template?.successStates.length > 0 &&
                //   issue.template.successStates.includes(e.target.value) &&
                //   !issue?.linkedBooking?._id
                // ) {
                //   setAlertObj({
                //     show: true,
                //     message:
                //       "Booking can be created once the issue is saved with the correct status!",
                //     status: "success",
                //   });
                // }
                updateIssueApi({ status: e.target.value });
              }}
              size="small"
              fullWidth={true}
              disabled={isLoading}
              sx={{
                "& .MuiSelect-select": {
                  padding: "8px 14px",
                  borderRadius: "8px",
                },
              }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option?._id} value={option?._id}>
                  {option?.text}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <NotifyAlert alertObj={alertObj} setAlertObj={setAlertObj} />
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: 500,
            }}
            onClick={() => updateIssueApi()}
          >
            Save
          </Button>
          <MyPopOver
            closeOnClick={true}
            appearContent={
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            }
            showContent={
              <div>
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteIssue();
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    Delete Issue
                  </MenuItem>
                </MenuList>
              </div>
            }
          />
        </HorizBox>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <StandardAppContainerRounded
            sx={{ mb: 2 }}
            style={{ padding: "12px" }}
          >
            {lead?._id ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    pb: 2,
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <SingleUserWithCaptionTableCell
                    label={lead?.profile?.parent?.displayName}
                    caption={`${
                      lead?.profile?.parent?.email?.trim() || "Email"
                    } ${lead?.profile?.parent?.phone?.trim() || "Phone"}`}
                    url={lead?.profile?.parent?.displayPicture?.url}
                    labelStyleProps={{
                      fontSize: "17px",
                      fontWeight: 600,
                      color: "black",
                    }}
                    captionStyleProps={{
                      fontSize: "0.75rem",
                      color: "#666",
                    }}
                    avatarSx={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <Box style={{ width: "90px" }}>
                    <IconButton
                      color="primary"
                      onClick={() => setShowUpdatePalDrawer(true)}
                      sx={{ padding: "4px" }}
                    >
                      <EditOutlined fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => connectLeadWithIssue({})}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ffebee",
                        },
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ "& > div": { mb: 2.5 } }}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: "15px",
                        mb: 1,
                        color: "#2C3E50",
                      }}
                    >
                      Deal Value (₹)
                    </Typography>
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator={true}
                      prefix="₹"
                      value={issue?.dealValue || ""}
                      onValueChange={(values) => {
                        const { value } = values;
                        setIssue({
                          ...issue,
                          dealValue: value,
                        });
                      }}
                      fullWidth
                      size="small"
                      sx={{
                        backgroundColor: "#F8F9FA",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "6px",
                          height: "36px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "8px 12px",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: "15px",
                        mb: 1,
                        color: "#2C3E50",
                      }}
                    >
                      Source
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#34495E",
                        backgroundColor: "#F8F9FA",
                        padding: "8px 12px",
                        borderRadius: "6px",
                        fontSize: "14px",
                      }}
                    >
                      {issue?.source || "No source specified"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: "15px",
                        mb: 1,
                        color: "#2C3E50",
                      }}
                    >
                      Source Notes
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "#F8F9FA",
                        padding: "12px",
                        borderRadius: "6px",
                        maxHeight: "150px",
                        overflowY: "auto",
                      }}
                    >
                      {issue?.sourceNote ? (
                        issue.sourceNote.split("\n").map((note, index) => (
                          <Typography
                            key={index}
                            variant="body2"
                            sx={{
                              color: "#34495E",
                              fontSize: "14px",
                              mb: note.length > 0 ? 1 : 0,
                              lineHeight: 1.5,
                            }}
                          >
                            {note}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#95A5A6",
                            fontSize: "14px",
                            fontStyle: "italic",
                          }}
                        >
                          No source notes available
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        fontSize: "15px",
                        mb: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Listing
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {lead?.listing?.title || "Untitled Listing"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", py: 3 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  This ticket is not linked to any lead
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowLeadsDrawer(true)}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                  }}
                >
                  Link a Lead
                </Button>
              </Box>
            )}
          </StandardAppContainerRounded>

          <StandardAppContainerRounded sx={{ mb: 2 }}>
            <FormBox label="Project">
              <ProjectPicker
                defaultProjectId={chosenProject?._id}
                selectedProject={chosenProject}
                setSelectedProject={setChosenProject}
                updateProjectFn={(id) => updateIssueApi({ project: id })}
                fullWidth={true}
              />
            </FormBox>

            {chosenProject && (
              <div>
                <ProjectBlockAndUnitPickerDropdown
                  projectId={chosenProject?._id}
                  selectedProjectBlock={selectedProjectBlock}
                  setSelectedProjectBlock={setSelectedProjectBlock}
                  selectedProjectUnit={selectedProjectUnit}
                  setSelectedProjectUnit={setSelectedProjectUnit}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                  isRentalBlocks={true}
                />
              </div>
            )}
          </StandardAppContainerRounded>

          {lead?._id && (
            <StandardAppContainerRounded sx={{ mb: 2 }}>
              <FormBox label="Assign">
                {AssignButton}
                {AssigneDrawer}
                {AssignView}
              </FormBox>
            </StandardAppContainerRounded>
          )}
        </Grid>

        <Grid item xs={9}>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={1}>
              {actionTabOptions.map((tab) => {
                const Icon = tab.icon;
                return (
                  <Grid item key={tab.title}>
                    <Button
                      variant={
                        actionTab === tab.title ? "contained" : "outlined"
                      }
                      onClick={() => setActionTab(tab.title)}
                      startIcon={<Icon />}
                      sx={{
                        textTransform: "none",
                        borderRadius: "8px",
                        backgroundColor:
                          actionTab === tab.title ? undefined : "#fff",
                      }}
                    >
                      {tab.title}
                    </Button>
                  </Grid>
                );
              })}
              {issue?.type === "Construction_Linked_Unit_Sale" && (
                <>
                  <Grid item key={"Estimate"}>
                    <Button
                      variant={
                        actionTab === "Estimate" ? "contained" : "outlined"
                      }
                      onClick={() => setActionTab("Estimate")}
                      startIcon={<RequestQuoteIcon />}
                      sx={{
                        textTransform: "none",
                        borderRadius: "8px",
                        backgroundColor:
                          actionTab === "Estimate" ? undefined : "#fff",
                      }}
                    >
                      Estimate
                    </Button>
                  </Grid>
                  <Grid item key={"Booking"}>
                    <Button
                      variant={
                        actionTab === "Booking" ? "contained" : "outlined"
                      }
                      onClick={() => setActionTab("Booking")}
                      startIcon={<TbBrandBooking />}
                      sx={{
                        textTransform: "none",
                        borderRadius: "8px",
                        backgroundColor:
                          actionTab === "Booking" ? undefined : "#fff",
                      }}
                    >
                      Booking
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Box sx={{ mb: 3 }}>
            {actionTab === "Notes" && (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  border: "1px solid #E0E0E0",
                  p: 2,
                }}
              >
                <InputBase
                  placeholder="Add a note..."
                  fullWidth
                  multiline
                  rows={3}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  sx={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "8px",
                    padding: "12px 16px",
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: "1.5",
                    },
                  }}
                />
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    disabled={!note.trim()}
                    onClick={saveAction}
                    sx={{
                      textTransform: "none",
                      borderRadius: "8px",
                    }}
                  >
                    Add Note
                  </Button>
                </Box>
              </Box>
            )}

            {actionTab === "Actions" && (
              <Paper style={{ padding: "15px" }}>
                <EventAddEdit
                  defaultProjectId={issue?.project?._id}
                  profileId={issue?.profile?._id}
                  defaultType={"Site Visit"}
                  saveAction={saveAction}
                  defaultRequiredAttendees={[
                    {
                      data: lead?.profile,
                      label: lead?.profile?.parent?.displayName,
                      value: lead?.profile?._id,
                    },
                  ]}
                  palUserName={lead?.profile?.parent?.displayName || ""}
                  defaultTitle={
                    lead?.profile?.parent?.displayName
                      ? `${lead?.profile?.parent?.displayName
                          .trim()
                          .replace(" ", "_")}-Site_Visit-`
                      : "Site_Visit-"
                  }
                />
              </Paper>
            )}

            {actionTab === "Documents" && (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  border: "1px solid #E0E0E0",
                  p: 2,
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <CustomFileUploadButton
                    showComponent={
                      <Button
                        variant="outlined"
                        startIcon={<Add />}
                        sx={{
                          textTransform: "none",
                          borderRadius: "8px",
                        }}
                      >
                        Upload Files
                      </Button>
                    }
                    parentType={"Issue"}
                    parentId={issue?._id}
                    fileNum={25}
                    givenMaxSize={26214400}
                    closeFunCall={() => console.log("close dialog")}
                  />
                </Box>

                <Grid item xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                  >
                    <Button
                      variant="contained"
                      onClick={saveAction}
                      sx={{
                        textTransform: "none",
                        borderRadius: "8px",
                      }}
                    >
                      Add Action
                    </Button>
                  </Box>
                </Grid>

                {createdFileIds && createdFileIds.length > 0 && (
                  <FilesViewer
                    fileIds={createdFileIds}
                    deletable={true}
                    styleBody={{
                      width: "100%",
                      height: "auto",
                    }}
                    handler={removeSingleImgFromReducerAndDelete}
                  />
                )}
              </Box>
            )}
            {actionTab === "Estimate" &&
              issue?.type === "Construction_Linked_Unit_Sale" && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    border: "1px solid #E0E0E0",
                    p: 2,
                  }}
                >
                  {!isEstimateEnabled ? (
                    <div className={classes.emptyCont}>
                      <TbStatusChange />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {issue?.project?.profile ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#4CAF50",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{
                                color: "gray",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          )}
                          Select Project & Unit
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {issue?.finrel?.profile?._id ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#4CAF50",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{
                                color: "gray",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          )}
                          Select Lead
                        </Typography>
                      </Box>
                    </div>
                  ) : issue?.linkedEstimate?._id ? (
                    <div style={{ maxWidth: "350px" }}>
                      <EstimateCard estimate={issue?.linkedEstimate} />
                    </div>
                  ) : (
                    <div className={classes.bookingCreateBtnCont}>
                      <Button
                        variant="contained"
                        style={{ width: "200px" }}
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setShowCreateEstimateModal(true);
                        }}
                      >
                        Create Estimate
                      </Button>
                      <CreateEstimateModal
                        libraryId={issue?.project?.wallet?.defaultLibrary}
                        open={showCreateEstimateModal}
                        setOpen={setShowCreateEstimateModal}
                        isNeedToRedirect={false}
                        onClose={(newEstimateId) => {
                          updateEstimateIdForIssueApi(newEstimateId);
                          setShowCreateEstimateModal(false);
                        }}
                        profileId={issue?.project?.profile}
                        defaultBlock={selectedProjectBlock}
                        defaultUnit={selectedProjectUnit}
                        customerProfileId={issue?.finrel?.profile?._id}
                        tags={[
                          {
                            data: issue?._id,
                            dataModel: "Issue",
                          },
                        ]}
                      />
                    </div>
                  )}
                </Box>
              )}
            {actionTab === "Booking" &&
              issue?.type === "Construction_Linked_Unit_Sale" && (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    border: "1px solid #E0E0E0",
                    p: 2,
                  }}
                >
                  {!isBookingEnabled ? (
                    <div className={classes.emptyCont}>
                      <TbStatusChange />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {issue?.project?.profile ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#4CAF50",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{
                                color: "gray",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          )}
                          Select Project & Unit
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {issue?.finrel?.profile?._id ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#4CAF50",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{
                                color: "gray",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          )}
                          Select Lead
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {issue?.template?.successStates &&
                          issue?.template?.successStates.length > 0 &&
                          issue?.status?._id &&
                          issue.template.successStates.includes(
                            issue?.status?._id
                          ) ? (
                            <CheckCircleIcon
                              sx={{
                                color: "#4CAF50",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{
                                color: "gray",
                                height: "24px",
                                width: "24px",
                              }}
                            />
                          )}
                          Set Status to Success Stage
                        </Typography>
                      </Box>
                    </div>
                  ) : issue?.linkedBooking?._id ? (
                    <div style={{ maxWidth: "350px" }}>
                      <BookingCard booking={issue?.linkedBooking} />
                    </div>
                  ) : (
                    <div className={classes.bookingCreateBtnCont}>
                      <Button
                        variant="contained"
                        style={{ width: "200px" }}
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setShowCreateBookingModel(true);
                        }}
                      >
                        Create Booking
                      </Button>
                      <CreateBookingModel
                        libraryId={issue?.project?.wallet?.defaultLibrary}
                        open={showCreateBookingModel}
                        setOpen={setShowCreateBookingModel}
                        isNeedToRedirect={false}
                        onClose={(newBookingId) => {
                          updateBookingIdForIssueApi(newBookingId);
                          setShowCreateBookingModel(false);
                        }}
                        profileId={issue?.project?.profile}
                        defaultBlock={selectedProjectBlock}
                        defaultUnit={selectedProjectUnit}
                        customerObj={{
                          userId: user?._id,
                          profileId: issue?.finrel?.profile?._id,
                          parentModelName: issue?.finrel?.parentModelName,
                          parent: issue?.finrel?.parent?._id,
                        }}
                        isRentalBlocks={true}
                        tags={[
                          {
                            data: issue?._id,
                            dataModel: "Issue",
                          },
                        ]}
                      />
                    </div>
                  )}
                </Box>
              )}
          </Box>
          <StandardAppContainerRounded>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                  Activity Timeline
                </Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}
                >
                  <Select
                    value={activityFilter}
                    onChange={handleActivityFilterChange}
                    size="small"
                    sx={{ minWidth: 150 }}
                  >
                    <MenuItem key="All" value="All">
                      All{" "}
                    </MenuItem>
                    <MenuItem key="Note" value="Note">
                      Notes
                    </MenuItem>
                    <MenuItem key="Action" value="Action">
                      Actions
                    </MenuItem>
                    <MenuItem key="Doc" value="Doc">
                      Documents
                    </MenuItem>
                    <MenuItem key="SMS" value="SMS">
                      SMS
                    </MenuItem>
                    <MenuItem key="Call" value="Call">
                      Calls
                    </MenuItem>
                  </Select>
                </Box>
              </Box>

              {loadingActivities ? (
                <Box sx={{ textAlign: "center", py: 4 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {activities.map((item) => {
                    const isChatbotActivity =
                      item?.sourceParent === "ChatbotSettings";

                    return (
                      <Box
                        key={item?._id}
                        onClick={() => handlePreview(item)}
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          border: "1px solid #E0E0E0",
                          p: 2,
                          cursor: "pointer",
                          transition: "all 0.2s ease",
                          "&:hover": {
                            boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
                            borderColor: "#BDBDBD",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {isChatbotActivity ? (
                              <SmartToyIcon />
                            ) : (
                              <Avatar
                                src={
                                  item?.userProfile?.parent?.displayPicture?.url
                                }
                                sx={{ width: 32, height: 32 }}
                              />
                            )}
                            <Box>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 600 }}
                              >
                                {isChatbotActivity
                                  ? "Chatbot"
                                  : item?.userProfile?.parent?.displayName ||
                                    "Untitled"}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {dayjs(item?.createdAt).format(
                                  "MMM D, YYYY • h:mm A"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {item.type === "Action" && (
                              <Chip
                                label={item?.calendarEvent?.type}
                                size="small"
                                sx={{
                                  backgroundColor: "#E8F5E9",
                                  color: "#2E7D32",
                                }}
                              />
                            )}
                            <Chip
                              icon={
                                item?.type === "Doc" ? (
                                  <FilePresentOutlined />
                                ) : item?.type === "Note" ? (
                                  <NoteOutlined />
                                ) : item?.type === "Action" ? (
                                  <AlarmOnOutlined />
                                ) : item?.type === "SMS" ? (
                                  <MessageOutlined />
                                ) : (
                                  <PhoneOutlined />
                                )
                              }
                              label={item?.type}
                              size="small"
                              sx={{
                                backgroundColor: "#E3F2FD",
                                color: "#1976D2",
                                "& .MuiChip-icon": { color: "#1976D2" },
                              }}
                            />
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box>
                          {item?.type === "Note" && (
                            <Typography
                              variant="body2"
                              sx={{ color: "#424242" }}
                            >
                              {item?.note || "No content available"}
                            </Typography>
                          )}

                          {item?.type === "Doc" && (
                            <Box>
                              {item.docs.map((doc) =>
                                doc.files.map((file) =>
                                  file?._id ? (
                                    <Box key={file._id} sx={{ mb: 1 }}>
                                      {file?.type?.startsWith("image/") ? (
                                        <img
                                          src={file.url}
                                          alt={file.name}
                                          style={{
                                            maxWidth: "100%",
                                            borderRadius: "8px",
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          startIcon={<FilePresentOutlined />}
                                          href={file.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {file.name}
                                        </Button>
                                      )}
                                    </Box>
                                  ) : null
                                )
                              )}
                            </Box>
                          )}

                          {item?.type === "Action" && (
                            <Box>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 600, mb: 1 }}
                              >
                                {item.actionType}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "#424242" }}
                              >
                                {item.calendarEvent?.description ||
                                  "No description available"}
                              </Typography>
                            </Box>
                          )}

                          {item?.type === "SMS" && (
                            <Typography
                              variant="body2"
                              sx={{ color: "#424242" }}
                            >
                              {item?.message || "No SMS content available"}
                            </Typography>
                          )}

                          {item?.type === "Call" && (
                            <Typography
                              variant="body2"
                              sx={{ color: "#424242" }}
                            >
                              {item?.callSummary || "No call summary available"}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </StandardAppContainerRounded>
        </Grid>
      </Grid>

      <DrawerContainer
        open={showPreviewDrawer}
        setOpen={setShowPreviewDrawer}
        title={itemToPreview?.type}
        onClose={() => {
          setItemToPreview(null);
          setNote("");
          setActionTitle("");
          setActionDescription("");
          setActionDate(dayjs());
        }}
      >
        <Box>
          {itemToPreview?.type === "Note" && (
            <Box>
              <TextField
                placeholder="Enter"
                fullWidth={true}
                multiline={true}
                rows={4}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <DuoButtonGroup
                primaryButtonText="Update"
                primaryButtonListener={() => {}}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => {
                  setShowPreviewDrawer(false);
                }}
              />
            </Box>
          )}
          {itemToPreview?.type === "Action" && (
            <Box>
              <FormBox label="Action Name">
                <TextField
                  placeholder="Enter"
                  fullWidth={true}
                  value={actionTitle}
                  onChange={(e) => setActionTitle(e.target.value)}
                />
              </FormBox>
              <FormBox label="Action Description">
                <TextField
                  placeholder="Enter"
                  fullWidth={true}
                  multiline={true}
                  rows={4}
                  value={actionDescription}
                  onChange={(e) => setActionDescription(e.target.value)}
                />
              </FormBox>
              <FormBox label="Action Date">
                <Select
                  value={actionDateMode}
                  onChange={(e) => setActionDateMode(e.target.value)}
                  fullWidth={true}
                >
                  {TIME_MODES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
              {actionDateMode === "Custom" && (
                <Box>
                  <FormBox label="Action Date">
                    <DatePicker
                      value={actionDate}
                      onChange={(val) => setActionDate(val)}
                    />
                  </FormBox>
                  <FormBox label="Action Time">
                    <TimePicker
                      value={actionDate}
                      onChange={(val) => {
                        setActionDate(val);
                      }}
                      fullWidth={true}
                    />
                  </FormBox>
                </Box>
              )}

              <DuoButtonGroup
                primaryButtonText="Update"
                primaryButtonListener={() => {}}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => {
                  setShowPreviewDrawer(false);
                }}
              />
            </Box>
          )}
          {itemToPreview?.type === "Doc" && (
            <Box>
              <FilesViewer
                files={files}
                isDeletable={true}
                deleteFunction={async (fileId) => {
                  deleteDocByFile(fileId);
                }}
                disablePreview={false}
                disableShowMetaData={true}
                disableEditMetaData={true}
              />
            </Box>
          )}
        </Box>
      </DrawerContainer>

      <SearchEntityDialog
        entity={issue?.profile?.parentModelName}
        multiple={false}
        orgtype={"User"}
        curEntityId={issue?.profile?.parent?._id}
        givenFilterOption={[
          {
            option: "Contact",
            types: ["Prospect", "Other"],
          },
        ]}
        onSave={(entity) => {
          connectLeadWithIssue(entity);
          setShowLeadsDrawer(false);
        }}
        givenProfileIdArr={[issue?.profile?._id, user?.profile]}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Lead"
        financialRelationType="Prospect"
      />

      <CreatePalDrawer
        text=""
        title={"Update Lead"}
        openPal={showUpdatePalDrawer}
        setOpenPal={setShowUpdatePalDrawer}
        financialRelationType={"Prospect"}
        contactType={"Prospect"}
        pal={lead}
        actionType={"Update"}
        mainProfileWitParent={mainProfileWithParent}
        profileIdArr={profileIdArr}
        selectEntityFunction={(newLead) => {
          connectLeadWithIssue(newLead);
        }}
        fRelationId={issue?.finrel?._id}
      />

      <Choose
        open={openChooseOrganization}
        setOpen={setOpenChooseOrganization}
        parentModelName="Organization"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Find Organization`}
        onSelected={(arr) => {
          if (arr && arr.length > 0) {
            setChosenOrganization(arr[0]);
            let updatedIssue = issue;
            updatedIssue.organization = arr[0];
            setIssue(updatedIssue);
          }
        }}
      />
    </StandardContainer>
  );
};

export default ProfileIssueCRM;
