import React, { useEffect, useState } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ProjectPicker from "./ProjectPicker";
import FormBox from "../../styled/generic/FormBox";
import { useHistory } from "react-router-dom";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import ProjectBlockAndUnitPickerDropdown from "../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  createBookingRecord,
  getPaymentTableByLibrary,
  getScheduleByPaymentTable,
} from "./api";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainCreateCont: {
    width: "700px",
  },
  bodyCont: {
    width: "100%",
    minHeight: "250px",
    overflowY: "auto",
  },
  bottomSty: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const steps = [
  "Project and Units",
  "Payment Details",
  "Payment Tables",
  "Additional Details",
];

const CreateBookingModel = ({
  open,
  onClose,
  libraryId,
  profileId,
  setOpen,
  defaultBlock,
  defaultUnit,
  isNeedToRedirect = false,
  customerObj = null,
  isRentalBlocks = null,
  bookingTypes = [],
  tags = [],
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);

  const [showProjects, setShowProjects] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [paymentSchedule, setPaymentSchedule] = useState(null);
  const [paymentTables, setPaymentTables] = useState([]);
  const [bookingData, setBookingData] = useState({
    project: null,
    projectBlock: null,
    unit: null,
    area: "",
    rsf: "",
    paymentTable: "",
    paymentSchedule: "",
    project: "",
    organization: "",
    saleableArea: "",
    bookingType: null,
    bookingDate: null,
  });

  //#region Data Get And Set In Form
  //to add default project, organization, block & unit
  const fetchProfile = async () => {
    setLoading(true);
    await getProfileDataPopulate({
      profileId: profileId,
    })
      .then((data) => {
        let bookingObj = {
          ...bookingData,
          projectBlock: defaultBlock,
          unit: defaultUnit,
        };
        if (data?.parentModelName === "Project") {
          setShowProjects(false);
          bookingObj.project = data?.parent;
          if (data?.ownerProfile?.parentModelName === "Organization") {
            bookingObj.organization = data?.ownerProfile?.parent;
          }
        } else {
          setShowProjects(true);
          bookingObj.organization = data?.parent;
        }
        setBookingData(bookingObj);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to fetch profile",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //to fetch profile data
  useEffect(() => {
    if (profileId && open) {
      fetchProfile();
    }
  }, [profileId, open]);

  //reset booking data
  useEffect(() => {
    if (open) {
      setBookingData({
        project: null,
        projectBlock: null,
        unit: null,
        area: "",
        rsf: "",
        paymentTable: "",
        paymentSchedule: "",
        project: null,
        organization: null,
        projectBlock: null,
        saleableArea: "",
        bookingType: null,
      });
      setActiveStep(0);
    }
  }, [open]);

  //get call of schedules against payment table
  const getSchedules = async () => {
    setLoading(true);
    getScheduleByPaymentTable({
      libraryId: libraryId,
      tableId: bookingData?.paymentTable?._id,
    })
      .then((data) => {
        setPaymentSchedule(data.filter((item) => item?.title));
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //get call of payment tables
  const getPaymentTables = () => {
    setLoading(true);
    getPaymentTableByLibrary({ libraryId: libraryId })
      .then((data) => {
        setPaymentTables(data.filter((item) => item.title));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //get schedule on payment table change
  useEffect(() => {
    if (bookingData?.paymentTable?._id && open) {
      getSchedules();
    }
  }, [bookingData?.paymentTable?._id, open]);

  // get payment table on dialog open
  useEffect(() => {
    getPaymentTables();
  }, [open, libraryId]);
  //#endregion

  //#region To Hanlde On Change Event
  //update field of the bookingData useState onchange event
  const handleUpdateBookingData = (newData) => {
    setBookingData((prevData) => ({ ...prevData, ...newData }));
  };

  useEffect(() => {
    if (bookingData?.unit?._id) {
      setBookingData({
        ...bookingData,
        rsf: bookingData?.unit?.ratePerSqFt || 0,
        saleableArea: bookingData?.unit?.saleableArea || 0,
      });
    }
  }, [bookingData.unit]);
  //#endregion

  //#region To Hanlde On Form Sumit
  //after click submit button
  const handleSubmit = async () => {
    setLoading(true);
    createBookingRecord({
      bookingData: {
        ...bookingData,
        paymentTable: bookingData.paymentTable?._id,
        schedule: bookingData.paymentSchedule?._id,
        project: bookingData.project?._id,
        projectBlock: bookingData.projectBlock?._id,
        unit: bookingData.unit?._id,
        organization: bookingData.organization?._id,
      },
      customerObj: customerObj,
      libraryId: libraryId,
      profileId: profileId,
      userProfileId: user?.profile,
      tags: tags,
    })
      .then((data) => {
        console.log(data);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Booking created successfully",
          },
        });
        if (isNeedToRedirect) {
          history.push("/booking/edit/" + data._id);
        }
        onClose(data?.data?._id);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Something went wrong while creating booking",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //#endregion

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => {
        setOpen(false);
      }}
      pageTitle={"Create New Booking"}
      content={
        <div className={classes.mainCreateCont}>
          <div style={{ width: "100%", height: "5px" }}>
            {loading && <LinearProgress />}
          </div>
          <div className={classes.bodyCont}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 3 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider
              sx={{
                mb: 3,
              }}
            />
            {activeStep === 0 ? (
              <Grid container spacing={2} gridColumn={12}>
                {showProjects && (
                  <Grid item xs={6}>
                    <FormBox label={"Project"}>
                      <ProjectPicker
                        fullWidth={true}
                        size="sm"
                        selectedOrg={
                          showProjects ? bookingData?.organization?._id : false
                        }
                        project={bookingData.project}
                        setProject={(project) =>
                          handleUpdateBookingData({ project: project })
                        }
                      />
                    </FormBox>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ProjectBlockAndUnitPickerDropdown
                    projectId={bookingData?.project}
                    hideLabel={true}
                    fullWidth={true}
                    selectedProjectBlock={bookingData?.projectBlock}
                    setSelectedProjectBlock={(data) => {
                      setBookingData({
                        ...bookingData,
                        projectBlock: data,
                        rsf: parseFloat(
                          bookingData.projectBlock?.baseRatePerSqft || 0
                        ),
                        unit: null,
                        area: 0,
                        saleableArea: 0,
                      });
                    }}
                    selectedProjectUnit={bookingData?.unit}
                    setSelectedProjectUnit={(data) => {
                      setBookingData({
                        ...bookingData,
                        unit: data,
                        area: parseFloat(data?.area || 0),
                        saleableArea: parseFloat(data?.saleableArea || 0),
                      });
                    }}
                    isRentalBlocks={isRentalBlocks}
                  />
                </Grid>
              </Grid>
            ) : activeStep === 1 ? (
              <Grid container spacing={2} gridColumn={12}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Total Area (sqft)"
                    type="number"
                    size="small"
                    value={bookingData.area}
                    onChange={(e) =>
                      handleUpdateBookingData({ area: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Rate per Sq Ft"
                    type="number"
                    size="small"
                    value={bookingData.rsf}
                    onChange={(e) =>
                      handleUpdateBookingData({ rsf: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Saleable Area (sqft)"
                    type="number"
                    size="small"
                    value={bookingData.saleableArea}
                    onChange={(e) =>
                      handleUpdateBookingData({
                        saleableArea: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            ) : activeStep === 2 ? (
              <Grid container spacing={2} gridColumn={12}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Table</InputLabel>
                    <Select
                      label="Payment Table"
                      value={bookingData?.paymentTable?._id}
                      onChange={(e) => {
                        const paymentTable = paymentTables?.find(
                          (item) => item?._id === e.target.value
                        );
                        handleUpdateBookingData({ paymentTable: paymentTable });
                      }}
                    >
                      {paymentTables.map((item) => {
                        return (
                          <MenuItem key={item} value={item._id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Schedule</InputLabel>
                    <Select
                      label="Payment Schedule"
                      value={bookingData.paymentSchedule}
                      onChange={(e) =>
                        handleUpdateBookingData({
                          paymentSchedule: e.target.value,
                        })
                      }
                    >
                      {paymentSchedule?.map((item) => {
                        return (
                          <MenuItem key={item._id} value={item._id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} gridColumn={12}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Booking Date"
                      value={bookingData.bookingDate}
                      onChange={(date) => {
                        handleUpdateBookingData({ bookingDate: date });
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          sx: {
                            "& .MuiInputBase-root": { height: 40 },
                            "& .MuiInputBase-input": {
                              fontSize: 14,
                              padding: "6px 8px",
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Loan Sanctioned/Unsanctioned</InputLabel>
                    <Select
                      label="Loan Sanctioned/Unsanctioned"
                      value={bookingData.sanctioned}
                      onChange={(e) =>
                        handleUpdateBookingData({ sanctioned: e.target.value })
                      }
                      fullWidth={true}
                    >
                      <MenuItem value={true}>Sanctioned</MenuItem>
                      <MenuItem value={false}>Unsanctioned</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Booking Type</InputLabel>
                    <Select
                      value={bookingData.bookingType || ""}
                      label="Booking Type"
                      onChange={(e) =>
                        handleUpdateBookingData({ bookingType: e.target.value })
                      }
                      fullWidth
                    >
                      {bookingTypes.map((s) => (
                        <MenuItem value={s.value}>{s.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </div>
          <div className={classes.bottomSty}>
            <Button
              disabled={loading ? true : activeStep === 0}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              variant="outlined"
              style={{ marginRight: "15px" }}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleSubmit}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default CreateBookingModel;
