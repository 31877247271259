import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import PhotoAndFullView from "../styled/CommonComponents/PhotosAndFullView";
import { addCommentEmoji } from "./api.call";
import "./approval.css"

const useStyles = makeStyles((theme) => ({
    textCont: {
        width: "calc(100% - 55px)",
    },
    textHeaderCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5px",
        "& h3": {
            fontSize: "18px",
            fontWeight: "400",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
        },
        "& h4": {
            fontSize: "16px",
            fontWeight: "500",
        }
    },
    emojisCount: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        "& div": {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "5px",
            padding: "0px 7px",
            marginRight: "10px",
            fontSize: "22px"
        },
        "& span": {
            fontSize: "18px",
            fontWeight: "500",
            marginLeft: "5px",
            position: "relative",
            top: "-2px"
        }
    }
}));

const emojiArr = ["👍", "💯", "👋", "❤️", "😂", "😯"]

function countEmojis(arr) {
    return arr.reduce((acc, item) => {
        acc[item?.reaction] = (acc[item?.reaction] || 0) + 1;
        return acc;
    }, {});
}

export default function SingleComment({
    comment, setReactions, setOpenReactionsDetails
}) {
    const classes = useStyles();

    const { user } = useSelector((state) => state.auth);

    const [commentData, setCommentData] = useState(null)
    const [emojiCount, setEmojiCount] = useState(null)
    const [addEmojiLoader, setEmojiLoader] = useState(false)

    useEffect(() => {
        setCommentData(comment)
    }, [comment])

    const addEmoji = async (emoji) => {
        setEmojiLoader(true)
        await addCommentEmoji({
            commentId: comment?._id,
            reaction: emoji,
            userId: user?._id,
            userProfileId: user?.profile
        })
            .then((data) => {
                console.log(data)
                setCommentData({
                    ...commentData,
                    reactions: data?.reactions
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setEmojiLoader(false)
            })
    }

    useEffect(() => {
        if (commentData) {
            setEmojiCount(countEmojis(commentData?.reactions || []))
        }
    }, [commentData])


    return (
        <div className="picTextCont" >
            <Avatar
                src={commentData?.user?.displayPicture?.url}
                alt={commentData?.user?.displayName}
                style={{ width: "45px", height: "45px", marginTop: "5px" }}
            />
            <div className={classes.textCont} >
                <div className={classes.textHeaderCont} >
                    <div>
                        <h3>{commentData?.user?.displayName}</h3>
                        <p>{moment(commentData?.createdAt).format('MMM Do YYYY, hh:mm a')}</p>
                    </div>
                    <h4 style={commentData?.actionText === "Rejected" ? { color: "red" } : { color: "green" }} >{commentData?.actionText}</h4>
                </div>
                <div style={{ color: "gray" }} >{ReactHtmlParser(commentData?.text)}</div>
                <PhotoAndFullView actualImages={commentData?.files || []} />
                <div
                    className={classes.emojisCount}
                    onClick={() => {
                        setOpenReactionsDetails(true)
                        setReactions(commentData?.reactions)
                    }}
                >
                    {emojiCount && Object.keys(emojiCount).map((key) => (
                        <div>
                            {key}
                            <span>{emojiCount?.[key]}</span>
                        </div>
                    ))}
                </div>
            </div>
            {!addEmojiLoader && (
                <div className="emojCont" >
                    {emojiArr.map((emoji) => (
                        <span onClick={() => { addEmoji(emoji) }} >{emoji}</span>
                    ))}
                </div>
            )}
        </div>
    );
}
