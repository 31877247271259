import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from '@material-ui/core/TableContainer';
import Api from "../../../helpers/Api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import { Add } from "@material-ui/icons";
import Button from "../../styled/generic/Button";
import { useDispatch } from "react-redux";
import CreateTransactionModal from "./CreateTransactionModal";
import { TransactionRow } from "../transaction/Transaction";

function TransactionsList({ bookingId, profileId, booking, needToMakeDisable }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(false);
  const [showCreateTransactionModal, setShowCreateTransactionModal] = useState(false);
  const [transactions, setTransations] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [walletData, setWalletData] = useState({});

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId }).then((data) => {
        if (data) {
          setWalletData(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Failed to fetch wallet data",
            },
          });
        }
        setPageLoading(false);
      });
    }
  }, [profileId]);

  const fetchTransations = async () => {
    const data = await Api.post(`listing/booking/transaction/get/${bookingId}`);
    if (data) {
      setTransations(data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred 2",
        },
      });
    }
    setPageLoading(false);
  };

  useEffect(() => {
    fetchTransations();
  }, [bookingId, reload]);

  console.log(transactions)

  return (
    <Box
      sx={{
        paddingY: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h5">Transactions</Typography>
        {needToMakeDisable ? (<div></div>) : (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowCreateTransactionModal(true)}
          >
            Add Transaction
          </Button>
        )}
      </Box>
      <Box>
        {pageLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
        ) : (<>{(!transactions || transactions.length === 0) ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <Typography color={"GrayText"}>No Transactions Found</Typography>
          </Box>
        ) : (
          <TableContainer style={{ width: '100%' }} >
            <Table
              stickyHeader
              aria-label="sticky table"
              size={'small'}
              style={{ tableLayout: 'auto' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" >
                    Subject
                  </TableCell>
                  <TableCell align="left" >
                    Customer
                  </TableCell>
                  <TableCell align="center" >
                    Type
                  </TableCell>
                  <TableCell align="center" >
                    Unit
                  </TableCell>
                  <TableCell align="center"  >
                    Block
                  </TableCell>
                  <TableCell align="center"  >
                    Amount Payable
                  </TableCell>
                  <TableCell align="center"  >
                    Due Date
                  </TableCell>
                  <TableCell align="center"  >
                    Status
                  </TableCell>
                  <TableCell align="right"  >
                    Created
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions && transactions.map((transaction) => (
                  <TransactionRow
                    transaction={transaction}
                    history={history}
                    entityType={"Project"}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}</>)}
      </Box>
      <CreateTransactionModal
        isOpen={showCreateTransactionModal}
        onClose={() => {
          setShowCreateTransactionModal(false);
        }}
        initialData={{
          booking: booking,
        }}
        reload={reload}
        setReload={setReload}
        profileId={profileId}
        libraryId={walletData?.defaultLibrary}
      />
    </Box>
  );
}

export default TransactionsList;
