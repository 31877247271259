import React from 'react';
import "./flipCardStyle.css"

const FlipCard = ({ frontComponent, backComponent }) => {
    return (
        <div className="flip-card-container">
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    {frontComponent}
                </div>
                <div className="flip-card-back">
                    {backComponent}
                </div>
            </div>
        </div>
    );
};

export default FlipCard;