import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Avatar,
  Typography,
  Grid,
  useTheme,
  Checkbox,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Divider,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Api from "../../../helpers/Api";
import { getTeams } from "../booking/api";
import SimpleCard from "../../issue/issue.simple.card";
import CRMCard from "../../issue/issue.crm.card";

const getProfileData = async (profileId) => {
  const data = await Api.post("/profile/get/profile/populated", {
    profileId: profileId,
  });
  return data.data;
};

const getProjectByOrganizations = async (organizationId) => {
  const data = await Api.post("/projects/by-org", {
    organizationId: organizationId,
  });
  return data.data.projects;
};

const getAssignedUserProfiles = async (projectIds) => {
  const data = await Api.post("/issue/project/fetchAssignedProfiles", {
    projectIds: projectIds,
  });

  return data.data;
};

const BookingIssue = () => {
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const theme = useTheme();
  const [pageLoading, setPageLoading] = useState(false);
  const [issues, setIssues] = useState([]);
  const [users, setUsers] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [projects, setProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [selectedOrganizationIds, setSelectedOrganizations] = useState([]);
  const [selectedAccountManagerIds, setSelectedAccountManagerIds] = useState(
    []
  );
  const [searchStr, setSearchStr] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [teams, setTeams] = useState([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changeState, setChangeState] = useState(false);

  useEffect(() => {
    if (!user) return;
    getTeams(user?.profile).then((data) => {
      if (data) {
        setTeams(data);
      }
    });
  }, [user]);

  useEffect(() => {
    if (profileId) {
      getProfileData(profileId).then((data) => {
        if (data) {
          setProfile(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "An unknown error occurred",
            },
          });
        }
      });
    }
  }, [profileId]);

  useEffect(() => {
    if (!profile) return;
    if (profile?.parentModelName === "Organization") {
      getProjectByOrganizations(profile.parent._id).then((data) => {
        if (data) {
          console.log("fetched.project", data);
          setSelectedProjectIds(data.map((item) => item._id));
          setProjects(data);
        }
      });
    }
  }, [profile]);

  useEffect(() => {
    if (!profile) return;
    const projectIds =
      profile?.parentModelName === "Project"
        ? [profile?.parent?._id]
        : selectedProjectIds;
    getAssignedUserProfiles(projectIds).then((data) => {
      if (data) {
        console.log("data", data);
        setUsers(data);
      }
    });
  }, [profile, selectedProjectIds]);

  const getIssues = async () => {
    if (!profile?.parent?._id) return;
    const payload = {
      managerIds: selectedAccountManagerIds,
      organizationIds: selectedOrganizationIds,
      teamIds: selectedTeamIds,
      projectIds:
        profile?.parentModelName === "Organization"
          ? selectedProjectIds
          : [profile?.parent?._id],
    };
    const { data } = await Api.post(
      `issue/project/getByProjectId?q=${searchStr}`,
      payload
    );
    if (data) {
      setIssues(data);
      setLoading(false);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something wrong while fetching issues",
        },
      });
    }
  };

  useEffect(() => {
    getIssues().finally(() => {
      setDataLoading(false);
    });
  }, [profile]);

  useEffect(() => {
    const getFilterDataTimeout = setTimeout(() => {
      setDataLoading(true);
      getIssues().finally(() => {
        setDataLoading(false);
      });
    }, 2000);
    return () => clearTimeout(getFilterDataTimeout);
  }, [
    selectedAccountManagerIds,
    selectedOrganizationIds,
    selectedProjectIds,
    selectedTeamIds,
  ]);

  useEffect(() => {
    const getFilterDataTimeout = setTimeout(() => {
      getIssues();
    }, 1000);
    return () => clearTimeout(getFilterDataTimeout);
  }, [searchStr]);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <CircularProgress />
        <Typography variant="body1" color="text.secondary">
          Loading Issues
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        p: 1,
        bgcolor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Box>
        <Typography variant="h5" gutterBottom color="primary">
          Booking Issues
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 3 }}>
        <TextField
          placeholder="Search Issues"
          size="small"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, minWidth: "200px" }}
        />
        {profile?.parentModelName === "Organization" && (
          <FormControl size="small" sx={{ minWidth: "200px", flexGrow: 1 }}>
            <InputLabel id="project-select-label">Select projects</InputLabel>
            <Select
              labelId="project-select-label"
              label="Select projects"
              multiple
              value={selectedProjectIds}
              onChange={(e) => setSelectedProjectIds(e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const project = projects.find((p) => p._id === value);
                    return (
                      <Chip
                        key={value}
                        label={project?.displayName}
                        size="small"
                        z
                      />
                    );
                  })}
                </Box>
              )}
            >
              {projects.map((project) => (
                <MenuItem key={project._id} value={project._id}>
                  <Checkbox
                    checked={selectedProjectIds.indexOf(project._id) > -1}
                  />
                  <ListItemText primary={project?.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}{" "}
        <FormControl size="small" sx={{ minWidth: "200px", flex: 1 }}>
          <InputLabel id="team-select-label">Select teams</InputLabel>
          <Select
            labelId="team-select-label"
            label="Select teams"
            multiple
            value={selectedTeamIds}
            onChange={(e) => setSelectedTeamIds(e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const team = teams.find((p) => p._id === value);
                  return (
                    <Chip key={value} label={team?.title} size="small" z />
                  );
                })}
              </Box>
            )}
          >
            {teams
              .filter((item) => item.title)
              .map((team) => (
                <MenuItem key={team._id} value={team._id}>
                  <Checkbox checked={selectedTeamIds.indexOf(team._id) > -1} />
                  <ListItemText primary={team?.title} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: "200px", flex: 1 }}>
          <InputLabel id="account-manager-select-label">
            Select account managers
          </InputLabel>
          <Select
            labelId="account-manager-select-label"
            label="Select account managers"
            multiple
            value={selectedAccountManagerIds}
            onChange={(e) => setSelectedAccountManagerIds(e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const user = users.find((u) => u._id === value);
                  return (
                    <Chip
                      key={value}
                      label={user?.parent?.displayName}
                      avatar={<Avatar src={user.parent.displayPicture?.url} />}
                      size="small"
                    />
                  );
                })}
              </Box>
            )}
          >
            {users.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                <Checkbox
                  checked={selectedAccountManagerIds.indexOf(user._id) > -1}
                />
                <ListItemAvatar>
                  <Avatar src={user?.parent.displayPicture?.url} />
                </ListItemAvatar>
                <ListItemText primary={user?.parent?.displayName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider
        sx={{
          mb: 3,
        }}
      />
      {dataLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {issues.length > 0 ? (
            issues.map((issue, index) => {
              if (!issue?.template?._id) return null;
              const isCRM = issue.template?.isCRM;
              let IssueCard = isCRM ? CRMCard : SimpleCard;
              return (
                <IssueCard
                  key={issue._id}
                  issue={issue}
                  xs={12}
                  md={6}
                  lg={3}
                  onIssueDelete={() => {
                    setChangeState(!changeState);
                  }}
                />
              );
            })
          ) : (
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="20vh"
              >
                <Typography color={"GrayText"}>No issues found</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default BookingIssue;
