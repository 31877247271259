import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import StandardContainer from "../../../styled/generic/StandardContainer";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { getBookingEstimateById, updateBookingEstimate } from "../api";
import EstimateForm from "./EstimateForm";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

export default function EstimateEdit() {
  const { estimateId } = useParams();
  const dispatch = useDispatch();

  const [estimateData, setEstimateData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchEstimateData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getBookingEstimateById({
        bookingEstimateId: estimateId,
      });
      setEstimateData(data?.bookingEstimate);
    } catch (err) {
      console.error("Error fetching estimate data::", err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          message: "Error fetching estimate data",
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }, [estimateId]);

  useEffect(() => {
    fetchEstimateData();
  }, [estimateId]);

  const handleUpdateEstimate = async (data) => {
    try {
      const response = await updateBookingEstimate({
        bookingEstimateId: estimateId,
        estimateData: data,
      });
      setEstimateData(response?.bookingEstimate);
      dispatch({
        type: "AddApiAlert",
        payload: {
          message: "Estimate updated successfully",
          success: true,
        },
      });
    } catch (error) {
      console.error("Error updating estimate::", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          message: "Error updating estimate",
          success: false,
        },
      });
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <StandardContainer showAppBar={true} appBarTitle="Edit Booking Estimate">
      <StandardAppContainerRounded>
        <EstimateForm
          estimateData={estimateData}
          setEstimateData={setEstimateData}
          onUpdateEstimate={handleUpdateEstimate}
          loading={loading}
        />
      </StandardAppContainerRounded>
    </StandardContainer>
  );
}
