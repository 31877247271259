import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Button,
  Box,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Divider,
  CircularProgress,
  IconButton,
  CardHeader,
  Modal,
  Paper,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  Avatar,
  InputBase,
} from "@mui/material";
import {
  Edit as EditIcon,
  Close as CloseIcon,
  CalendarToday as CalendarIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
  LocalOffer as OfferIcon,
  AttachMoney as ValueIcon,
  Gavel as TermsIcon,
  VerifiedUser,
  Update as UpdateIcon,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import { FilterList, CalendarToday } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Percent } from "@mui/icons-material";
import {
  MonetizationOn,
  CardGiftcard,
  Star,
  Share,
  AssignmentReturn,
  LocalOffer,
} from "@mui/icons-material";
import {
  Phone as PhoneIcon,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { ActionInput, ThemeColorPicker } from "./CreateOfferModel";

const getOfferIcon = (offerType) => {
  switch (offerType) {
    case "Discount":
      return <MonetizationOn />;
    case "Freebie":
      return <CardGiftcard />;
    case "Upgrade":
      return <Star />;
    case "Referral Discounts":
      return <Share />;
    case "Money-Back Guarantee":
      return <AssignmentReturn />;
    case "Other":
      return <LocalOffer />;
    default:
      return <LocalOffer />;
  }
};

const fetchOffers = async (libraryId, filters, page, limit) => {
  const queryParams = new URLSearchParams({
    page,
    limit,
    ...filters,
  });
  const data = await Api.post(`/booking/offers?${queryParams.toString()}`, {
    library: libraryId,
  });
  return data;
};

const BookingOffers = ({ libraryId, projectIds, newOfferCreated }) => {
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [filters, setFilters] = useState({
    offerType: "",
    isActive: "",
    minValue: "",
    maxValue: "",
    startDate: "",
    endDate: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
  });

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
  };

  const getOffers = async () => {
    try {
      const data = await fetchOffers(
        libraryId,
        filters,
        pagination.page,
        pagination.limit
      );
      setOffers(data.offers);
      setPagination({
        ...pagination,
        totalPages: data.totalPages,
      });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch offers",
        },
      });
    }
  };

  useEffect(() => {
    if (libraryId) {
      getOffers().finally(() => {
        setPageLoading(false);
      });
    }
  }, [libraryId, newOfferCreated]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getOffers();
    }, 500);
    return () => clearTimeout(timer);
  }, [filters, pagination.page, pagination.limit]);

  if (pageLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        <CircularProgress />
        <Typography variant="body1">Loading Offers...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <FilterComponent
        filters={filters}
        handleFilterChange={handleFilterChange}
      />
      <Divider sx={{ my: "1rem" }} />
      <Grid
        container
        spacing={2}
        minHeight={{
          xs: "calc(100vh - 300px)",
          sm: "calc(100vh - 350px)",
          md: "calc(100vh - 450px)",
        }}
      >
        {offers?.length > 0 ? (
          offers?.map((offer) => (
            <Grid item xs={12} sm={6} md={3} key={offer?._id}>
              <OfferCard
                offer={offer}
                getOffers={getOffers}
                libraryId={libraryId}
                projectIds={projectIds}
              />
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "30vh",
            }}
          >
            <Typography>No Offers Found</Typography>
          </Box>
        )}
      </Grid>
      <Divider sx={{ my: "1rem" }} />
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={pagination.totalPages}
          page={pagination.page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default BookingOffers;

const OfferCard = ({ offer, getOffers, libraryId, projectIds }) => {
  const [open, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const offerData = {
    ...offer,
  };

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: "100%",
        minHeight: "243px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: 60,
          minWidth: 60,
          bgcolor: offerData?.theme?.primaryColor || "#FF6600",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            writingMode: "vertical-rl",
            transform: "rotate(180deg)",
            fontSize: 20,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {offerData?.actions.filter(
            (action) => action.actionType === "Booking"
          )[0]?.reward && (
            <>
              ₹
              {
                offerData?.actions.filter(
                  (action) => action.actionType === "Booking"
                )[0]?.reward
              }
            </>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            backgroundColor: offerData?.theme?.secondaryColor || "#FFF0E6",
            width: "100%",
            p: 2,
            position: "relative",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h6"
                component="div"
                color={offerData?.theme?.primaryColor || "#FF6600"}
                gutterBottom
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {offerData?.offerType}
              </Typography>
              <Chip
                label={offerData?.status}
                size="small"
                color={
                  offerData?.status === "Draft"
                    ? "warning"
                    : offerData?.status === "Archived"
                    ? "info"
                    : offerData?.status === "Active"
                    ? "success"
                    : "default"
                }
                sx={{
                  fontWeight: "bold",
                }}
              />
            </Box>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              mb: 1,
            }}
          >
            {offerData?.description?.length > 100
              ? offerData?.description?.slice(0, 100) + "..."
              : offerData?.description}
          </Typography>
          {offer?.project && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                mb: 1,
              }}
            >
              <Typography variant="body1" color="text.secondary">
                {offer?.project?.displayName}
              </Typography>
              <img
                src={offer?.project?.displayPicture?.url}
                alt={offer?.project?.displayName}
                style={{ width: 70, height: 70, borderRadius: "10%" }}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "auto",
            p: 1,
            gap: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "wrap",
            }}
          >
            {offerData?.actions.map((action, index) => (
              <Chip
                key={index}
                variant="filled"
                label={action.actionType}
                size="small"
                sx={{
                  backgroundColor: "#bae6fd",
                  color: "#3b82f6",
                }}
              />
            ))}
          </Box>
          <IconButton
            variant="outlined"
            size="small"
            queryParams
            color="primary"
            onClick={() => setIsOpen(true)}
          >
            <MoreHorizIcon />
          </IconButton>
        </Box>
      </Box>
      <OfferDisplayModal
        open={open}
        handleClose={handleClose}
        offerData={offerData}
        getOffers={getOffers}
        libraryId={libraryId}
        projectIds={projectIds}
      />
    </Card>
  );
};

const updateOffers = async (payload, dispatch) => {
  const data = await Api.post(`booking/offer-update/${payload._id}`, payload);
  if (data) {
    dispatch({
      type: "AddApiAlert",
      payload: {
        success: true,
        message: "Offer updated successfully",
      },
    });
  } else {
    dispatch({
      type: "AddApiAlert",
      payload: {
        success: false,
        message: "Failed to update offer",
      },
    });
  }
};

const OfferDisplayModal = ({
  open,
  handleClose,
  offerData,
  getOffers,
  libraryId,
  projectIds,
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [description, setDescription] = useState(offerData.description);
  const dispatch = useDispatch();

  const handleEditOpen = () => setIsEditOpen(true);
  const handleEditClose = () => setIsEditOpen(false);

  const handleUpdate = async (data) => {
    await updateOffers(data, dispatch).then((data) => {
      getOffers();
      handleEditClose();
    });
  };

  const [sending, setSending] = useState(false);

  const handleSendOfferNotification = async () => {
    try {
      setSending(true);
      const response = await Api.post("/booking/offer-push", {
        offerId: offerData._id,
        libraryId: libraryId,
        projectIds: projectIds,
      });

      if (response) {
        setSending(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Offer notifications sent successfully",
          },
        });
      }
    } catch (error) {
      console.error("Error sending offer notifications:", error);
      setSending(false);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message:
            error.response?.data?.error || "Failed to send offer notifications",
        },
      });
    } finally {
      setSending(false);
    }
  };

  const InfoCard = ({ icon, title, content, isDescription = false }) => (
    <Card elevation={1} sx={{ height: "100%" }}>
      <CardContent sx={{ p: 1.5, "&:last-child": { pb: 1.5 } }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {React.cloneElement(icon, {
            sx: { fontSize: 20, mr: 1 },
            color: "primary",
          })}
          <Typography variant="subtitle1" component="div">
            {title}
          </Typography>
        </Box>
        {isDescription ? (
          <InputBase
            multiline
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{
              "& .MuiInputBase-input": {
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                padding: 1,
                fontSize: "0.875rem",
                "&:focus": {
                  border: "2px solid #1976d2",
                  outline: "none",
                },
              },
            }}
          />
        ) : (
          <Typography variant="body2">{content}</Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="offer-modal"
      aria-describedby="offer-details"
    >
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          width: "90%",
          maxWidth: 800,
          p: 2,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h5" component="h2" color="primary">
            Offer Details
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              icon={<OfferIcon />}
              title="Offer Type"
              content={
                <Chip
                  label={offerData.offerType}
                  color="primary"
                  size="small"
                  sx={{ height: 24, fontSize: "0.75rem" }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              icon={<ValueIcon />}
              title="Value"
              content={`${offerData.value}%`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              icon={<CalendarIcon />}
              title="Expiry Date"
              content={new Date(offerData.expiryDate).toLocaleDateString()}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoCard
              icon={<DescriptionIcon />}
              title="Description"
              isDescription={false}
              content={`${offerData.description} Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus, saepe, aliquam suscipit odit, vel quisquam possimus repudiandae voluptatum corrupti unde exercitationem! Ipsam quaerat, optio tenetur quae tempora officia dolorem nostrum.`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard
              icon={<PersonIcon />}
              title="Created By"
              content={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={offerData?.createdBy?.parent?.displayPicture?.url}
                    sx={{ width: 20, height: 20, mr: 1 }}
                  />
                  <Typography variant="body2">
                    {offerData?.createdBy?.parent?.displayName}
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard
              icon={<UpdateIcon />}
              title="Status"
              content={
                <Chip
                  label={offerData.status}
                  color="secondary"
                  size="small"
                  sx={{ height: 24, fontSize: "0.75rem" }}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InfoCard
              icon={<TermsIcon />}
              title="Terms"
              content={offerData.terms || "N/A"}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoCard
              icon={<ListIcon />}
              title="Actions"
              content={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  {offerData.actions.map((action, index) => {
                    return (
                      <Chip
                        key={index}
                        label={action.actionType}
                        size="small"
                        sx={{
                          backgroundColor: "#bae6fd",
                          color: "#3b82f6",
                        }}
                      />
                    );
                  })}
                </Box>
              }
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="success"
            size="small"
            style={{ marginRight: "10px" }}
            onClick={handleSendOfferNotification}
            disabled={sending}
          >
            {sending ? "Sending..." : "Send Offer Notification"}
          </Button>

          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEditOpen}
            size="small"
          >
            Edit Offer
          </Button>
        </Box>

        <OfferSchemeEditModal
          open={isEditOpen}
          handleClose={handleEditClose}
          offerData={{ ...offerData, description }}
          handleSave={(data) => {
            handleUpdate(data);
          }}
        />
      </Paper>
    </Modal>
  );
};

const OfferSchemeEditModal = ({ open, handleClose, offerData, handleSave }) => {
  const [formData, setFormData] = useState(offerData);

  useEffect(() => {
    setFormData(offerData);
  }, [offerData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      expiryDate: date,
    }));
  };

  const handleActionsChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      actions: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave(formData);
  };

  console.log(
    new Date(formData?.expiryDate).toLocaleString("en-GB", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    })
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="offer-edit-modal"
      aria-describedby="offer-edit-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Typography
          id="offer-edit-modal"
          variant="h6"
          component="h2"
          gutterBottom
        >
          Edit Offer Scheme
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="offer-type-label">Offer Type</InputLabel>
          <Select
            labelId="offer-type-label"
            id="offerType"
            name="offerType"
            value={formData.offerType}
            label="Offer Type"
            onChange={handleChange}
          >
            {[
              "Discount",
              "Freebie",
              "Upgrade",
              "Referral Discounts",
              "Money-Back Guarantee",
              "Other",
            ].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          id="description"
          name="description"
          label="Description"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={3}
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <ThemeColorPicker
            theme={
              formData.theme || {
                primaryColor: "#FF6600",
                secondaryColor: "#FFF0E6",
              }
            }
            onChange={(newTheme) =>
              setFormData((prev) => ({ ...prev, theme: newTheme }))
            }
          />
        </Box>

        <FormControl fullWidth margin="normal">
          <ActionInput
            onChange={(actions) =>
              setFormData((prevData) => ({
                ...prevData,
                actions,
              }))
            }
            defaultActions={formData.actions}
          />
        </FormControl>
        {formData.offerType === "Discount" && (
          <TextField
            fullWidth
            margin="normal"
            id="value"
            name="value"
            label="Value"
            type="number"
            value={formData.value}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Percent />
                </InputAdornment>
              ),
            }}
          />
        )}
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          sx={{
            mt: "10px",
          }}
        >
          <DatePicker
            label="Expiry Date"
            value={new Date(formData.expiryDate)}
            onChange={handleDateChange}
            sx={{
              width: "100%",
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" />
            )}
          />
        </LocalizationProvider>
        <TextField
          fullWidth
          margin="normal"
          id="terms"
          name="terms"
          label="Terms"
          value={formData.terms}
          onChange={handleChange}
          multiline
          rows={3}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status"
            value={formData.status}
            label="Status"
            onChange={handleChange}
          >
            {["Active", "Archived", "Draft"].map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleClose} sx={{ mr: 1 }} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const FilterComponent = ({ filters, handleFilterChange }) => {
  const handleDateChange = (name) => (newValue) => {
    handleFilterChange({
      target: {
        name,
        value: newValue ? dayjs(newValue).format("YYYY-MM-DD") : null,
      },
    });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box sx={{ p: 1, display: "flex", flexWrap: "wrap", gap: 2 }}>
          <FormControl sx={{ minWidth: 200, flex: 1 }}>
            <InputLabel>
              <LocalOffer fontSize="small" /> Offer Type
            </InputLabel>
            <Select
              name="offerType"
              value={filters.offerType || ""}
              onChange={handleFilterChange}
              label="🏷️ Offer Type"
              sx={{ "& .MuiSelect-icon": { color: "primary.main" } }}
            >
              <MenuItem value="">All Types</MenuItem>
              <MenuItem value="Discount">Discount</MenuItem>
              <MenuItem value="Freebie">Freebie</MenuItem>
              <MenuItem value="Upgrade">Upgrade</MenuItem>
              <MenuItem value="Referral">Referral</MenuItem>
              <MenuItem value="Money-Back">Money-Back</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 150, flex: 1 }}>
            <InputLabel>
              <VerifiedUser fontSize="small" /> Status
            </InputLabel>
            <Select
              name="isActive"
              value={filters.isActive || ""}
              onChange={handleFilterChange}
              label="✓ Status"
              sx={{ "& .MuiSelect-icon": { color: "primary.main" } }}
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>

          <DatePicker
            label="Start Date"
            value={filters.startDate ? dayjs(filters.startDate) : null}
            onChange={handleDateChange("startDate")}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ minWidth: 150, flex: 1 }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <CalendarToday
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 1 }}
                    />
                  ),
                }}
              />
            )}
          />

          <DatePicker
            label="End Date"
            value={filters.endDate ? dayjs(filters.endDate) : null}
            onChange={handleDateChange("endDate")}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ minWidth: 150, flex: 1 }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <CalendarToday
                      fontSize="small"
                      color="primary"
                      sx={{ mr: 1 }}
                    />
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
