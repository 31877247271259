import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import SimpleCard from "../../issue/issue.simple.card";
import CRMCard from "../../issue/issue.crm.card";

const IssuesList = ({ bookingId }) => {
  const [searchStr, setSearchStr] = useState("");
  const [issues, setIssues] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [changeState, setChangeState] = useState();

  const getIssues = async () => {
    const { data } = await Api.post(`bookings/getIssues?q=${searchStr}`, {
      bookingIds: [bookingId],
    });
    if (data) {
      setIssues(data);
    } else {
      alert("Something wrong while fetching data");
    }
  };

  useEffect(() => {
    if (bookingId) {
      setPageLoading(true);
      getIssues().finally(() => {
        setPageLoading(false);
      });
    }
  }, [bookingId]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getIssues();
    }, 1000);
    return () => clearTimeout(searchTimeout);
  }, [searchStr]);

  return (
    <Box
      sx={{
        padding: "15px",
      }}
    >
      <Box>
        <TextField
          placeholder="Search Issues"
          size="small"
          fullWidth
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
        />
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          paddingY: "20px",
        }}
      >
        {pageLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
        ) : !issues.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
              width: "100%",
            }}
          >
            <Typography variant="body1">Issues not found</Typography>
          </Box>
        ) : (
          issues.map((issue, index) => {
            if (!issue?.template?._id) return null;
            const isCRM = issue.template?.isCRM;
            let IssueCard = isCRM ? CRMCard : SimpleCard;
            return (
              <IssueCard
                key={issue._id}
                issue={issue}
                xs={12}
                md={6}
                lg={3}
                onIssueDelete={() => {
                  setChangeState(!changeState);
                }}
              />
            );
          })
        )}
        <Box></Box>
      </Grid>
    </Box>
  );
};

export default IssuesList;
