import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import BookingTopCards from "./BookingTopCards";
import BrokerPaymentAnalysis from "./BrokerPaymentAnalysis";
import CustomerAnalysisTable from "./CustomerAnalysisTable";
import BookingDataAnalysis from "./BookingDataAnalysis";
import BookingChartAnalysis from "./BookingChartAnalysis";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Paper, TextField } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getBookingAnalysisFilterOptions } from "../api.call";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UnitWiseAnalysis from "./UnitWiseAnalysis";

const useStyles = makeStyles((theme) => ({
    filterCont: {
        display: "block",
        position: "absolute",
        width: "500px",
        top: "45px",
        right: "5px",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    hideFilterCont: {
        display: "none",
        position: "absolute",
        width: "500px",
        top: "45px",
        right: "5px",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    headerSty: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 0px 10px",
        position: "relative",
        "& h3": {
            fontSize: "25px",
            fontWeight: "600"
        }
    },
    filterAnalysisCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
    },
    onlyAnalysisCont: {
        width: "calc(100% - 290px)",
        height: "100%",
        overflowY: "auto",
        padding: "3px",
    },
    sideFilterCont: {
        width: "280px",
        height: "100%",
        padding: "10px",
        position: "relative",
        "& h3": {
            fontSize: "17px",
            fontWeight: "500",
            marginBottom: "15px"
        }
    },
    bottomBtnCont: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px",
        position: "absolute",
        bottom: "0px",
        right: "0px"
    },
    headingSty: {
        fontSize: "22px",
        fontWeight: "500",
        color: "black",
        margin: "10px 0px 15px"
    }
}));

function getLastThreeYears() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear - 2, currentYear - 3];
}

function getDateRange(value) {
    const today = new Date();
    let startDate, endDate;

    switch (value) {
        case "Today":
            startDate = endDate = today;
            break;
        case "Yesterday":
            startDate = endDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
            );
            break;
        case "Last 7 Days":
            startDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 7
            );
            endDate = today;
            break;
        case "Last 30 Days":
            startDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 30
            );
            endDate = today;
            break;
        case "Last 3 Months":
            startDate = new Date(
                today.getFullYear(),
                today.getMonth() - 3,
                today.getDate()
            )
            endDate = today
            break;
        case "Last Year":
            startDate = new Date(today.getFullYear() - 1, 0, 1)
            endDate = today
            break;

        default:
            const yaear1 = new Date(value, 0, 1, 0, 0, 2).getFullYear();
            startDate = new Date(yaear1, 0, 1);
            endDate = new Date(yaear1, 11, 31);
            break;
    }

    return { startDate, endDate };
}

const BookingAnalytics = () => {
    const { profileId } = useParams();
    const classes = useStyles();

    const [resetChildFilter, setResetChildFilter] = useState(0)
    const [filterOptions, setFilterOptions] = useState({
        projects: [],
        blocks: [],
        units: [],
    })
    const [filterObj, setFilterObj] = useState({
        durationType: null,
        from: null,
        to: null,
        projects: [],
        blocks: [],
        units: [],
    })
    const [totalCriticalAmount, setTotalCriticalAmount] = useState(0)
    const [totalTransactedAmount, setTotalTransactedAmount] = useState(0)

    const getDurationTypes = () => {
        let generatedTypes = getLastThreeYears()
        return [
            "Today",
            "Yesterday",
            "Last 7 Days",
            "Last 30 Days",
            "Last 3 Months",
            "Last Year",
            ...generatedTypes,
            "Custom"
        ].map((s) => s.toString())
    }

    const getFilterOptions = async () => {
        getBookingAnalysisFilterOptions({ profileId })
            .then((data) => {
                console.log(data)
                setFilterOptions(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getFilterOptions()
    }, [])

    return (
        <div className={classes.filterAnalysisCont} >
            <Paper
                className={classes.sideFilterCont}
                elevation={2}
            >
                <h3>Filter</h3>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Autocomplete
                        value={filterObj?.durationType}
                        options={getDurationTypes()}
                        getOptionLabel={(option) => { return option || "" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                variant="outlined"
                                label="Duration Type"
                                size="small"
                                placeholder="Select duration type"
                            />
                        )}
                        onChange={(e, value) => {
                            if (value === "Custom") {
                                setFilterObj({
                                    ...filterObj,
                                    durationType: value,
                                    from: null,
                                    to: null
                                })
                            } else {
                                const { startDate, endDate } = getDateRange(value);
                                setFilterObj({
                                    ...filterObj,
                                    durationType: value,
                                    from: startDate,
                                    to: endDate
                                })
                            }
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        size="small"
                    />
                    {filterObj?.durationType === "Custom" && (<>
                        <DatePicker
                            label="From"
                            value={filterObj.from}
                            onChange={(date) => {
                                setFilterObj({
                                    ...filterObj,
                                    from: date
                                })
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    fullWidth: true,
                                    sx: {
                                        "& .MuiInputBase-root": { height: 40 },
                                        "& .MuiInputBase-input": { fontSize: 14, padding: "6px 8px" },
                                    },
                                },
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                />}
                        />
                        <div style={{ height: "10px" }} ></div>
                        <DatePicker
                            label="To"
                            value={filterObj.to}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    fullWidth: true,
                                    sx: {
                                        "& .MuiInputBase-root": { height: 40 },
                                        "& .MuiInputBase-input": { fontSize: 14, padding: "6px 8px" },
                                    },
                                },
                            }}
                            onChange={(date) => {
                                setFilterObj({
                                    ...filterObj,
                                    to: date
                                })
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                />}
                        />
                        <div style={{ height: "10px" }} ></div>
                    </>)}
                    <Autocomplete
                        value={filterObj?.blocks}
                        options={filterOptions?.blocks}
                        getOptionLabel={(option) => { return option?.name || "" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                margin="normal"
                                variant="outlined"
                                label="Project Block"
                                placeholder="Select project block"
                            />
                        )}
                        multiple={true}
                        onChange={(e, value) => {
                            setFilterObj({
                                ...filterObj,
                                blocks: value
                            })
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        size="small"
                    />
                    <Autocomplete
                        value={filterObj?.units}
                        options={filterOptions?.units}
                        getOptionLabel={(option) => { return `${option?.floor} | ${option?.name}` || "" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ margin: "0px" }}
                                margin="normal"
                                variant="outlined"
                                label="Unit"
                                placeholder="Select unit"
                            />
                        )}
                        multiple={true}
                        onChange={(e, value) => {
                            setFilterObj({
                                ...filterObj,
                                units: value
                            })
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        size="small"
                    />
                    {filterOptions?.entityType === "Organization" && (
                        <Autocomplete
                            value={filterObj?.projects}
                            options={filterOptions?.projects}
                            getOptionLabel={(option) => { return option?.displayName || "" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    margin="normal"
                                    variant="outlined"
                                    label="Project"
                                    placeholder="Select project"
                                />
                            )}
                            multiple={true}
                            onChange={(e, value) => {
                                setFilterObj({
                                    ...filterObj,
                                    projects: value
                                })
                            }}
                            fullWidth
                            style={{ marginBottom: "10px" }}
                            size="small"
                        />
                    )}
                </LocalizationProvider>

                <div className={classes.bottomBtnCont} >
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<RestartAltIcon />}
                        onClick={() => {
                            setFilterObj({
                                durationType: null,
                                from: null,
                                to: null,
                                projects: [],
                                blocks: [],
                                units: [],
                            })
                            setResetChildFilter((prev) => prev + 1)
                        }}
                    >
                        Reset
                    </Button>
                </div>
            </Paper>
            <div className={classes.onlyAnalysisCont} >
                <h3 className={classes.headingSty} >Booking Analytics</h3>
                <BookingTopCards
                    profileId={profileId}
                    filterObj={filterObj}
                    setTotalCriticalAmount={setTotalCriticalAmount}
                    setTotalTransactedAmount={setTotalTransactedAmount}
                />
                <UnitWiseAnalysis
                    profileId={profileId}
                    filterObj={filterObj}
                    resetChildFilter={resetChildFilter}
                    totalCriticalAmount={totalCriticalAmount}
                    totalTransactedAmount={totalTransactedAmount}
                />
                <CustomerAnalysisTable
                    profileId={profileId}
                    filterObj={filterObj}
                />
                <BookingChartAnalysis
                    profileId={profileId}
                    filterObj={filterObj}
                    resetChildFilter={resetChildFilter}
                />
                <BrokerPaymentAnalysis
                    profileId={profileId}
                    filterObj={filterObj}
                />
                <BookingDataAnalysis
                    profileId={profileId}
                    filterObj={filterObj}
                />
            </div>
        </div>
    );
};

export default BookingAnalytics;