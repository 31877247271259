import React, { useState } from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getBookingAnalysisTopCardsData } from "../api.call";
import { useDebounce } from "react-use";
import Skeleton from '@mui/material/Skeleton';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

const sty = {
    width: "350px",
    minWidth: "350px",
    height: "130px",
    margin: "10px",
    borderRadius: "10px"
}

const CustomLeftArrow = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
                backgroundColor: "white",
                borderRadius: "50%",
                minWidth: "40px",
                height: "40px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                "&:hover": { backgroundColor: "gray" },
            }}
        >
            ◀
        </Button>
    );
};

const CustomRightArrow = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
                backgroundColor: "white",
                borderRadius: "50%",
                minWidth: "40px",
                height: "40px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                "&:hover": { backgroundColor: "gray" },
            }}
        >
            ▶
        </Button>
    );
};

const getIcon = (change) => {
    const sx = {
        fontSize: 24,
        color: "primary.main",
        fontWeight: "600"
    };
    switch (change) {
        case 0:
            return <CottageOutlinedIcon style={sx} />;
        case 1:
            return <Inventory2OutlinedIcon style={sx} />;
        case 2:
            return <ShoppingCartIcon style={sx} />;
        case 3:
            return <PendingActionsIcon style={sx} />;
        case 4:
            return <SendOutlinedIcon style={sx} />;
        case 5:
            return <ErrorOutlineIcon style={sx} />;
        case 6:
            return <CallReceivedIcon style={sx} />;
        case 7:
            return <TrendingUpIcon style={sx} />;
        case 8:
            return <TrendingUpIcon style={sx} />;
        default:
            return null;
    }
};

const getIconBgColor = (change) => {
    switch (change) {
        case 0:
            return "#90caf9";
        case 1:
            return "#90caf9";
        case 2:
            return "#ffcc80";
        case 3:
            return "#ffd54f";
        case 4:
            return "#b39ddb";
        case 5:
            return "#ef9a9a";
        case 6:
            return "#80cbc4"
        case 7:
            return "#ffe0b2"
        case 8:
            return "#b0bec5"
        default:
            return null;
    }
};

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const MetricCard = ({ title, value, count, unit, index }) => (
    <Paper
        elevation={2}
        sx={{
            p: 2,
            height: "100%",
            borderRadius: "10px"
        }}
    >
        <Box
            display={"flex"}
            alignItems={"start"}
            justifyContent={"space-between"}
        >
            <Box>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {title}(<span style={{ fontWeight: "600", color: "black" }} >{count}</span>)
                </Typography>
                <Typography
                    variant="h4"
                    component="div"
                    fontWeight="bold"
                    sx={{ mb: 1 }}
                >
                    {unit}
                    {numberWithCommas(value)}
                </Typography>
            </Box>
            <Box
                sx={{
                    padding: 2,
                    borderRadius: "50%",
                    backgroundColor: getIconBgColor(index)
                }}
            >
                {getIcon(index)}
            </Box>
        </Box>
    </Paper>
);

const BookingTopCards = ({
    profileId, filterObj, setTotalCriticalAmount,
    setTotalTransactedAmount
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTopBarData = () => {
        setLoading(true)
        getBookingAnalysisTopCardsData({ profileId, filterObj })
            .then((analysisData) => {
                setData(analysisData)
                setTotalCriticalAmount((analysisData.filter((s) => s.title === "Critical"))[0]?.value)
                setTotalTransactedAmount((analysisData.filter((s) => s.title === "Bill Sent"))[0]?.value)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useDebounce(() => {
        getTopBarData();
    }, 500, [filterObj]);

    const getItemsToShow = (CARD_WIDTH, GAP) => {
        if (typeof window !== "undefined") {
            return (Math.floor((window.innerWidth - 290) / (CARD_WIDTH + GAP))) + 0.2;
        }
        return 1;
    };

    const responsive = {
        allScreens: {
            breakpoint: { max: 5000, min: 0 },
            items: getItemsToShow(350, 20),
        },
    };

    return (
        <div style={{ position: "relative", marginBottom: "20px" }}>
            {loading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        overflow: "hidden"
                    }}
                >
                    <Skeleton variant="rect" style={sty} />
                    <Skeleton variant="rect" style={sty} />
                    <Skeleton variant="rect" style={sty} />
                    <Skeleton variant="rect" style={sty} />
                    <Skeleton variant="rect" style={sty} />
                </div>
            ) : (
                <Carousel
                    responsive={responsive}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                    showDots={false}
                    infinite={false}
                    autoPlay={false}
                    keyBoardControl={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    {data.map((metric, index) => (
                        <div key={index} style={{ padding: "8px" }}>
                            <MetricCard
                                index={index}
                                title={metric.title}
                                value={metric.value}
                                count={metric.count}
                                change={0}
                                unit={metric.suffix}
                            />
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

export default BookingTopCards;