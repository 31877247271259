import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import formatToRupees from "../../../helpers/formatToRupees";

/**
 * Given an array of objects, each representing a row in a table,
 * calculate the row totals for each column.
 *
 * The input data is expected to be an array of objects, where each
 * object has a "title" property representing the title of the row,
 * and other properties representing the columns of the table. The
 * values of these properties are expected to be numbers.
 *
 * The function returns an object where the keys are the column names
 * (i.e. the keys of the input objects except for "title"), and the
 * values are the totals for each column.
 *
 * @param {Object[]} data - The input data.
 * @returns {Object} - The row totals for each column.
 */
const calculateTotals = (data) => {
  return data?.reduce((acc, row) => {
    Object.keys(row).forEach((key) => {
      if (key !== "title") {
        acc[key] = (acc[key] || 0) + row[key];
      }
    });
    return acc;
  }, {});
};

export const PaymentTableView = ({ data }) => {
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    if (data) {
      setTotals(calculateTotals(data?.filter((item) => !item?.componentRef?.hideInTable)));
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Charge Type</TableCell>
            <TableCell align="right">Charge Amount</TableCell>
            <TableCell align="right">Current Due</TableCell>
            <TableCell align="right">Discount Amount</TableCell>
            <TableCell align="right">Delayed Interest</TableCell>
            <TableCell align="right">Receivable Due</TableCell>
            <TableCell align="right">Total Payable Amount</TableCell>
            <TableCell align="right">Received Amount</TableCell>
            <TableCell align="right">Receivable Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((item) => !item?.componentRef?.hideInTable)
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.chargeAmount || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.currentDue || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.rebateAmount || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.penaltyDue || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.dueReceivable || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.netReceivable || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.amountReceived || 0)}
                </TableCell>
                <TableCell align="right">
                  ₹{formatToRupees(row.amountReceivable || 0)}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell component="th" scope="row">
              Total
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.chargeAmount || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.currentDue || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.rebateAmount || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.penaltyDue || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.dueReceivable || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.netReceivable || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.amountReceived || 0)}
            </TableCell>
            <TableCell align="right">
              ₹{formatToRupees(totals.amountReceivable || 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
