import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Divider,
} from "@mui/material";
import {
  ArrowBack,
  AccountBalance,
  Home,
  FilePresent,
  CalendarToday,
  Edit,
} from "@mui/icons-material";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { useParams, useHistory } from "react-router-dom";
// Assuming these are imported from your project
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import ImageViewer from "../../global/ImageViewer";

const TransactionPage = () => {
  const { transactionId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const response = await Api.post(
          `/listing/booking/transaction/${transactionId}`
        );
        setTransaction(response);
      } catch (error) {
        dispatch({
          type: "AddApiAlert",
          payload: { success: false, message: "Failed to fetch transaction" },
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [transactionId, dispatch]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const openImageViewer = (url) => {
    setSelectedImage(url);
    setImageViewerOpen(true);
  };

  return (
    <Box sx={{ bgcolor: "#f4f6f8", minHeight: "100vh", p: 3 }}>
      <Paper
        elevation={0}
        sx={{ p: 2, mb: 3, borderRadius: 2, bgcolor: "#fff" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <IconButton onClick={() => history.goBack()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" fontWeight="bold">
              Transaction Details
            </Typography>
          </Box>
          {transaction.status === "Paid" ? (<div></div>) : (
            <IconButton
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(`/booking/transaction/edit/${transactionId}`);
              }}
            >
              <Edit />
            </IconButton>
          )}
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar
                src={
                  transaction?.booking?.customerProfile?.parent?.displayPicture
                    ?.url
                }
                sx={{ width: 30, height: 30, mr: 2 }}
              />
              <Box>
                <Typography variant="body1">
                  {transaction?.booking?.customerProfile?.parent?.displayName ||
                    "Unknown"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Customer
                </Typography>
              </Box>
            </Box>

            <Typography mb={1}>
              <CalendarToday sx={{ mr: 2, color: "text.secondary" }} />
              {new Date(transaction?.createdAt).toLocaleDateString()}
            </Typography>

            <Typography mb={1}>
              <Home sx={{ mr: 2, color: "text.secondary" }} />
              Unit:- {transaction?.booking?.unit?.name || "N/A"}
            </Typography>

            <Typography mb={1}>
              <NewspaperIcon sx={{ mr: 2, color: "text.secondary" }} />
              Type:- {transaction?.type || "General Transaction"}
            </Typography>

          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{ p: 2, pt: 0, bgcolor: "#f9f9f9", borderRadius: 2 }}
            >
              <Typography variant="h6" mb={2}>
                <AccountBalance sx={{ mr: 1, verticalAlign: "middle" }} />
                Payment Information
              </Typography>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography color="text.secondary">Amount Payable:</Typography>
                <Typography fontWeight="bold">
                  ₹ {transaction?.amountPayable || 0}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography color="text.secondary">Bank Name:</Typography>
                <Typography>
                  {transaction?.bankAccountId?.bankName || "N/A"}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography color="text.secondary">Account No:</Typography>
                <Typography>
                  {transaction?.bankAccountId?.accountNumber || "N/A"}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={0}
        sx={{ p: 2, mb: 3, borderRadius: 2, bgcolor: "#fff" }}
      >
        <Typography variant="h6" fontWeight="bold" mb={3}>
          Payment Details
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Charge Type
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Charge Amount
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Current Due
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Penalty Amount
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    Discount Amount
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    Total Payable Amount
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    Amount Received Amount
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transaction?.billItems?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="body1">{item.chargeType}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1">
                      ₹ {formatCurrency(item.chargeAmount, true)}
                    </Typography>
                  </TableCell>

                  <TableCell align="right">
                    <Typography variant="body1">
                      ₹ {formatCurrency(item.currentDue, true)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1">
                      ₹ {formatCurrency(item.penaltyDue, true)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1">
                      ₹ {formatCurrency(item.rebateAmount, true)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1">
                      ₹ {formatCurrency(item.totalAmountReceivable, true)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1">
                      ₹ {formatCurrency(item.amountReceived, true)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper elevation={0} sx={{ p: 2, borderRadius: 2, bgcolor: "#fff" }}>
        <Typography variant="h6" fontWeight="bold" mb={3}>
          Attached Documents
        </Typography>
        <Grid container spacing={2}>
          {transaction?.attachedFiles?.length > 0 ? (
            transaction.attachedFiles.map((file, index) => (
              <Grid item key={index} xs={6} sm={4} md={3} lg={2}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 1,
                    cursor: "pointer",
                    "&:hover": { boxShadow: 6 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => openImageViewer(file.files[0].url)}
                >
                  <img
                    src={file.files[0].url}
                    alt={`Document ${index + 1}`}
                    style={{
                      width: "100%",
                      height: 120,
                      objectFit: "cover",
                      marginBottom: 8,
                    }}
                  />
                  <Typography variant="body2" noWrap>
                    Document {index + 1}
                  </Typography>
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box textAlign="center" py={4}>
                <FilePresent
                  sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
                />
                <Typography color="text.secondary">
                  No documents attached
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>

      <ImageViewer
        isOpen={imageViewerOpen}
        setIsOpen={setImageViewerOpen}
        url={selectedImage}
      />
    </Box>
  );
};

export default TransactionPage;

function formatCurrency(amount, useLakh = false) {
  const isNegative = amount < 0;
  amount = Math.abs(amount);

  function addCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function addLakhCommas(num) {
    num = num.toString();
    let result = "";
    let count = 0;

    for (let i = num.length - 1; i >= 0; i--) {
      result = num[i] + result;
      count++;

      if (count === 3 && i !== 0) {
        result = "," + result;
        count = 0;
      } else if (count === 2 && i !== 0 && result.length > 4) {
        result = "," + result;
        count = 0;
      }
    }

    return result;
  }

  let [integerPart, fractionalPart] = amount.toFixed(2).split(".");

  if (useLakh) {
    integerPart = addLakhCommas(integerPart);
  } else {
    integerPart = addCommas(integerPart);
  }

  const result = `${integerPart}.${fractionalPart}`;
  return isNegative ? "-" + result : result;
}
