import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getBookingAnalysisForBrokers } from "../api.call";
import NumberFormat from "react-number-format";
import { useTheme } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import { useDebounce } from "react-use";
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        position: "relative",
        marginBottom: "15px",
        "& .react-multi-carousel-list": {
            paddingBottom: "5px",
        }
    },
    skeletonSty:{
        width: "450px",
        minWidth: "450px",
        height: "170px !important",
        margin: "10px",
        borderRadius: "10px"
    }
}));

const CustomLeftArrow = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
                backgroundColor: "white",
                borderRadius: "50%",
                minWidth: "40px",
                height: "40px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                "&:hover": { backgroundColor: "gray" },
            }}
        >
            ◀
        </Button>
    );
};

const CustomRightArrow = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 2,
                backgroundColor: "white",
                borderRadius: "50%",
                minWidth: "40px",
                height: "40px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                "&:hover": { backgroundColor: "gray" },
            }}
        >
            ▶
        </Button>
    );
};

const BrokerAmountCard = ({ brokerData }) => {
    const theme = useTheme();

    return (
        <Paper
            elevation={2}
            sx={{
                p: 2,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: "10px",
                position: 'relative',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 4,
                    bgcolor: 'primary.main',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                },
            }}
        >
            <Box display="flex" alignItems="center" gap={1.5} mb={2}>
                <Avatar
                    src={brokerData.broker?.displayPicture?.url}
                    sx={{ width: 40, height: 40 }}
                />
                <Box flex={1} minWidth={0}>
                    <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        noWrap
                        sx={{ maxWidth: 'calc(100% - 50px)' }}
                    >
                        {brokerData.broker?.displayName}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {brokerData.statusBreakdown?.length} statuses
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                {[
                    { label: 'Total', value: (parseFloat(brokerData?.totalAmountPayable || 0)).toFixed(2) },
                    { label: 'Due', value: (parseFloat(brokerData?.totalDueAmount || 0)).toFixed(2) },
                    { label: 'Paid', value: (parseFloat(brokerData?.totalAmountPaid || 0)).toFixed(2) },
                ].map((metric, idx) => (
                    <Grid item xs={4} key={idx}>
                        <Box textAlign="left">
                            <Typography variant="caption" color="text.secondary" display="block">
                                {metric.label}
                            </Typography>
                            <NumberFormat
                                value={metric.value}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="₹"
                                renderText={(value) => (
                                    <Typography
                                        variant="body2"
                                        fontWeight={500}
                                        sx={{
                                            wordBreak: 'break-word',
                                            minWidth: 60,
                                            display: 'inline-block',
                                        }}
                                    >
                                        {value}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', overflowX: 'auto', gap: 1, pb: 1 }}>
                {brokerData.statusBreakdown?.map((status, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                            p: 0.5,
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                        }}
                    >
                        <Typography variant="caption" color="text.primary" noWrap>
                            {status.status}
                        </Typography>
                        <NumberFormat
                            value={(parseFloat(status?.totalAmountPayable || 0)).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="₹"
                            renderText={(value) => (
                                <Typography variant="caption" fontWeight={500} noWrap>
                                    {value}
                                </Typography>
                            )}
                        />
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

const BrokerPaymentAnalysis = ({ profileId, filterObj }) => {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getBrokerPaymentAnalysis = () => {
        setLoading(true)
        getBookingAnalysisForBrokers({ profileId, filterObj })
            .then((analysisData) => {
                console.log(analysisData)
                setData(analysisData)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useDebounce(() => {
        getBrokerPaymentAnalysis();
    }, 500, [filterObj]);

    const getItemsToShow = (CARD_WIDTH, GAP) => {
        if (typeof window !== "undefined") {
            return (Math.floor((window.innerWidth - 290) / (CARD_WIDTH + GAP))) + 0.2;
        }
        return 1;
    };

    const responsive = {
        allScreens: {
            breakpoint: { max: 5000, min: 0 },
            items: getItemsToShow(550, 20),
        },
    };

    return (
        <div className={classes.mainCont} >
            {loading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        overflow: "hidden"
                    }}
                >
                    <Skeleton variant="rect" className={classes.skeletonSty} />
                    <Skeleton variant="rect" className={classes.skeletonSty}  />
                    <Skeleton variant="rect" className={classes.skeletonSty}  />
                    <Skeleton variant="rect" className={classes.skeletonSty}  />
                    <Skeleton variant="rect" className={classes.skeletonSty}  />
                </div>
            ) : (
                <Carousel
                    responsive={responsive}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                    showDots={false}
                    infinite={false}
                    autoPlay={false}
                    keyBoardControl={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                    {data.map((broker, index) => (
                        <div key={index} style={{ padding: "0 8px" }}>
                            <BrokerAmountCard key={index} brokerData={broker} />
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

export default BrokerPaymentAnalysis;