import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Button,
  IconButton,
  Paper,
  Avatar,
  InputAdornment,
} from "@material-ui/core";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import "react-quill/dist/quill.snow.css";
import "../doc/editor.css";
import CancelIcon from "@material-ui/icons/Cancel";
import ReactQuill from "react-quill";
import { getAllFiles } from "../post/api.call";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import { addCommentApiCall, takeActionAndAddCommment } from "./api.call";

const useStyles = makeStyles((theme) => ({
  mainDialogCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
    },
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 65px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "15px",
  },
  bottomAct: {
    width: "100%",
    height: "60px",
    padding: "0px 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderTop: "1px solid #ececec",
  },
  allImagesCont: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "10px",
  },
  imageCardCont: {
    width: "33.33%",
    padding: "10px",
  },
  imageCard: {
    width: "100%",
    height: "200px",
    position: "relative",
    backgroundPosition: "center",
    backgroundSize: "cover",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
  },
  closeBtn: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  uploadAreaCont: {
    width: "100%",
    height: "100px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "10px",
    border: `2px dashed ${theme.palette.primary.main}`,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "script",
  "color",
  "background",
];

export default function ApprovalActionDrawer({
  openAction, setOpenAction, loadingBool, setLoadingBool, actionType, templateId,
  parent, parentModelName, statusField, statusValue, requestId, origin, organizationId,
  activeStage, reloadChat, actionCallBack, noNeedToUpdateParentStatus = false, projectId,
  ...other
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const { createdFileIds } = useSelector((state) => state.file);
  const { user } = useSelector((state) => state.auth);

  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!openAction) {
      dispatch({ type: "FileUploadReset" });
    }
  }, [openAction]);

  const afterFileAdded = async () => {
    if (createdFileIds && createdFileIds.length > 0) {
      await getAllFiles({ files: createdFileIds })
        .then((data) => {
          setFiles([...files, ...data]);
          dispatch({ type: "FileUploadReset" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addComment = async () => {
    setLoadingBool(true);
    await addCommentApiCall({
      text: comment,
      actionText: "",
      files: files ? files.map((s) => s?._id) : [],
      action: activeStage?._id,
      actionModelName: "ApprovalRequestStage",
      parent,
      parentModelName,
      origin,
      user: {
        _id: user?._id,
        profile: user?.profile,
        displayName: user?.displayName
      },
      requestId: requestId,
      templateId,
      statusFrom: activeStage?.status,
      statusTo: statusValue,
      projectId: projectId,
      organizationId: organizationId
    })
      .then((data) => {
        console.log(data);
        if (reloadChat) {
          reloadChat();
        }
        setLoadingBool(false);
        setOpenAction(false);
        setComment("");
        setFiles([]);
      })
      .catch((err) => {
        setLoadingBool(false);
        console.log(err);
      });
  };

  const takeActionAsync = async () => {
    setLoadingBool(true);
    await takeActionAndAddCommment({
      requestId: requestId,
      userProfileId: user?.profile,
      noNeedToUpdateParentStatus,
      text: comment,
      actionText: statusValue === "Rejected" ? statusValue : `Approved For ${statusValue}`,
      files: files ? files.map((s) => s?._id) : [],
      statusField: statusField,
      statusValue: statusValue,
      user: {
        _id: user?._id,
        profile: user?.profile,
        displayName: user?.displayName
      },
      origin: origin,
      projectId: projectId,
      organizationId: organizationId,
      statusFrom: activeStage?.status,
      dataId: parent
    })
      .then(async (data) => {
        console.log(data);
        if (reloadChat) {
          await reloadChat();
        }
        actionCallBack(data?.nextAction, data?.nextStatus)
        setLoadingBool(false);
        setOpenAction(false);
        setComment("");
        setFiles([]);
        if (data?.nextAction === "ApprovalForNextStage") {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "Current stage is successfully approved and upgraded for next stage",
            },
          });
        } else if (data?.nextAction === "ApprovalForSameStage") {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "You successfully approved the stage and waiting for others",
            },
          });
        } else if (data?.nextAction === "RequestRejected") {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Request is successfully rejected and the request is closed",
            },
          });
        }
      })
      .catch((err) => {
        setLoadingBool(false);
        console.log(err);
      });
  };

  return (
    <NormalDrawer
      openDrawer={openAction}
      setOpenDrawer={setOpenAction}
      anchor={"right"}
      width={"50vw"}
      title={actionType === "addComment" ? "Add Comment" : "Approval Action"}
      content={
        <div className={classes.mainDialogCont}>
          <div className={classes.bodyCont}>
            {actionType !== "addComment" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  You are changing status from{" "}
                  <span style={{ fontWeight: "bold" }}>{activeStage?.status}</span> to{" "}
                  <span style={{ fontWeight: "bold" }}>{statusValue}</span>
                </span>
              </div>
            )}
            <ReactQuill
              {...other}
              className="EditorStyle1"
              value={comment}
              placeholder={"Enter terms and condition"}
              onChange={setComment}
              modules={modules}
              formats={formats}
              theme="snow"
            ></ReactQuill>
            <div style={{ padding: "20px 0px" }}>
              <CustomFileUploadButton
                showComponent={
                  <div className={classes.uploadAreaCont}>+ Add Photo(s)</div>
                }
                parentType={parentModelName}
                parentId={parent}
                fileNum={25}
                givenFileTypes={["image"]}
                givenMaxSize={26214400}
                closeFunCall={afterFileAdded}
              />
              {files && files.length > 0 && (
                <div className={classes.allImagesCont}>
                  {files.map((file) => (
                    <div className={classes.imageCardCont}>
                      <div
                        className={classes.imageCard}
                        style={{
                          backgroundImage: `url(${file?.thumbUrl ? file?.thumbUrl : file?.url
                            })`,
                        }}
                      >
                        <IconButton
                          className={classes.closeBtn}
                          onClick={() => {
                            const filteredFiles = files.filter(
                              (entityProfile) => entityProfile?._id != file?._id
                            );
                            setFiles(filteredFiles);
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div style={{ width: "100%", height: "5px" }}>
            {loadingBool && <LinearProgress />}
          </div>
          <div className={classes.bottomAct}>
            {actionType === "addComment" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ width: "40%" }}
                onClick={addComment}
                disabled={comment && comment.trim().length > 1 ? false : true}
              >
                Save And Close
              </Button>
            ) : (
              <>
                {statusValue === "Rejected" ? (
                  <ColorButton
                    variant="contained"
                    color="primary"
                    style={{ width: "40%" }}
                    startIcon={<ClearIcon />}
                    disabled={comment && comment.trim().length > 1 ? false : true}
                    onClick={takeActionAsync}
                  >
                    Reject
                  </ColorButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "40%" }}
                    startIcon={<CheckIcon />}
                    disabled={comment && comment.trim().length > 1 ? false : true}
                    onClick={takeActionAsync}
                  >
                    Approve For {statusValue}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
}