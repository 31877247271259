import Api from '../../helpers/Api';

export const getBillingAcountAgainstProfile = async (obj) => {
    try {
        const res = await Api.post('wallet/billing/billing-account/sharedIds/get', obj);
        const result = res?.billingAccounts || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const createMultipleTxs = async (obj) => {
    try {
        const res = await Api.post('listing/booking/multiple/transactions/create', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const transferStatusToAccountingTx = async (obj) => {
    try {
        const res = await Api.post('listing/booking/transaction/status/transfer', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingPaginatedTransactions = async (obj) => {
    try {
        const res = await Api.post('/listing/booking/transaction/get', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingAnalysisTopCardsData = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/top-cards', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingAnalysisForBrokers = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/broker-wise/ammounts', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingAnalysisForCustomers = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/customer-wise/amounts', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingChartAnalysisData = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/chart-data', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingAmountsAnalysisData = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/booking-amount/aggregated', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingAnalysisFilterOptions = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/filter/options', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getUnitWiseAnalysisData = async (obj) => {
    try {
        const res = await Api.post('listing/booking/analysis/unit-wise/analysis', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBlockUnitsAnalysis = async (obj) => {
    try {
        const res = await Api.post('units/box-data/analysis', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBookingTransactionId = async (bookingTxId) => {
    try {
        const res = await Api.post(`/listing/booking/transaction/${bookingTxId}`);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};