import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import { getBookingAnalysisForCustomers } from '../api.call';
import { useDebounce } from 'react-use';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
    tableRoot: {
        width: "100%",
        marginBottom: "20px",
        borderRadius: "10px",
        overflow: "hidden",
        padding: "15px",
        "& .MuiTablePagination-root": {
            border: "1px solid #d2d1d1",
            marginTop: "-3px"
        }
    },
    container: {
        width: "100%",
        minHeight: "250px",
        maxHeight: "500px",
        border: "1px solid #d5d1d1",
        "& tr": {
            height: "auto"
        },
        "&.MuiTableCell-root": {
            outline: "1px solid #ececec",
            border: "none",
        },
        "& table": {
            tableLayout: "auto",
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
        },
        "& th": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "10px"
        },
        "& td": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "10px 10px",
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    skeletonSty: {
        width: "100%",
        height: "50px !important",
        marginBottom: "2px"
    },
    headingSty:{
        fontSize: "22px",
        fontWeight: "500",
        color: "black",
        marginBottom: "15px"
    }
}));

export default function CustomerAnalysisTable({
    profileId, filterObj
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [customersData, setCustomersData] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [loader, setLoader] = useState(true)
    const [changeState, setChangeState] = useState(true)

    const getCustomerAnalysisData = async () => {
        setLoader(true)
        await getBookingAnalysisForCustomers({
            profileId,
            curPage: curPage - 1,
            limit: pageLimit,
            filterObj
        })
            .then((data) => {
                setCustomersData(data?.data || [])
                let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                setTotalPages(locTotalPage)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoader(false)
            })
    }

    useDebounce(() => {
        getCustomerAnalysisData()
    }, 1000, [curPage, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, profileId])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Paper className={classes.tableRoot} elevation={2} >
            <h3 className={classes.headingSty} >Customer</h3>
            {loader ? (<>
                <Skeleton
                    variant="rect"
                    className={classes.skeletonSty}
                    style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                    }}
                />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton variant="rect" className={classes.skeletonSty} />
                <Skeleton
                    variant="rect"
                    className={classes.skeletonSty}
                    style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                    }}
                />
            </>) : (<>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >
                                    Customer
                                </TableCell>
                                <TableCell align="center"  >
                                    Project/Block/Floor/Unit
                                </TableCell>
                                <TableCell align="center" >
                                    Total Amount
                                </TableCell>
                                <TableCell align="center" >
                                    Paid Transactions
                                </TableCell>
                                <TableCell align="center"  >
                                    Total Paid Amount
                                </TableCell>
                                <TableCell align="center"  >
                                    Due Transactions
                                </TableCell>
                                <TableCell align="center" >
                                    Total Due Amount
                                </TableCell>
                                <TableCell align="center"  >
                                    Critical Transactions
                                </TableCell>
                                <TableCell align="right"  >
                                    Total Critical Amount
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customersData && customersData.map((cfData) => (
                                <TableRow
                                    style={cfData?.criticalTransactions > 0 ? { backgroundColor: "#ffded1" } : cfData?.dueTransactions > 0 ? { backgroundColor: "#fff0cc" } : {}}
                                >
                                    <TableCell align="left" >
                                        {cfData?.customer?.displayName}
                                    </TableCell>
                                    <TableCell align="center"  >
                                        {`${cfData?.projectData?.displayName}/${cfData?.blockData?.name}/${cfData?.unitData?.floor}/${cfData?.unitData?.name}`}
                                    </TableCell>
                                    <TableCell align="center" >
                                        ₹ {numberWithCommas(cfData?.totalAmount || 0)}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {numberWithCommas(cfData?.paidTransactions || 0)}
                                    </TableCell>
                                    <TableCell align="center"  >
                                        ₹ {numberWithCommas(cfData?.totalPaidAmount || 0)}
                                    </TableCell>
                                    <TableCell align="center"  >
                                        {numberWithCommas(cfData?.dueTransactions || 0)}
                                    </TableCell>
                                    <TableCell align="center" >
                                        ₹ {numberWithCommas(cfData?.totalDueAmount || 0)}
                                    </TableCell>
                                    <TableCell align="center"  >
                                        {numberWithCommas(cfData?.criticalTransactions || 0)}
                                    </TableCell>
                                    <TableCell align="right"  >
                                        ₹ {numberWithCommas(cfData?.criticalAmount || 0)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </>)}
        </Paper>
    );
}