import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "react-quill/dist/quill.snow.css";
import "../doc/editor.css";
import { Button } from "@material-ui/core";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from '@material-ui/core';
import { changeTemplateForExisitingRequest, getApprovalTemplatesByLibrary } from "./api.call";

const useStyles = makeStyles((theme) => ({
    subheadCreateCont: {
        width: "550px"
    },
}));

export default function ApprovalTemplateChange({
    profileId, templateType, parentId, parentModelName, 
    previousReqId, reloadData
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [openChangeDialog, setOpenChangeDialog] = useState(false)
    const [templates, setTemplates] = useState([])
    const [selectedTemplates, setSelectedTemplates] = useState()
    const [loader, setLoader] = useState(false)

    const getTemplateOptions = async () => {
        console.log(profileId, templateType)
        await getApprovalTemplatesByLibrary({
            profileId,
            type: templateType
        })
            .then((data) => {
                console.log(data)
                setTemplates(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        console.log(profileId, templateType)
        if (openChangeDialog && profileId && templateType) {
            getTemplateOptions()
        }
    }, [openChangeDialog, profileId, templateType])

    const updateTemplate = async () => {
        setLoader(true)
        await changeTemplateForExisitingRequest({
            profileId, 
            curStatus : "Draft", 
            userProfile: user?.profile, 
            templateId: selectedTemplates?._id,
            parentId, 
            parentModelName, 
            previousReqId,
        })
            .then(async(data) => {
                console.log(data)
                await reloadData();
                setLoader(false)
                setOpenChangeDialog();
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                      success: true,
                      message: "Approved template is updated successfully",
                    },
                  });
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (<>
        <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
                setOpenChangeDialog(true)
            }}
            endIcon={<PublishedWithChangesIcon />}
        >
            Change Approval
        </Button>

        <NormalDialog
            openDialog={openChangeDialog}
            handleCloseShare={() => { setOpenChangeDialog(false) }}
            pageTitle={"Change Approval Template"}
            loading={loader}
            content={<div className={classes.subheadCreateCont} >
                <Autocomplete
                    options={templates}
                    value={selectedTemplates}
                    onChange={(event, value) => {
                        setSelectedTemplates(value)
                    }}
                    fullWidth
                    size="small"
                    style={{ margin: "20px 0px 40px" }}
                    getOptionLabel={(option) => option?.title || ""}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            {...params}
                            placeholder={"Select approval template"}
                        />
                    )}
                />
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        onClick={updateTemplate}
                        disabled={loader ? true : !selectedTemplates?._id}
                    >
                        Update Template
                    </Button>
                </div>
            </div>}
        />
    </>);
}