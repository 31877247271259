import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../styled/generic/Button";
import { Add, SettingsOutlined } from "@material-ui/icons";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import { LuMessagesSquare } from "react-icons/lu";
import { PiHammerDuotone } from "react-icons/pi";
import Api from "../../../helpers/Api";
import emptyPage from "../../../Assets/EmptyPageSVG.svg";
import Bookings from "./Bookings";
import Transaction from "../transaction/Transaction";
import DemandDeeds from "../demanddeed/DemandDeeds";
import CreateDemandDeedDrawer from "./demandDeed/CreateDemandDeedDrawer";
import CreateTransactionModal from "./CreateTransactionModal";
import BookingOffers from "../offers/BookingOffers";
import CreateOfferModel from "../offers/CreateOfferModel";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BookingAnalytics from "../analytics/BookingAnalytics";
import OldBookingAnalytics from "../analytics/OldBookingAnalytics";
import CreateBookingModel from "./CreateBookingModel";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import BookingSchedule from "./BookingSchedule";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import HandshakeIcon from "@mui/icons-material/Handshake";
import GavelIcon from "@mui/icons-material/Gavel";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { createDefaultTemplateHook } from "../../approval/approvalHelper";
import { SALE_TYPE } from "../../../helpers/constants";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Paper } from "@material-ui/core";
import GridViewIcon from "@mui/icons-material/GridView";
import BlockUnitsHome from "../BlockUnits/BlockUnitsHome";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CreateEstimateModal from "./estimate/CreateEstimateModal";
import EstimateList from "./estimate/EstimateList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  backArrow: {
    fontSize: "30px",
  },
  editIconSty: {
    fontSize: "25px",
  },
  invoiceEditBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "15px",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
    backgroundColor: "white",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  nextIcon: {
    transform: "rotate(180deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
  },
  tabPagesCont: {
    width: "100%",
    height: "calc(100% - 70px)",
    marginTop: "10px",
    overflow: "hidden",
    borderRadius: "10px",
  },
  emptyLibrarayCont: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& div": {
      width: "100%",
      marginTop: "10px",
      padding: "0px 20px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
      textAlign: "center",
    },
    "& a": {
      textDecoration: "none",
      fontSize: "14px",
      marginLeft: "5px",
      cursor: "pointer",
      color: "blue",
    },
  },
  profileLoaderCont: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

const BookingHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [showCreateBookingModel, setShowCreateBookingModel] = useState(false);
  const [tab, setTab] = useState("Booking");
  const [showCreateDemandDeedDrawer, setShowCreateDemandDeedDrawer] =
    useState(false);
  const [showCreateTransactionModal, setShowCreateTransactionModal] =
    useState(false);
  const [showCreateEstimateModal, setShowCreateEstimateModal] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showCreateOfferModel, setShowCreteOfferModel] = useState(false);
  const [projectIds, setProjectIds] = useState([]);
  const [projects, setProjects] = useState([]);
  const [reload, setReload] = useState(false);
  const [newOfferCreated, setNewOfferCreated] = useState(false);
  const [profileDataLoader, setProfileDataLoader] = useState(false);
  const [profileData, setProfileData] = useState(false);
  const [defaultCreateLoader, setDefaultCreateLoader] = useState(false);
  const [bookingTypes, setBookingTypes] = useState([]);

  const getTabFromQuery = () => {
    const params = new URLSearchParams(location.search);
    if (params.get("tab")) {
      return params.get("tab");
    } else {
      setQuery("tab", "Booking");
      return "Booking";
    }
  };

  useEffect(() => {
    setTab(getTabFromQuery());
  }, []);

  const getBookings = async () => {
    const payload =
      projectIds?.length > 0
        ? { projectIds }
        : { libraryId: profileData?.parent?.wallet?.defaultLibrary?._id };
    const data = await Api.post("/listing/booking/get", payload);

    if (data) {
      setBookings(data.bookings);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch bookings",
        },
      });
    }
  };

  useEffect(() => {
    if (
      profileData?.parent?.wallet?.defaultLibrary?._id &&
      projectIds?.length > 0
    ) {
      getBookings();
    }
  }, [profileData, projectIds]);

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setShowCreateTransactionModal(true);
  };

  const setQuery = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set("tab", value);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const handleTabChange = (val) => {
    setTab(val);
    setQuery("tab", val);
  };

  const getProjectData = async () => {
    setProfileDataLoader(true);
    await getProfileDataPopulate({ profileId })
      .then((data) => {
        let filteredOptions = [];
        setProfileData(data);
        if (
          data?.parent?.wallet?.defaultLibrary?.defaultUnitSaleBookingApproval
            ?.stages &&
          data.parent.wallet.defaultLibrary.defaultUnitSaleBookingApproval
            .stages.length > 0
        ) {
          const initialStage =
            data?.parent?.wallet?.defaultLibrary?.defaultUnitSaleBookingApproval
              ?.stages[0];
          const approversArr = initialStage.approvers.map(
            (approver) => approver?.profile
          );
          if (
            initialStage?.approvalType === "anybody" ||
            (approversArr &&
              approversArr.length > 0 &&
              approversArr.includes(user?.profile))
          ) {
            filteredOptions.push(SALE_TYPE.Unit_Sale);
          }
        }
        if (
          data?.parent?.wallet?.defaultLibrary
            ?.defaultConstructionLinkedUnitSaleBookingApproval?.stages &&
          data.parent.wallet.defaultLibrary
            .defaultConstructionLinkedUnitSaleBookingApproval.stages.length > 0
        ) {
          const initialStage =
            data?.parent?.wallet?.defaultLibrary
              ?.defaultConstructionLinkedUnitSaleBookingApproval?.stages[0];
          const approversArr = initialStage.approvers.map(
            (approver) => approver?.profile
          );
          if (
            initialStage?.approvalType === "anybody" ||
            (approversArr &&
              approversArr.length > 0 &&
              approversArr.includes(user?.profile))
          ) {
            filteredOptions.push(SALE_TYPE.Construction_Linked_Unit_Sale);
          }
        }
        setBookingTypes(filteredOptions);
        setProfileDataLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setProfileDataLoader(false);
      });
  };

  useEffect(() => {
    if (profileId) {
      getProjectData();
    }
  }, [profileId]);

  const getProjectsList = async () => {
    if (profileData?.parentModelName === "Organization") {
      const data = await Api.post("projects/by-org", {
        organizationId: profileData?.parent?._id,
      });
      if (data) {
        setProjectIds(data?.data?.projects?.map((project) => project?._id));
        setProjects(data?.data?.projects);
      }
    } else {
      setProjectIds([profileData?.parent?._id]);
      setProjects([profileData]);
    }
  };

  useEffect(() => {
    if (profileData?.parent?._id) {
      getProjectsList();
    }
  }, [profileData?.parent?._id]);

  const createDefaultsTemplates = async () => {
    setDefaultCreateLoader(true);
    const entity = profileData?.parent?._id;
    const entityModelName = profileData?.parentModelName;
    const entityParent = profileData?.parent?.ownerProfile?.parent;
    const entityParentModelName =
      profileData?.parent?.ownerProfile?.parentModelName;
    await createDefaultTemplateHook({
      profileId,
      entity,
      entityModelName,
      entityParent,
      userId: user?._id,
      entityParentModelName,
      ownerProfileId: profileData?.parent?.ownerProfile?._id,
      types: [
        "Unit Sale Booking",
        "Construction Linked Unit Sale Booking",
        "Transaction",
      ],
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
    })
      .then(async (data) => {
        await getProjectData();
        setDefaultCreateLoader(false);
      })
      .catch(async (error) => {
        console.log(error);
        await getProjectData();
        setDefaultCreateLoader(false);
      });
  };

  const handleOfferCreated = () => {
    setNewOfferCreated((prev) => !prev);
  };

  return (
    <>
      {profileDataLoader ? (
        <div className={classes.profileLoaderCont}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {!profileData?.parent?.wallet?.defaultLibrary?._id ? (
            <div className={classes.emptyLibrarayCont}>
              <img src={emptyPage} />
              <div>
                <p>
                  No Library exists in the current directory or any library
                  still didn't selected as default library.
                  <br />
                  Click{" "}
                  <a
                    onClick={() => {
                      history.push(
                        `/offerings-categories/management/${profileId}`
                      );
                    }}
                  >
                    here
                  </a>{" "}
                  to create and manage your library(s), category(s), product(s)
                  and service(s)
                </p>
              </div>
            </div>
          ) : (
            <>
              {!profileData?.parent?.wallet?.defaultLibrary
                ?.defaultUnitSaleBookingApproval ||
              !profileData?.parent?.wallet?.defaultLibrary
                ?.defaultConstructionLinkedUnitSaleBookingApproval ? (
                <div className={classes.emptyLibrarayCont}>
                  <img src={emptyPage} />
                  <div>
                    <p>
                      {defaultCreateLoader ? (
                        `Template Creating....`
                      ) : (
                        <>
                          Template is missing in the current directory or no
                          template is selected as default. <br /> Click{" "}
                          <a onClick={createDefaultsTemplates}>here</a> to
                          create default template(s)
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={classes.root}>
                  <div className={classes.topBar}>
                    <div className={classes.barLeft}>
                      <IconButton
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                      </IconButton>
                      <h3 className={classes.headerTitle}>Booking</h3>
                    </div>

                    <div className={classes.barRight}>
                      <Tooltip title="Issues" enterDelay={1500}>
                        <IconButton
                          onClick={() =>
                            history.push(`/booking/issues/${profileId}`)
                          }
                          style={{ marginRight: "15px" }}
                        >
                          <PiHammerDuotone style={{ fontSize: "1.3rem" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Messages" enterDelay={1500}>
                        <IconButton
                          onClick={() =>
                            history.push(`/booking/messages/${profileId}`)
                          }
                          style={{ marginRight: "15px" }}
                        >
                          <LuMessagesSquare style={{ fontSize: "1.3rem" }} />
                        </IconButton>
                      </Tooltip>
                      {tab === "Booking" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setShowCreateBookingModel(true);
                            }}
                            startIcon={<Add />}
                          >
                            New Booking
                          </Button>
                          <CreateBookingModel
                            libraryId={
                              profileData?.parent?.wallet?.defaultLibrary?._id
                            }
                            open={showCreateBookingModel}
                            setOpen={setShowCreateBookingModel}
                            isNeedToRedirect={true}
                            bookingTypes={bookingTypes}
                            onClose={(newBookingId) => {
                              setShowCreateBookingModel(false);
                            }}
                            profileId={profileId}
                          />
                        </>
                      )}
                      {tab === "Estimate" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowCreateEstimateModal(true)}
                            startIcon={<Add />}
                          >
                            Create Estimate
                          </Button>
                          <CreateEstimateModal
                            open={showCreateEstimateModal}
                            setOpen={setShowCreateEstimateModal}
                            profileId={profileId}
                            libraryId={
                              profileData?.parent?.wallet?.defaultLibrary?._id
                            }
                            isNeedToRedirect={true}
                          />
                        </>
                      )}
                      {tab === "Transaction" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowCreateTransactionModal(true)}
                            startIcon={<Add />}
                          >
                            New Transaction
                          </Button>
                          <CreateTransactionModal
                            isOpen={showCreateTransactionModal}
                            onClose={() => {
                              setShowCreateTransactionModal(false);
                              setSelectedTransaction(null);
                            }}
                            reload={reload}
                            setReload={setReload}
                            profileId={profileId}
                            initialData={selectedTransaction}
                            libraryId={
                              profileData?.parent?.wallet?.defaultLibrary?._id
                            }
                          />
                        </>
                      )}
                      {tab === "Demand Deed" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowCreateDemandDeedDrawer(true)}
                            startIcon={<Add />}
                          >
                            New Demand Deed
                          </Button>
                          <CreateDemandDeedDrawer
                            isOpen={showCreateDemandDeedDrawer}
                            setIsOpen={setShowCreateDemandDeedDrawer}
                            profileId={profileId}
                          />
                        </>
                      )}
                      {tab === "Offer" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowCreteOfferModel(true)}
                            startIcon={<Add />}
                          >
                            New Offer
                          </Button>
                          <CreateOfferModel
                            open={showCreateOfferModel}
                            setOpen={setShowCreteOfferModel}
                            libraryId={
                              profileData?.parent?.wallet?.defaultLibrary?._id
                            }
                            onSuccess={handleOfferCreated}
                          />
                        </>
                      )}
                      <IconButton
                        variant="primary"
                        onClick={() =>
                          history.push(`/booking/settings/${profileId}`)
                        }
                        style={{ marginLeft: "15px" }}
                      >
                        <SettingsOutlined />
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.invoiceEditBody}>
                    <Paper elevation={2} style={{ overflow: "hidden" }}>
                      <TabsWithActions
                        onTabChange={handleTabChange}
                        currentTabVariable={tab}
                        tabs={[
                          { title: "Booking", icon: <HomeIcon /> },
                          { title: "Estimate", icon: <RequestQuoteIcon /> },
                          { title: "Demand Deed", icon: <DescriptionIcon /> },
                          { title: "Transaction", icon: <HandshakeIcon /> },
                          { title: "Offer", icon: <GavelIcon /> },
                          { title: "Analytics", icon: <TrendingUpIcon /> },
                          { title: "Schedules", icon: <CalendarMonthIcon /> },
                          { title: "Blocks Analysis", icon: <GridViewIcon /> },
                        ]}
                      />
                    </Paper>
                    <Paper className={classes.tabPagesCont} elevation={2}>
                      {tab === "Booking" && (
                        <Bookings
                          libraryId={
                            profileData?.parent?.wallet?.defaultLibrary?._id
                          }
                          projectIds={projectIds}
                        />
                      )}
                      {tab === "Estimate" && (
                        <EstimateList
                          libraryId={
                            profileData?.parent?.wallet?.defaultLibrary?._id
                          }
                          projectIds={projectIds}
                        />
                      )}
                      {tab === "Demand Deed" && (
                        <DemandDeeds bookings={bookings} />
                      )}
                      {tab === "Transaction" && (
                        <Transaction
                          libraryId={
                            profileData?.parent?.wallet?.defaultLibrary?._id
                          }
                          setOldTransaction={handleEditTransaction}
                          reload={reload}
                        />
                      )}
                      {tab === "Offer" && (
                        <BookingOffers
                          libraryId={
                            profileData?.parent?.wallet?.defaultLibrary?._id
                          }
                          projectIds={projectIds}
                          newOfferCreated={newOfferCreated}
                        />
                      )}
                      {tab === "Analytics" && <BookingAnalytics />}
                      {tab === "Schedules" && (
                        <BookingSchedule
                          libraryId={
                            profileData?.parent?.wallet?.defaultLibrary?._id
                          }
                          bookings={bookings}
                          projectIds={projectIds}
                          projects={projects}
                        />
                      )}
                      {tab === "Blocks Analysis" && <BlockUnitsHome />}
                    </Paper>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default BookingHome;
