import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getApprovalStagesByRequestId } from "./api.call";
import StageAndCommentDetails from "./StageCommentDetails";
import { Skeleton } from "@mui/material";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    skeletonSty: {
        width: "100%",
        height: "60px !important",
        marginBottom: "20px"
    },
    reactionsCont: {
        width: "100%",
        height: "500px",
        position: "absolute",
        bottom: "55px",
        left: "0px",
        borderTop: "1px solid #ececec",
        boxShadow: "20px -10px 15px -10px rgba(0, 0, 0, 0.3)",
        backgroundColor: "white",
        transition: "all 200ms ease-in",
    },
    hideReactionsCont: {
        width: "100%",
        height: "500px",
        position: "absolute",
        bottom: "-555px",
        left: "0px",
        borderTop: "1px solid #ececec",
        boxShadow: "20px -10px 15px -10px rgba(0, 0, 0, 0.3)",
        backgroundColor: "white",
        transition: "all 200ms ease-in",
    },
    reactionHeader: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px",
        borderBottom: "1px solid #ececec",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500"
        }
    },
    reactionBodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowY: "auto",
        padding: "15px"
    },
    emojiNameCont:{
        display: "flex",
        alignItems: "center",
        marginBottom: "5px",
        "& h3":{
            fontSize: "16px",
            fontWeight: "500",
            marginLeft: "10px",
        },
        "& span":{
            fontSize: "25px"
        }
    }
}));

export default function StagesAndComments({
    openApprovalFlow, changePageState, requestId
}) {
    const classes = useStyles();

    const [stageArr, setStageArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openReactionsDetails, setOpenReactionsDetails] = useState(false)
    const [reactions, setReactions] = useState([])

    const getAllStages = async () => {
        setLoading(true);
        await getApprovalStagesByRequestId({
            approvalRequest: requestId
        })
            .then(async (data) => {
                console.log(data)
                setStageArr(data)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        console.log(openApprovalFlow)
        if (openApprovalFlow) {
            getAllStages()
        }
    }, [openApprovalFlow]);

    return (<>
        {loading ? (<>
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
            <Skeleton variant="rect" className={classes.skeletonSty} />
        </>) : (<>
            {stageArr.map((stageData, index) => (
                <StageAndCommentDetails
                    key={stageData?._id}
                    index={index}
                    stageData={stageData}
                    changePageState={changePageState}
                    setReactions={setReactions}
                    setOpenReactionsDetails={setOpenReactionsDetails}
                />
            ))}
        </>)}
        <div className={openReactionsDetails ? classes.reactionsCont : classes.hideReactionsCont} >
            <div className={classes.reactionHeader} >
                <h3>Reactions</h3>

                <IconButton
                    onClick={() => {
                        setOpenReactionsDetails(false)
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.reactionBodyCont} >
                {reactions && reactions.map((reaction) => (
                    <div className={classes.emojiNameCont} >
                        <span>{reaction?.reaction}</span>
                        <h3>{reaction?.user?.displayName}</h3>
                    </div>
                ))}
            </div>
        </div>
    </>);
}