import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import config from "../../../config";
import { useState, useEffect } from "react";
import Api from "../../../helpers/Api";
import DocCard from "../../doc/Doc.Card";

export default function BookingDocumentsTab(props) {
  const { booking } = props;

  const isDev = config?.mode == "dev";

  const reqDocuments = booking?.docFolder?.documentStatus;
  const folderId = booking?.docFolder?._id;
  const template = booking?.docFolder?.template;

  const dispatch = useDispatch();
  const history = useHistory();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const redirectToDocuments = () => {
    if (!booking?.docFolder?.profile) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "No document folder found",
        },
      });
      return;
    }
    const path = `/doc/folder/${booking?.docFolder?.profile}`;
    if (isDev) {
      history.push(path);
    } else if (config.mode == "prod") {
      let link = `https://reallist.ai/${path}`;
      window.open(link, "_self");
    }
  };

  const fetchDocumentsData = async () => {
    try {
      setLoading(true);
      if (!reqDocuments || !folderId || !template) return;

      // First create any missing documents
      const creationPromises = reqDocuments.map(async (ds) => {
        if (!ds.document) {
          const response = await Api.post(
            "/doc/folder/template/update-document",
            {
              folderId: folderId,
              documentName: ds.documentName,
              status: ds.status,
              parentTemplate: template,
            }
          );
          return response.data;
        }
        return null;
      });

      const creationResults = await Promise.all(creationPromises);

      // Get the updated folder after document creation
      const updatedFolder = creationResults.find((result) => result !== null);

      // Use the latest document status for fetching details
      const latestDocumentStatus = updatedFolder
        ? updatedFolder.documentStatus
        : reqDocuments;

      // Then fetch all document details in a single operation
      const updatedDocStatus = await Promise.all(
        latestDocumentStatus.map(async (ds) => {
          if (ds.document) {
            const { data } = await Api.post("/doc/getDocDetail", {
              docId: ds.document,
            });
            if (data && data.length > 0) {
              return {
                ...ds,
                document: data[0],
              };
            }
          }
          return ds;
        })
      );

      setDocuments(updatedDocStatus);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error in fetching documents data",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentsData();
  }, [booking]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return template ? (
    <div>
      {documents && documents.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          {documents.map((doc) => (
            <DocCard
              key={doc.document._id}
              doc={doc.document}
              status={doc.status}
            />
          ))}
        </div>
      ) : (
        <div>No documents found</div>
      )}
      <Button onClick={redirectToDocuments} variant="outlined" color="primary">
        Manage Documents
      </Button>
    </div>
  ) : (
    <div
      style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}
    >
      No template found for this booking
    </div>
  );
}
