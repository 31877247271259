import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Divider,
  LinearProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import NormalDialog from "../../../styled/CommonComponents/NormalDialog";
import { useDispatch } from "react-redux";
import ProjectPicker from "../ProjectPicker";
import FormBox from "../../../styled/generic/FormBox";
import ProjectBlockAndUnitPickerDropdown from "../../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import { getProfileDataPopulate } from "../../../team/procurements/api.call";
import { createBookingEstimate } from "../api";
import { getPaymentTableByLibrary, getScheduleByPaymentTable } from "../api";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainCreateCont: {
    width: "700px",
  },
  bodyCont: {
    width: "100%",
    minHeight: "250px",
    overflowY: "auto",
  },
  bottomSty: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const steps = ["Project and Units", "Payment Details", "Payment Tables"];

export default function CreateEstimateModal({
  open,
  setOpen,
  onClose,
  libraryId,
  profileId,
  isNeedToRedirect = false,
  defaultBlock = null,
  defaultUnit = null,
  customerProfileId = null,
  tags = [],
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  const [estimateData, setEstimateData] = useState({
    project: null,
    projectBlock: null,
    unit: null,
    area: 0,
    rsf: 0,
    saleableArea: 0,
    paymentTableId: null,
    paymentScheduleId: null,
    organization: null,
    customerProfileId: null,
  });

  const [paymentTables, setPaymentTables] = useState([]);
  const [paymentSchedule, setPaymentSchedule] = useState(null);

  const fetchProfile = async () => {
    setLoading(true);
    await getProfileDataPopulate({
      profileId: profileId,
    })
      .then((data) => {
        let estimateObj = { ...estimateData };
        if (data?.parentModelName === "Project") {
          setShowProjects(false);
          estimateObj.project = data?.parent;
          if (data?.ownerProfile?.parentModelName === "Organization") {
            estimateObj.organization = data?.ownerProfile?.parent;
          }
        } else {
          setShowProjects(true);
          estimateObj.organization = data?.parent;
        }
        if (defaultBlock) {
          estimateObj.projectBlock = defaultBlock;
        }
        if (defaultUnit) {
          estimateObj.unit = defaultUnit;
        }
        setEstimateData(estimateObj);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to fetch profile",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (profileId && open) {
      fetchProfile();
    }
  }, [profileId, open]);

  useEffect(() => {
    if (estimateData?.unit?._id) {
      setEstimateData({
        ...estimateData,
        rsf: estimateData?.unit?.ratePerSqFt || 0,
        saleableArea: estimateData?.unit?.saleableArea || 0,
      });
    }
  }, [estimateData.unit]);

  const getPaymentTables = () => {
    setLoading(true);
    getPaymentTableByLibrary({ libraryId: libraryId })
      .then((data) => {
        setPaymentTables(data.filter((item) => item.title));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSchedules = async () => {
    setLoading(true);
    getScheduleByPaymentTable({
      libraryId: libraryId,
      tableId: estimateData?.paymentTableId,
    })
      .then((data) => {
        setPaymentSchedule(data.filter((item) => item?.title));
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open && libraryId) {
      getPaymentTables();
    }
  }, [open, libraryId]);

  useEffect(() => {
    if (estimateData?.paymentTableId && open) {
      getSchedules();
    }
  }, [estimateData?.paymentTableId, open]);

  const createEstimateData = async () => {
    try {
      setLoading(true);
      const formattedEstimateData = {
        ...estimateData,
        organization: estimateData?.organization?._id,
        project: estimateData?.project?._id,
        projectBlock: estimateData?.projectBlock?._id,
        unit: estimateData?.unit?._id,
        tags: tags,
      };
      const response = await createBookingEstimate({
        libraryId,
        customerProfileId: customerProfileId,
        profileId,
        estimateData: formattedEstimateData,
      });
      if (!response) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error creating estimate",
          },
        });
        return;
      }
      if (isNeedToRedirect) {
        history.push(
          "/booking/estimate/edit/" + response?.bookingEstimateDetails?._id
        );
      }
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Estimate created successfully",
        },
      });
      if (onClose) {
        onClose(response?.bookingEstimateDetails?._id);
      }
      setOpen(false);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error creating estimate",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEstimateData = (newData) => {
    setEstimateData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => {
        setOpen(false);
      }}
      pageTitle={"Create New Estimate"}
      content={
        <div className={classes.mainCreateCont}>
          <div style={{ width: "100%", height: "5px" }}>
            {loading && <LinearProgress />}
          </div>
          <div className={classes.bodyCont}>
            <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 1 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider sx={{ mb: 3 }} />

            {activeStep === 0 ? (
              <Grid container spacing={3}>
                <Grid container item spacing={2}>
                  {showProjects && (
                    <Grid item xs={12} md={6}>
                      <FormBox label={"Select Project"}>
                        <ProjectPicker
                          fullWidth={true}
                          size="sm"
                          selectedOrg={
                            showProjects
                              ? estimateData?.organization?._id
                              : false
                          }
                          project={estimateData.project}
                          setProject={(project) =>
                            handleUpdateEstimateData({ project: project })
                          }
                        />
                      </FormBox>
                    </Grid>
                  )}
                  {estimateData?.project && (
                    <Grid item xs={12} md={6}>
                      <FormBox label={"Select Block"}>
                        <ProjectBlockAndUnitPickerDropdown
                          projectId={estimateData?.project?._id}
                          hideLabel={true}
                          fullWidth={true}
                          showOnlyBlocks={true}
                          selectedProjectBlock={estimateData?.projectBlock}
                          setSelectedProjectBlock={(data) => {
                            handleUpdateEstimateData({ projectBlock: data });
                          }}
                          selectedProjectUnit={estimateData?.unit}
                          setSelectedProjectUnit={(data) => {
                            handleUpdateEstimateData({ unit: data });
                          }}
                        />
                      </FormBox>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : activeStep === 1 ? (
              <Grid container spacing={2} gridColumn={12}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Total Area (sqft)"
                    type="number"
                    size="small"
                    value={estimateData.area}
                    onChange={(e) =>
                      handleUpdateEstimateData({ area: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Rate per Sq Ft"
                    type="number"
                    size="small"
                    value={estimateData.rsf}
                    onChange={(e) =>
                      handleUpdateEstimateData({ rsf: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Saleable Area (sqft)"
                    type="number"
                    size="small"
                    value={estimateData.saleableArea}
                    onChange={(e) =>
                      handleUpdateEstimateData({
                        saleableArea: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} gridColumn={12}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Table</InputLabel>
                    <Select
                      label="Payment Table"
                      value={estimateData?.paymentTableId || ""}
                      onChange={(e) => {
                        const paymentTable = paymentTables?.find(
                          (item) => item?._id === e.target.value
                        );
                        handleUpdateEstimateData({
                          paymentTableId: paymentTable?._id,
                        });
                      }}
                    >
                      {paymentTables.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Schedule</InputLabel>
                    <Select
                      label="Payment Schedule"
                      value={estimateData?.paymentScheduleId || ""}
                      onChange={(e) => {
                        const schedule = paymentSchedule?.find(
                          (item) => item?._id === e.target.value
                        );
                        handleUpdateEstimateData({
                          paymentScheduleId: schedule?._id,
                        });
                      }}
                    >
                      {paymentSchedule?.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </div>
          <div className={classes.bottomSty}>
            <Button
              disabled={loading ? true : activeStep === 0}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              variant="outlined"
              style={{ marginRight: "15px" }}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={createEstimateData}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
}
